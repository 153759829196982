import React, { Fragment, useEffect, useState, forwardRef, useImperativeHandle } from "react"
import { useTable, useRowSelect, useSortBy, useFilters, useExpanded, usePagination } from "react-table"
import { Table, Input, Button, UncontrolledTooltip } from "reactstrap"
import { Space, Empty, Tooltip, Checkbox, Tag } from "antd"
import { PlusOutlined, MinusOutlined } from "@ant-design/icons"
import moment from "moment"
import _ from "lodash"

import { DndContext, PointerSensor, useSensor } from "@dnd-kit/core"
import { SortableContext, useSortable, verticalListSortingStrategy, arrayMove } from "@dnd-kit/sortable"
import { CSS } from "@dnd-kit/utilities"

import CoreRenderFunction from "DisplayCore"
import {
  Filter,
  BetweenDatesFilter,
  EqualCurrentLanguageFilter,
  ContainsCurrentLanguageFilter,
  DefaultColumnFilter,
  SelectColumnFilter,
  NumberRangeColumnFilter,
  DateRangeColumnFilter,
  SelectMultiLanguageColumnFilter,
  DateColumnFilter,
} from "./filter"
import { applyColor } from "utils/apply-color"
import { tagColor } from "utils/tagColor"
import { getContraColor } from "utils"
import {
  getSelectedLanguageContent,
  handleNestedObjectKey,
  checkIfComponentHidden,
  checkIfComponentDisabled,
} from "utils"

import TechBaseIcon from "DisplayCore/Icon"

const TechbaseTable = (props, ref) => {
  let {
    id,
    pageNumber,
    updateTableInfo,
    columnsContent,
    defaultPageSize,
    hidePagination,
    hidePageSizeOptions,
    actionsContent,
    dynamicContents,
    dataKey,
    style,
    sortable,
    sortingActionKey,
    getSubRows,
    metadataKey,
    subRowContent,
    showFooter,
    showAllRows,
    columnFilterChangedKey,
    rowSelection,
  } = props.item
  const [tableColumns, setTableColumns] = useState([])
  const [rowContent, updateRowContent] = useState([])

  useEffect(() => {
    let tmpActionColumn = getActionContentShown()
    let tmpCols = [
      ..._.map(columnsContent, (col) => ({
        Header:
          col.HeaderType === "custom" ? (
            <CoreRenderFunction {...props} item={col.Header} />
          ) : (
            getSelectedLanguageContent(col.Header, props.selectedLanguage)
          ),
        maxWidth: col.maxWidth ? col.maxWidth : undefined,
        headerStyle: { textAlign: !col.centerColumn ? "start" : "center" },
        className: col.centerColumn ? "d-flex justify-content-center text-center" : "",
        show: !checkIfComponentHidden(props.hideComponent, col),

        disableFilters: col.disableFilters || false,
        disableSortBy: col.disableSortBy || false,
        ...(col.selectFilter && { Filter: SelectColumnFilter }),
        ...(col.selectMultilanguageFilter && {
          Filter: (data) =>
            SelectMultiLanguageColumnFilter({ ...data, selectedLanguage: props.selectedLanguage }),
          filter: (rows, id, filterValues) =>
            EqualCurrentLanguageFilter(rows, id, filterValues, props.selectedLanguage),
        }),
        ...(col.multilanguageTextFilter && {
          filter: (rows, id, filterValues) =>
            ContainsCurrentLanguageFilter(rows, id, filterValues, props.selectedLanguage),
        }),
        ...(col.numberRangeColumnFilter && { Filter: NumberRangeColumnFilter }),
        ...(col.dateRangeColumnFilter && { Filter: DateRangeColumnFilter, filter: BetweenDatesFilter }),
        ...(col.dateColumnFilter && { Filter: DateColumnFilter }),
        ...(col.filter && { filter: col.filter }),
        ...(!_.isEmpty(col.valueType) && !checkIfComponentHidden(props.hideComponent, col)
          ? processTableCell(col)
          : col.accessor
          ? {
              accessor:
                col.accessor === "$index"
                  ? (row, i) =>
                      (!_.isEmpty(metadataKey) && props[metadataKey].isPaginated != false
                        ? pageIndex * pageSize
                        : 0) +
                      i +
                      1
                  : col.accessor,
              Cell: (row) => (
                <div style={col.style}>
                  {typeof applyColumnFunction(col.function, row.value, row.row.original) === "string"
                    ? getSelectedLanguageContent(
                        applyColumnFunction(col.function, row.value, row.row.original),
                        props.selectedLanguage
                      )
                    : applyColumnFunction(col.function, row.value, row.row.original)}
                </div>
              ),
            }
          : { Cell: ({ row }) => col.Cell(row.original) }),
        ...(col.footer && { Footer: (data) => processFooterCell(col, data) }),
        ...(col.minWidth && { minWidth: col.minWidth })
      })),
      ...(dynamicContents != null ? generateDynamicColumns() : []),
      ...(tmpActionColumn?.length > 0
        ? [
            {
              Header: getSelectedLanguageContent("{{ACTIONS}}", props.selectedLanguage),
              maxWidth: tmpActionColumn.length * 70,
              disableSortBy: true,
              disableFilters: true,
              accessor: "",
              Cell: ({ row }) => {
                return (
                  <Space size={"small"}>
                    {tmpActionColumn?.map((action, index) => {
                      if (
                        action.hide === undefined ||
                        !(
                          (typeof action.hide === "string" &&
                            checkIfComponentHidden(
                              props.hideComponent,
                              { isHide: action.hide },
                              row.original
                            )) ||
                          (typeof action.hide === "function" &&
                            action.hide(row.original, props.modulePermissions))
                        )
                      ) {
                        if (action.type === "toggle-expand-row") {
                          if (row.canExpand) {
                            return (
                              <Tooltip
                                title={
                                  row.isExpanded
                                    ? getSelectedLanguageContent("{{COLLAPSE}}", props.selectedLanguage)
                                    : getSelectedLanguageContent("{{EXPAND}}", props.selectedLanguage)
                                }
                              >
                                <span {...row.getToggleRowExpandedProps()}>
                                  {row.isExpanded ? <MinusOutlined /> : <PlusOutlined />}
                                </span>
                              </Tooltip>
                            )
                          }
                        } else if (action.type) {
                          return <CoreRenderFunction {...props} item={action} rowData={row} />
                        } else {
                          return (
                            <>
                              <Button
                                id={`${action.actionID}-${row.original.id}`}
                                color={action.color || ""}
                                disabled={action.disabled ? action.disabled(row.original, index) : false}
                                className={`btn-icon btn-icon-only`}
                                style={applyColor(action.style, props.colors)}
                                onClick={
                                  props.onClickAction
                                    ? () => props.onClickAction(action.actionKey, row.original)
                                    : () => {}
                                }
                              >
                                {typeof action.content === "object"
                                  ? action.content
                                  : action.content(row.original)}
                              </Button>
                              <UncontrolledTooltip
                                target={`${action.actionID}-${row.original.id}`}
                                placement={action.tooltipPosition || "top"}
                              >
                                {getSelectedLanguageContent(action.tooltipContent, props.selectedLanguage)}
                              </UncontrolledTooltip>
                            </>
                          )
                        }
                      } else {
                        ;<></>
                      }
                    })}
                  </Space>
                )
              },
            },
          ]
        : []),
      ...(!_.isEmpty(rowSelection) && !checkIfComponentHidden(props.hideComponent, rowSelection)
        ? [
            {
              id: "selection",
              Header: ({ getToggleAllRowsSelectedProps }) => (
                <div>
                  <IndeterminateCheckbox isHeader={true} {...getToggleAllRowsSelectedProps()} />
                </div>
              ),
              Cell: ({ row }) => (
                <div>
                  <IndeterminateCheckbox row={row} {...row.getToggleRowSelectedProps()} />
                </div>
              ),
            },
          ]
        : []),
    ]
    setTableColumns(tmpCols)
  }, [
    props.selectedLanguage,
    props.data.ProfileReducer.permissions,
    rowContent,
    checkIfComponentHidden(props.hideComponent, rowSelection),
  ])

  const generateDynamicColumns = () => {
    let dynamicColumns = []
    dynamicColumns = props[dynamicContents.columnList].map((col) => {
      let accessor = dynamicContents.accessor
      let dynamicKeys = dynamicContents.accessor.split(".$")
      for (let i = 1; i < dynamicKeys.length; i++)
        accessor = accessor.replace(`$${dynamicKeys[i]}`, col[dynamicKeys[i]])

      return {
        Header: getSelectedLanguageContent(col[dynamicContents.header], props.selectedLanguage),
        accessor: accessor,
        Cell: (row) => (
          <div style={dynamicContents.config.style}>
            {applyColumnFunction(dynamicContents.config.function, row.value, row.row.original)}
          </div>
        ),
        disableFilters: dynamicContents.config.disableFilters || false,
        ...(dynamicContents.config.selectFilter && { Filter: SelectColumnFilter }),
        ...(dynamicContents.config.numberRangeColumnFilter && { Filter: NumberRangeColumnFilter }),
        ...(dynamicContents.config.filter && { filter: dynamicContents.config.filter }),
      }
    })

    return dynamicColumns
  }

  const getActionContentShown = () => {
    return (
      !_.isEmpty(actionsContent) &&
      _.filter(actionsContent, (val) => !checkIfComponentHidden(props.hideComponent, val))
    )
  }

  const processTableCell = (col) => {
    if (col.valueType === "image") {
      return {
        accessor: "",
        Cell: ({ row }) => {
          if (col.accessor && row.original[col.accessor]) {
            const source = row.original[col.accessor]
            if (row.original.type === "video" || source.includes(".mp4") || source.includes(".mpeg")) {
              return (
                <video style={col.style} autoPlay loop muted>
                  <source src={source} />
                  Your browser does not support the video tag.
                </video>
              )
            } else {
              return <img src={source} style={col.style} alt="product" />
            }
          } else {
            return <></>
          }
        },
      }
    } else if (col.valueType === "array-value") {
      return {
        accessor: col.accessor,
        Cell: ({ value }) => (
          <>
            {value.map((val, index) => (
              <Tag color={tagColor[index]}>{val}</Tag>
            ))}
          </>
        ),
      }
    } else if (
      col.valueType === "dateTime" ||
      col.valueType === "multilanguageText" ||
      col.valueType === "translate"
    ) {
      return {
        accessor: col.accessor,
        minWidth: col.minWidth,
        Cell: ({ row }) => processTableCellContent(row.original, col),
      }
    } else {
      return {
        accessor: "",
        Cell: ({ row }) => (
          <div
            style={
              col.valueType === "boolean"
                ? { display: "flex", justifyContent: "center", ...(col.style ? col.style : {}) }
                : { overflow: "auto", ...(col.style ? col.style : {}) }
            }
          >
            {col.accessor && processTableCellContent(row.original, col)}
          </div>
        ),
      }
    }
  }

  const processTableCellContent = (row, col) => {
    let content
    if (col.accessor.includes(".")) {
      content = col.accessor.split(".").reduce((obj, key) => obj?.[key], row)
    } else {
      content = row[col.accessor]
    }
    if (_.isUndefined(content) || _.isNull(content)) {
      return
    }
    if (Array.isArray(content)) {
      return (
        <ul>
          {content.map((item) => (
            <li>{processTableCellContent(item)}</li>
          ))}
        </ul>
      )
    } else if (content != null && typeof content == "object") {
      if (content["en"] != null) {
        return getSelectedLanguageContent(content, props.selectedLanguage)
      } else {
        return JSON.parse(content)
      }
    } else if (typeof content === "boolean") {
      return content ? <i class="fa fa-check"></i> : ""
    } else if (col.valueType === "translate") {
      return getSelectedLanguageContent(`{{${content}}}`, props.selectedLanguage)
    } else if (col.valueType === "price") {
      if (typeof content === "string") {
        return `${handleNestedObjectKey(row, col.prefix) || ""} ${parseFloat(content).toFixed(2)}`
      }
      return `${handleNestedObjectKey(row, col.prefix) || ""} ${content?.toFixed(2)}`
    } else if (col.valueType === "dateTime") {
      if (col.dateTimeFormat) return moment(new Date(content)).format(col.dateTimeFormat)
      else return moment(new Date(content)).format("hh:mm:ssa, YYYY-MM-DD")
    }
  }

  const processFooterCell = (col, data) => {
    if (col.footerType === "custom") {
      return <CoreRenderFunction {...props} item={col.footer} />
    } else if (col.footerType === "total" && col.accessor) {
      const footerTotal = {
        total: _.sumBy(data.filteredRows, (row) => {
          return parseFloat(row.original[col.accessor])
        }),
        subtotal: _.sumBy(data.page, (row) => {
          return parseFloat(row.original[col.accessor])
        }),
      }
      return <CoreRenderFunction {...props} footerTotal={footerTotal} item={col.footer} />
    } else {
      return col.footer
    }
  }

  const applyColumnFunction = (func, value, original) => {
    if (func) {
      return func(value, original, props)
    }

    return value
  }

  const {
    getTableProps,
    getTableBodyProps,
    headerGroups,
    footerGroups,
    page,
    prepareRow,
    canPreviousPage,
    canNextPage,
    pageOptions,
    pageCount,
    gotoPage,
    nextPage,
    previousPage,
    setPageSize,
    state: { pageIndex, pageSize, sortBy, selectedRowIds },
    rows,
    preFilteredRows,
    toggleAllRowsSelected,
  } = useTable(
    {
      columns: tableColumns,
      data: rowContent || [],
      paginateExpandedRows: false,
      autoResetExpanded: false,
      defaultColumn: {
        Filter: (data) =>
          DefaultColumnFilter({
            ...data,
            onChangeKey: columnFilterChangedKey,
            onChangeAction: props.onClickAction,
          }),
      },
      getSubRows: getSubRows,
      ...(!_.isEmpty(metadataKey) && props[metadataKey].isPaginated != false
        ? {
            pageSize: props[metadataKey].pageSize,
            pageIndex: props[metadataKey].page - 1,
            pageCount: props[metadataKey].totalPages,
            defaultCanSort: true,
            manualPagination: true,
            manualFilters: true,
            manualSortBy: true,
          }
        : {
            manualPagination: false,
            manualFilters: false,
            manualSortBy: false,
          }),
      initialState: {
        pageIndex:
          (!_.isEmpty(metadataKey) && props[metadataKey].isPaginated != false
            ? props[metadataKey].page - 1
            : handleNestedObjectKey(props, pageNumber) - 1) || 0,
        pageSize: defaultPageSize || 10,
        hiddenColumns: _.map(_.filter(tableColumns, { show: false }), "accessor"),
      },
    },
    useFilters,
    useSortBy,
    useExpanded,
    usePagination,
    useRowSelect
  )

  useEffect(() => {
    let tmp = handleNestedObjectKey(props, dataKey)
    if (!_.isEqual(tmp, rowContent)) {
      if (_.isEmpty(tmp)) {
        updateRowContent([])
      } else {
        updateRowContent(tmp)
        if (showAllRows) {
          setPageSize(tmp.length)
        }
      }
    }
  }, [handleNestedObjectKey(props, dataKey)])

  useEffect(() => {
    props.onClickAction && props.onClickAction(updateTableInfo, "page", pageIndex + 1)
  }, [pageIndex])

  useEffect(() => {
    props.onClickAction && props.onClickAction(updateTableInfo, "pageSize", pageSize)
  }, [pageSize])

  useEffect(() => {
    props.onClickAction && props.onClickAction(updateTableInfo, "sorter", sortBy)
  }, [sortBy])

  useEffect(() => {
    if (!_.isEmpty(props[metadataKey]) && props[metadataKey].isPaginated != false) {
      gotoPage(props[metadataKey].page - 1)
      setPageSize(props[metadataKey].pageSize)
    }
  }, [props[metadataKey]])

  useEffect(() => {
    if (
      !_.isEmpty(rowSelection) &&
      rowSelection.onSelectedRowsChanged &&
      props[rowSelection.onSelectedRowsChanged]
    ) {
      if (selectedRowIds) {
        let selectedRows = []
        for (const [rowId, isSelected] of Object.entries(selectedRowIds)) {
          if (isSelected) {
            const row = _.find(preFilteredRows, (row) => row.id == rowId)
            if (row) {
              selectedRows.push(row)
            }
          }
        }
        props[rowSelection.onSelectedRowsChanged](selectedRows)
      }
    }
  }, [selectedRowIds])

  const generateSortingIndicator = (column) => {
    return column.isSorted ? (
      column.isSortedDesc ? (
        <i class="fa fa-sort-desc ml-2" />
      ) : (
        <i class="fa fa-sort-asc ml-2" />
      )
    ) : (
      ""
    )
  }

  const onChangeInSelect = (e) => {
    setPageSize(Number(e.target.value))
  }

  const onChangeInInput = (e) => {
    const page = e.target.value ? Number(e.target.value) - 1 : 0
    gotoPage(page)
  }

  const ConditionalWrapper = ({ condition, wrapper, children }) => (condition ? wrapper(children) : children)

  const sensor = useSensor(PointerSensor, {
    activationConstraint: { distance: 10 },
  })

  const handleDragEnd = (e) => {
    const { active, over } = e

    if (active.id !== over.id) {
      const oldIndex = rowContent.findIndex((item) => item.id === active.id)
      const newIndex = rowContent.findIndex((item) => item.id === over.id)

      const newRowContent = arrayMove(rowContent, oldIndex, newIndex)
      updateRowContent(newRowContent)

      if (sortingActionKey && props.onClickAction) {
        props.onClickAction(sortingActionKey, newRowContent)
      }
    }
  }

  const DraggableTableRow = ({ row }) => {
    const { attributes, listeners, setNodeRef, transform, transition } = useSortable({ id: row.original?.id })

    return (
      <Fragment>
        <tr
          ref={setNodeRef}
          style={{
            transform: CSS.Transform.toString(transform),
            transition,
            cursor: "move",
          }}
          {...row.getRowProps()}
        >
          {row.cells.map((cell, i) => (
            <td {...cell.getCellProps()}>
              <ConditionalWrapper
                condition={i === 0}
                wrapper={(children) => (
                  <div {...attributes} {...listeners}>
                    <TechBaseIcon item={{ antIcon: "HolderOutlined", className: "pr-1" }} />
                    {children}
                  </div>
                )}
              >
                {cell.render("Cell")}
              </ConditionalWrapper>
            </td>
          ))}
        </tr>
      </Fragment>
    )
  }

  const IndeterminateCheckbox = React.forwardRef(({ indeterminate, ...rest }, ref) => {
    const defaultRef = React.useRef()
    const resolvedRef = ref || defaultRef

    React.useEffect(() => {
      if (resolvedRef.current) {
        resolvedRef.current.indeterminate = indeterminate
      }
    }, [resolvedRef, indeterminate])

    return (
      <>
        {rest.isHeader ||
        !rowSelection?.isDisabled ||
        (rowSelection.isDisabled &&
          rest?.row?.original &&
          !checkIfComponentDisabled(props.disabledComponent, rowSelection, rest.row)) ? (
          <Checkbox {...rest} ref={resolvedRef} />
        ) : (
          <></>
        )}
      </>
    )
  })

  useImperativeHandle(ref, () => ({
    toggleAllSelectedRows: (isToSelect) => toggleAllRowsSelected(isToSelect),
  }))

  return (
    <>
      <style>
        {`
          ${id ? `#${id}` : ""} thead {
            color: ${
              style?.thead?.color
                ? applyColor(style.thead.color, props.colors)
                : applyColor("$tableTheadText", props.colors)
            };
            background-color: ${
              style?.thead?.backgroundColor
                ? applyColor(style.thead.backgroundColor, props.colors)
                : applyColor("$tableThead", props.colors)
            };
          }
           
          .table${id ? `#${id}` : ""} tbody tr {
            background-color: #ffffff;
            &:hover {
              background-color: ${applyColor("$tertiary", props.colors)};
            }
          }

          ${id ? `#${id}` : ""} tfoot {
            color: ${
              style?.tfoot?.color ? applyColor(style.tfoot.color, props.colors) : getContraColor("#e9ecef")
            };
            background-color: ${
              style?.tfoot?.backgroundColor
                ? applyColor(style.tfoot.backgroundColor, props.colors)
                : "#e9ecef"
            };
          }
        `}
      </style>
      <Fragment>
        <div className="table-responsive">
          <DndContext sensors={[sensor]} onDragEnd={handleDragEnd}>
            <Table id={id} bordered hover {...getTableProps()}>
              <thead>
                {headerGroups.map((headerGroup) => (
                  <tr {...headerGroup.getHeaderGroupProps()}>
                    {headerGroup.headers.map((column) => (
                      <th {...column.getHeaderProps({ style: { minWidth: column.minWidth } })}>
                        <div {...column.getSortByToggleProps()}>
                          {column.render("Header")}
                          {generateSortingIndicator(column)}
                        </div>
                        <Filter column={column} />
                      </th>
                    ))}
                  </tr>
                ))}
              </thead>
              <tbody {...getTableBodyProps()}>
                <SortableContext items={rowContent} strategy={verticalListSortingStrategy}>
                  {page.map((row) => {
                    prepareRow(row)
                    return (
                      <>
                        {sortable ? (
                          <DraggableTableRow row={row} />
                        ) : (
                          <Fragment key={row.getRowProps().key}>
                            <tr onClick={() => (subRowContent ? row.toggleRowExpanded() : {})}>
                              {row.cells.map((cell) => (
                                <td {...cell.getCellProps()}>{cell.render("Cell")}</td>
                              ))}
                            </tr>
                            {subRowContent && row.isExpanded ? (
                              <tr
                                style={{
                                  border: "1px solid #e9ecef",
                                  transition: "all 0.5s ease-in",
                                  padding: "0.5rem",
                                }}
                              >
                                <td colSpan={row.cells.length}>
                                  <CoreRenderFunction {...props} item={subRowContent} rowData={row} />
                                </td>
                              </tr>
                            ) : (
                              <></>
                            )}
                          </Fragment>
                        )}
                      </>
                    )
                  })}
                  {page.length === 0 && (
                    <Fragment>
                      <tr>
                        <td colSpan={tableColumns.length} style={{ textAlign: "center" }}>
                          <Empty
                            description={
                              <span>
                                {getSelectedLanguageContent(`{{NO_DATA_TO_DISPLAY}}`, props.selectedLanguage)}
                              </span>
                            }
                          />
                        </td>
                      </tr>
                    </Fragment>
                  )}
                </SortableContext>
              </tbody>
              {showFooter && (
                <tfoot>
                  {footerGroups.map((footerGroup) => (
                    <tr {...footerGroup.getFooterGroupProps()}>
                      {footerGroup.headers.map((column) => (
                        <td {...column.getFooterProps()}>{column.render("Footer")}</td>
                      ))}
                    </tr>
                  ))}
                </tfoot>
              )}
            </Table>
          </DndContext>
        </div>
        {!hidePagination && pageCount > 1 && (
          <div
            style={{
              display: "flex",
              gap: "5px",
              alignItems: "center",
              justifyContent: "center",
              margin: "30px 0",
            }}
          >
            <Button
              style={applyColor({ backgroundColor: "$quaternary", color: "#ffffff" }, props.colors)}
              onClick={() => gotoPage(0)}
              disabled={!canPreviousPage}
            >
              {"<<"}
            </Button>
            <Button
              style={applyColor({ backgroundColor: "$quaternary", color: "#ffffff" }, props.colors)}
              onClick={previousPage}
              disabled={!canPreviousPage}
            >
              {"<"}
            </Button>
            <div className="ml-3 mr-2">
              {getSelectedLanguageContent("{{PAGE}}", props.selectedLanguage)}
              <strong>
                {pageIndex + 1} of {pageOptions.length}
              </strong>
            </div>
            <Input
              type="number"
              min={1}
              style={{ width: "70px" }}
              max={pageOptions.length}
              defaultValue={pageIndex + 1}
              onChange={onChangeInInput}
            />
            {!hidePageSizeOptions && (
              <Input
                type="select"
                value={pageSize}
                onChange={onChangeInSelect}
                className="form-control mr-3"
                style={{ width: "100px" }}
              >
                {[5, 10, 20, 30, 40, 50].map((pageSize) => (
                  <option key={pageSize} value={pageSize}>
                    {`${getSelectedLanguageContent("{{SHOW}}", props.selectedLanguage)} ${pageSize}`}
                  </option>
                ))}
              </Input>
            )}
            <Button
              style={applyColor({ backgroundColor: "$quaternary", color: "#ffffff" }, props.colors)}
              onClick={nextPage}
              disabled={!canNextPage}
            >
              {">"}
            </Button>
            <Button
              style={applyColor({ backgroundColor: "$quaternary", color: "#ffffff" }, props.colors)}
              onClick={() => gotoPage(pageCount - 1)}
              disabled={!canNextPage}
            >
              {">>"}
            </Button>
          </div>
        )}
      </Fragment>
    </>
  )
}

export default forwardRef(TechbaseTable)
