import React, { useEffect, useState, useRef } from "react";
import { compose } from "redux";
import _ from "lodash";
import SignatureCanvas from "react-signature-canvas";
import { Row, Col, Button, Dropdown, Form, Table, Modal, Input, Space, Card } from "antd";

import TechBaseText from "DisplayCoreV2/Text";

import WithOrderHOC from "./actions";
import WithParamsHOC from "containers/Actions/params";
import { ValidateICRule, requireRule, validateMobileNumberRule } from "utils/rules";
import TechBaseButton from "DisplayCoreV2/Button";
import TechBaseFilter from "DisplayCoreV2/Filter";
import { orderStatusCode } from "constants";
import { thousandSeperatorFormatter } from "utils";


const MonumentListing = (props) => {
  const [AuthorizeForm] = Form.useForm();
  const canvasParent = useRef();
  const [signCanvasRef, updateSignCanvasRef] = useState();
  const [canvasWidth, setCanvasWidth] = useState(0);

  const {
    query,
    orders,
    profile,
    selectedOrder,
    totalOrders,
    showAddSignatureModal,
    showAddAuthRepModal,
    showDetailModal,
    onChangeHOC,
    getOrders,
    getSelectedOrder,
    getProductParamOptions,
    addAuthorisedRep,
    getPurchaseOrders,
    getAddendum,
    addDigitalSignature,
    getPurchaseInvoice
  } = props;
  const { can_read, can_update, can_delete, can_create } = props.permission ?? {};

  const actions = (record) => [
    {
      key: "1",
      label: <TechBaseText content="{{VIEW_DETAILS}}" />
    },
    {
      key: "2",
      label: <TechBaseText content="{{AUTHORIZED_REPRESENTATIVE}}" />
    },
    // {
    //   key: "3",
    //   label: <TechBaseText content="{{VIEW_PURCHASE_ORDER}}" />
    // },
    {
      key: "4",
      label: <TechBaseText content="{{VIEW_ADDENDUM}}" />
    },
    ...record.SalesStatus === 'A' ? [
      {
        key: "5",
        label: <TechBaseText content="{{ADD_DIGITAL_SIGNATURE}}" />
      }
    ] : [],
    {
      key: "6",
      label: <TechBaseText content="{{VIEW_PURCHASE_INVOICE}}" />
    },
  ];

  const columns = [
    {
      title: <TechBaseText Tag="div" content="{{NUMBER}}." />,
      render: (text, record, index) => (query.pageNumber - 1) * query.pageSize + index + 1
    },
    {
      title: <TechBaseText Tag="div" content="{{LOT_NO}}" />,
      dataIndex: "UniqueCode",
      key: "UniqueCode",
      onCell: () => ({
        style: { whiteSpace: 'nowrap' },
      }),
    },
    {
      title: <TechBaseText Tag="div" content="{{SALES_ID}}" />,
      dataIndex: "SalesID",
      key: "SalesID",
      onCell: () => ({
        style: { whiteSpace: 'nowrap' },
      }),
    },
    {
      title: <TechBaseText Tag="div" content="{{SALES}}" />,
      key: "sales",
      render: (text, record, index) => (
        <div>
          {record.Fullname && <TechBaseText Tag="div" content={`({{PURCHASER}} {{NAME}}: ${record.Fullname})`} />}
          {record.AgentID && <TechBaseText Tag="div" content={`({{AGENT_ID}}: ${record.AgentID})`} />}
          {record.SalesDate && <TechBaseText Tag="div" content={`({{SALES_DATE}}: ${record.SalesDate})`} />}
          {record.ReservationDate && (
            <TechBaseText Tag="div" content={`({{RESERVATION_DATE}}: ${record.ReservationDate})`} />
          )}
        </div>
      )
    },
    {
      title: <TechBaseText Tag="div" content="{{PAYMENT}} (RM)" />,
      key: "payment",
      render: (text, record, index) => (
        <div>
          {!_.isUndefined(record.TotalPayment) && (
            <TechBaseText Tag="div" content={`({{UNIT_PRICE}}: ${thousandSeperatorFormatter(record.UnitPrice)})`} />
          )}
          {!_.isUndefined(record.MaintenanceFee) && (
            <TechBaseText
              Tag="div"
              content={`({{MAINTENANCE_FEE}}: ${thousandSeperatorFormatter(record.MaintenanceFee)})`}
            />
          )}
        </div>
      )
    },
    {
      title: <TechBaseText Tag="div" content="{{DISCOUNT}} (RM)" />,
      dataIndex: "UnitDiscount",
      key: "UnitDiscount",
      align: "right",
      render: (text, record, index) => `${thousandSeperatorFormatter(text)}`
    },
    {
      title: <TechBaseText Tag="div" content="{{TOTAL_PRICE}} (RM)" />,
      dataIndex: "TotalPrice",
      key: "TotalPrice",
      align: "right",
      render: (text, record, index) => `${thousandSeperatorFormatter(text)}`
    },
    {
      title: <TechBaseText Tag="div" content="{{UPDATED_BY}}" />,
      dataIndex: "UpdatedBy",
      key: "UpdatedBy"
    },
    {
      title: <TechBaseText Tag="div" content="{{INTRODUCER_ID}}" />,
      dataIndex: "IntroducerDetail",
      key: "IntroducerDetail"
    },
    {
      title: <TechBaseText Tag="div" content="{{PO_REFERENCE_NUMBER}}" />,
      dataIndex: "PurchaseOrderRef",
      key: "PurchaseOrderRef"
    },
    {
      title: <TechBaseText Tag="div" content="{{REMARKS}}" />,
      dataIndex: "Remarks",
      key: "Remarks"
    },
    {
      title: <TechBaseText Tag="div" content="{{STATUS}}" />,
      dataIndex: "SalesStatus",
      key: "SalesStatus",
      render: (text) => orderStatusCode[text]
    },
    {
      title: <TechBaseText Tag="div" content="{{TERMINATED}}" />,
      dataIndex: "TerminatedForRefund",
      key: "TerminatedForRefund",
      render: (text, record, index) => (text > 0 ? "Yes" : "No")
    },
    {
      title: <TechBaseText Tag="div" content="{{ACTIONS}}" />,
      key: "action",
      render: (text, record) => (
        <Dropdown
          trigger="click"
          menu={{
            items: actions(record),
            onClick: (e) => {
              switch (e.key) {
                case "1":
                  getSelectedOrder(record, "details");
                  break;
                case "2":
                  getSelectedOrder(record, "authRep");
                  break;
                // case "3":
                //   getPurchaseOrders(record.SalesID);
                //   break;
                case "4":
                  getAddendum(record.SalesID);
                  break;
                case "5":
                  getSelectedOrder(record, "digitalSignature");
                  break;
                case "6":
                  getPurchaseInvoice(record.SalesID);
                  break;
                default:
              }
            }
          }}
        >
          <Button>
            <TechBaseText content="{{ACTIONS}}" />
          </Button>
        </Dropdown>
      )
    }
  ];

  const filterFields = [
    {
      type: "date-picker",
      name: "salesDateFrom",
      label: "{{SALES_DATE_FROM}}",
      lg: { span: 8, order: 0 }
    },
    {
      type: "date-picker",
      name: "salesDateTo",
      label: "{{SALES_DATE_TO}}",
      lg: { span: 8, order: 1 }
    },
    {
      type: "date-picker",
      name: "bookingDateFrom",
      label: "{{BOOKING_DATE_FROM}}",
      lg: { span: 8, order: 3 }
    },
    {
      type: "date-picker",
      name: "bookingDateTo",
      label: "{{BOOKING_DATE_TO}}",
      lg: { span: 8, order: 4 }
    },
    {
      type: "input",
      name: "uniqueCode",
      label: "{{LOT_NO}}",
      lg: { span: 8, order: 2 }
    },
    {
      type: "input",
      name: "salesId",
      label: "{{PO_REFERENCE_NO}}",
      lg: { span: 8, order: 5 }
    },
    {
      type: "input",
      name: "productCode",
      label: "{{PRODUCT}} {{CODE}}",
      lg: { span: 8, order: 6 }
    },
    {
      type: "select",
      name: "isTerminatedForRefund",
      label: "{{TERMINATED}}",
      options: [
        { value: "1", label: "Yes" },
        { value: "0", label: "No" }
      ],
      lg: { span: 8, order: 7 }
    },
    ...(profile.Role === "AD"
      ? [
        {
          role: "M",
          type: "member-finder",
          name: "agentCode_Name_IC",
          label: "{{AGENT_ID}}",
          lg: { span: 8, order: 8 }
        }
      ]
      : []),
    {
      role: "C",
      type: "member-finder",
      name: "purchaserID_Name",
      label: "{{PURCHASER_ID}}",
      lg: { span: 8, order: 9 }
    }
    // {
    //   type: "select",
    //   name: "introducer",
    //   label: "{{INTRODUCER}}",
    //   default: "",
    //   options: [
    //     { value: "", label: "All" },
    //     { value: "only_introducer", label: "Only Introducer" },
    //     { value: "exclude_introducer", label: "Exclude Introducer" }
    //   ]
    // },
    // {
    //   type: "member-finder",
    //   name: "introducerDetails",
    //   label: "{{INTRODUCER_ID}}"
    // }
  ];

  const paymentSummary = [
    {
      label: <TechBaseText content="{{UNIT_PRICE}}" />,
      key: "UnitPrice"
    },
    {
      label: <TechBaseText content="{{MAINTENANCE_FEE}}" />,
      key: "MaintenanceFee"
    },
    {
      label: <TechBaseText content="{{TOTAL_AMOUNT}}" />,
      key: "Subtotal"
    },
    {
      label: <TechBaseText content="{{UNIT_DISCOUNT}}" />,
      key: "UnitDiscount"
    },
    // {
    //   label: <TechBaseText content="{{BALANCE_AMOUNT}}" />,
    //   key: "BalanceAmount"
    // },
    {
      label: <TechBaseText content="{{DOWNPAYMENT}}" />,
      key: "Downpayment"
    },
    // {
    //   label: <TechBaseText content="{{BOOKING_FEE_RECEIVED}}" />,
    //   key: "BookingReceived"
    // },
    {
      label: <TechBaseText content="{{AMOUNT_TO_PAY}}" />,
      key: "BalanceAmount"
    },
    // {
    //   label: <TechBaseText content="{{TOTAL_PRICE}}" />,
    //   key: "TotalPrice"
    // }
  ];

  const productSummary = [
    {
      title: <TechBaseText content="{{PRODUCT}} {{CODE}}" />,
      dataIndex: "ProductCode",
      key: "ProductCode"
    },
    {
      title: <TechBaseText content="{{PRODUCT}} {{NAME}}" />,
      dataIndex: "ProductName",
      key: "ProductName"
    },
    {
      title: <TechBaseText content="{{CATEGORY}}" />,
      dataIndex: "CategoryName",
      key: "CategoryName"
    },
    {
      title: <TechBaseText content="{{QUANTITY}}" />,
      dataIndex: "Quantity",
      key: "Quantity"
    },
    {
      title: <TechBaseText content="{{PRICE}}" />,
      dataIndex: "SinglePrice",
      key: "SinglePrice"
    },
    {
      title: <TechBaseText content="{{SUBTOTAL}}" />,
      dataIndex: "TotalPrice",
      key: "TotalPrice"
    }
  ];

  useEffect(() => {
    if (profile.Role === "AD") {
      getProductParamOptions();
    }
  }, []);

  useEffect(() => {
    getOrders(query);
  }, [query]);

  useEffect(() => {
    if (!_.isEmpty(selectedOrder) && showAddAuthRepModal) {
      AuthorizeForm.setFieldsValue({
        authorizedFullName: selectedOrder.AuthorizedFullName,
        authorizedIC: selectedOrder.AuthorizedIC,
        authorizedMobileNo: selectedOrder.AuthorizedMobileNo
      });
    }
  }, [selectedOrder, showAddAuthRepModal]);

  useEffect(() => {
    // Update canvas width based on the parent container
    const updateCanvasWidth = () => {
      if (canvasParent?.current) {
        const parentWidth = canvasParent.current.offsetWidth;
        setCanvasWidth(parentWidth);
      }
    };

    // Initialize and add event listener for window resize
    updateCanvasWidth();
    window.addEventListener("resize", updateCanvasWidth);

    // Cleanup event listener on component unmount
    return () => {
      window.removeEventListener("resize", updateCanvasWidth);
    };
  }, [signCanvasRef]);

  const onSubmitForm = (formData) => {
    const params = {
      ...formData,
      salesID: selectedOrder.SalesID
    };
    addAuthorisedRep(params);
  };

  const onSubmitFilter = (formData) => {
    let tmp = _.omitBy(formData, _.isNil);
    if (tmp.salesDateFrom) {
      tmp.salesDateFrom = formData.salesDateFrom.format("YYYY-MM-DD");
    }
    if (tmp.salesDateTo) {
      tmp.salesDateTo = formData.salesDateTo.format("YYYY-MM-DD");
    }
    if (tmp.bookingDateFrom) {
      tmp.bookingDateFrom = formData.bookingDateFrom.format("YYYY-MM-DD");
    }
    if (tmp.bookingDateTo) {
      tmp.bookingDateTo = formData.bookingDateTo.format("YYYY-MM-DD");
    }
    onChangeHOC("query", {
      ...tmp,
      pageNumber: 1,
      pageSize: query.pageSize,
      agentCode_Name_IC: profile.Role !== "AD" ? profile.MemberId : tmp.agentCode_Name_IC,
      salesType: [9]
    });
  };

  if (!can_read) {
    return <></>;
  }

  return (
    <div>
      <h3 className="text-capitalize">
        <TechBaseText content="{{MONUMENT_LISTING}}" />
      </h3>
      <Row className="w-100" >
        <TechBaseFilter
          fields={filterFields}
          onFinish={onSubmitFilter}
          onReset={() =>
            onChangeHOC("query", {
              pageNumber: 1,
              pageSize: 10,
              agentCode_Name_IC: profile.Role !== "AD" ? profile.MemberId : undefined,
              salesType: [9]
            })
          }
        />
        <Table
          bordered
          className="techbase-table"
          columns={columns}
          dataSource={orders}
          pagination={{
            current: query.pageNumber,
            pageSize: query.pageSize,
            total: totalOrders,
            showSizeChanger: true,
            onChange: (page, pageSize) => onChangeHOC("query", { ...query, pageNumber: page, pageSize: pageSize })
          }}
        />
      </Row>

      <Modal
        width={700}
        title={<TechBaseText content="{{ORDER}} {{DETAILS}}" />}
        open={showDetailModal}
        onCancel={() => onChangeHOC("showDetailModal", false)}
        footer={null}
      >
        <div>
          <Card className="mb-3">
            <table style={{ borderSpacing: "10px", borderCollapse: "separate" }}>
              {selectedOrder.LotNo && (
                <tr>
                  <th>
                    <TechBaseText content="{{LOT_ID}}" />
                  </th>
                  <td>: {selectedOrder.LotNo}</td>
                </tr>
              )}
              <tr>
                <th>
                  <TechBaseText content="{{SALES_ID}}" />
                </th>
                <td>: {selectedOrder.SalesId ?? selectedOrder.SalesID}</td>
              </tr>
              {(selectedOrder.AgentName || selectedOrder.AgentID) && (
                <tr>
                  <th>
                    <TechBaseText content="{{AGENT}}" />
                  </th>
                  <td>
                    : {`${selectedOrder.AgentName || ""} ${selectedOrder.AgentID ? `(${selectedOrder.AgentID})` : ""}`}
                  </td>
                </tr>
              )}
              {(selectedOrder.PurchaserName || selectedOrder.PurchaserID) && (
                <tr>
                  <th>
                    <TechBaseText content="{{PURCHASER}}" />
                  </th>
                  <td>
                    :{" "}
                    {`${selectedOrder.PurchaserName || ""} ${selectedOrder.PurchaserID ? `(${selectedOrder.PurchaserID})` : ""
                      }`}
                  </td>
                </tr>
              )}
              <tr>
                <th>
                  <TechBaseText content="{{SALES_DATE}}" />
                </th>
                <td>: {selectedOrder.SalesDate}</td>
              </tr>
              <tr>
                <th>
                  <TechBaseText content="{{STATUS}}" />
                </th>
                <td>: {orderStatusCode[selectedOrder.SalesStatus]}</td>
              </tr>
            </table>
          </Card>
          {selectedOrder?.Products?.length > 0 && (
            <Table
              className="techbase-table mb-3"
              columns={productSummary}
              dataSource={selectedOrder.Products}
              pagination={selectedOrder.Products.length > 10}
            />
          )}
          {paymentSummary.some(
            (summary) => selectedOrder[summary.key] > -1 && !_.isNull(selectedOrder[summary.key])
          ) && (
              <div>
                <TechBaseText content="{{PAYMENT_SUMMARY}}" />
              </div>
            )}
          <Card className="mb-3">
            <table style={{ borderSpacing: "10px", borderCollapse: "separate" }}>
              {paymentSummary.map((summary) => (
                <>
                  {selectedOrder[summary.key] > -1 && !_.isNull(selectedOrder[summary.key]) && (
                    <tr>
                      <th>{summary.label}</th>
                      <td>: RM </td>
                      <td align="right">{thousandSeperatorFormatter(selectedOrder[summary.key])}</td>
                    </tr>
                  )}
                </>
              ))}
            </table>
          </Card>
          {selectedOrder.TotalInstallments > 1 && (
            <>
              <div >
                <TechBaseText content="{{INSTALLMENT_DETAILS}}" />
              </div>
              <Card className="mb-3">
                <table style={{ borderSpacing: "10px", borderCollapse: "separate" }}>
                  <tr>
                    <th>
                      <TechBaseText content="{{PERIOD}}" />
                    </th>
                    <td>:</td>
                    <td align="right">{selectedOrder.TotalInstallments} months</td>
                  </tr>
                  <tr>
                    <th>
                      <TechBaseText content="{{PERIOD_DATE}}" />
                    </th>
                    <td>:</td>
                    <td align="right">{selectedOrder.InstallmentDateRange}</td>
                  </tr>
                  <tr>
                    <th>
                      <TechBaseText content="{{SUM_PER_INSTALLMENT}}" />
                    </th>
                    <td>:</td>
                    <td align="right">RM {thousandSeperatorFormatter(selectedOrder.SumPerInstallment)}</td>
                  </tr>
                  <tr>
                    <th>
                      <TechBaseText content="{{FIRST_INSTALLMENT}}" />
                    </th>
                    <td>:</td>
                    <td align="right">RM {thousandSeperatorFormatter(selectedOrder.FirstInstallment)}</td>
                  </tr>
                  <tr>
                    <th>
                      <TechBaseText content="{{TOTAL_INSTALLMENT_AMOUNT}}" />
                    </th>
                    <td>:</td>
                    <td align="right">RM {thousandSeperatorFormatter(selectedOrder.TotalInstallmentAmount)}</td>
                  </tr>
                </table>
              </Card>
            </>
          )}
        </div>
        <div className="techbase-button-wrapper-modal">
          <TechBaseButton className="techbase-button-modal" size="large" onClick={() => onChangeHOC("showDetailModal", false)}>
            <TechBaseText content="{{CLOSE}}" />
          </TechBaseButton>
        </div>
      </Modal>

      <Modal
        destroyOnClose={true}
        title={<TechBaseText content="{{AUTHORIZED_REPRESENTATIVE}}" />}
        open={showAddAuthRepModal && !_.isEmpty(selectedOrder)}
        onCancel={() => onChangeHOC("showAddAuthRepModal", false)}
        footer={null}
      >
        <Form preserve={false} layout="vertical" form={AuthorizeForm} onFinish={onSubmitForm}>
          <Form.Item name="authorizedFullName" label={<TechBaseText content="{{FULL_NAME}}" />} rules={[requireRule]}>
            <Input size="large" placeholder={TechBaseText({ content: "{{FULL_NAME}}" })} />
          </Form.Item>
          <Form.Item
            name="authorizedIC"
            label={<TechBaseText content="{{IDENTITY_CARD_NUMBER}}" />}
            rules={[requireRule, ValidateICRule()]}
          >
            <Input size="large" placeholder={TechBaseText({ content: "{{IDENTITY_CARD_NUMBER}}" })} />
          </Form.Item>
          <Form.Item
            label={<TechBaseText content="{{CONTACT_NUMBER}}" />}
            rules={[requireRule, validateMobileNumberRule()]}
            name="authorizedMobileNo"
          >
            <Input size="large" placeholder={TechBaseText({ content: "{{CONTACT_NUMBER}}" })} />
          </Form.Item>
          <Row className="w-100 d-flex footer-btn ">

            <Col>
              <TechBaseButton size="large" onClick={() => onChangeHOC("showAddAuthRepModal", false)}>
                <TechBaseText content="{{CANCEL}}" />
              </TechBaseButton></Col>

            {can_update && (

              <Col>
                <TechBaseButton size="large" htmlType="submit" color="primary">
                  <TechBaseText content="{{ADD}}" />
                </TechBaseButton>
              </Col>

            )}
          </Row>
        </Form>
      </Modal>

      <Modal
        destroyOnClose={true}
        title={<TechBaseText content="{{ADD_DIGITAL_SIGNATURE}}" />}
        open={showAddSignatureModal && !_.isEmpty(selectedOrder)}
        onCancel={() => onChangeHOC("showAddSignatureModal", false)}
        footer={null}
      >
        <div ref={canvasParent} style={{ width: "100%" }}>
          <SignatureCanvas
            ref={(ref) => updateSignCanvasRef(ref)}
            canvasProps={{ width: canvasWidth, height: 200, className: "sigCanvas" }}
          />
        </div>
        <Row className="w-100 d-flex footer-btn ">

          <Col>
            <TechBaseButton size="large" onClick={() => onChangeHOC("showAddSignatureModal", false)}>
              <TechBaseText content="{{CANCEL}}" />
            </TechBaseButton>
          </Col>

          <Col>
            <TechBaseButton size="large" onClick={() => signCanvasRef.clear()}>
              <TechBaseText content="{{CLEAR}}" />
            </TechBaseButton>
          </Col>

          {can_update && (
            <Col>
              <TechBaseButton size="large" color="primary" onClick={() => addDigitalSignature(signCanvasRef.toDataURL())}>
                <TechBaseText content="{{SUBMIT}}" />
              </TechBaseButton>
            </Col>

          )}
        </Row>
      </Modal>
    </div>
  );
};

export default compose(WithOrderHOC, WithParamsHOC)(MonumentListing);
