import React from 'react'
import { Collapse } from 'antd'

import CoreRenderFunction from 'DisplayCore'
import { applyColor } from 'utils/apply-color'

const { Panel } = Collapse

const TechBaseCollapse = props => {
  let {
    panels,
    style,
    className,
    defaultActiveKey
  } = props.item

  return(
    <Collapse style={ style } className={ className } defaultActiveKey={ defaultActiveKey || [] } >
      {
        panels?.map( ( panel, index ) => (
          <Panel
            style={ applyColor( panel.style ) }
            className={ panel.className }
            showArrow={ panel.showArrow }
            header={ <CoreRenderFunction { ...props } item={ panel.title }/> } 
            key={ index }>
            {
              panel.children?.map( child => {
                return <CoreRenderFunction { ...props } item={ child }/>
              })
            }
          </Panel>
        ))
      }
    </Collapse>
  )
}

export default TechBaseCollapse