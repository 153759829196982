import React, { useEffect } from "react";
import { Card, Form, Space, Row, Col, InputNumber, Divider } from "antd";
import _ from "lodash";

import TechBaseText from "DisplayCoreV2/Text";
import TechBaseButton from "DisplayCoreV2/Button";
import { requireRule } from "utils/rules";

const InstallmentFields = [
  { title: <TechBaseText content="{{NO_OF_INSTALLMENT}}" />, name: ["lot", "loanDetails", "installmentMonth"], min: 2 },
  { title: <TechBaseText content="{{SUM_PER_INSTALLMENT}}" />, name: ["lot", "loanDetails", "sumPerInstallment"] },
  {
    title: <TechBaseText content="{{FIRST_INSTALLMENT}}" />,
    name: ["lot", "loanDetails", "firstInstallment"],
    disabled: true
  }
];

const Step2 = (props) => {
  const { selectedSlot, bookingOrder, onNext, onBack, formData, salesType } = props;
  const [form] = Form.useForm();
  const unitPrice = Form.useWatch(["lot", "unitPrice"], form);
  const maintenanceFee = Form.useWatch(["lot", "maintenanceFee"], form);
  const unitDiscount = Form.useWatch(["lot", "unitDiscount"], form);
  const balanceAmount = Form.useWatch("balance_amount", form);
  const downpayment = Form.useWatch(["lot", "downpayment"], form);
  const installmentMonth = Form.useWatch(["lot", "loanDetails", "installmentMonth"], form);
  const sumPerInstallment = Form.useWatch(["lot", "loanDetails", "sumPerInstallment"], form);

  const DetailFields = [
    { title: <TechBaseText content="{{UNIT_PRICE}}" />, name: ["lot", "unitPrice"], disabled: salesType === "FSP" },
    { title: <TechBaseText content="{{MAINTENANCE_FEE}}" />, name: ["lot", "maintenanceFee"] },
    { title: <TechBaseText content="{{TOTAL}}" />, name: "total", disabled: true },
    { title: <TechBaseText content="{{UNIT_DISCOUNT}}" />, name: ["lot", "unitDiscount"] },
    { title: <TechBaseText content="{{BALANCE_PAYABLE}}" />, name: "balance_payable", disabled: true },
    { title: <TechBaseText content="{{DOWNPAYMENT}}" />, name: ["lot", "downpayment"], bold: true },
    { title: <TechBaseText content="{{BALANCE_AMOUNT}}" />, name: "balance_amount", disabled: true },
    { title: <TechBaseText content="{{BOOKING_FEE_RECEIVED}}" />, name: "booking_fee_received", disabled: true }
  ];

  useEffect(() => {
    let total = 0,
      payableBalance = 0,
      balanceAmount = 0,
      firstInstallment = 0;
    total = unitPrice + maintenanceFee;
    payableBalance = total - unitDiscount;
    balanceAmount = payableBalance - downpayment;
    firstInstallment = balanceAmount - (installmentMonth - 1) * (sumPerInstallment || 0);

    form.setFieldsValue({
      total: total,
      balance_payable: payableBalance,
      balance_amount: balanceAmount
    });

    form.setFieldValue(["lot", "loanDetails", "firstInstallment"], firstInstallment);
  }, [unitPrice, maintenanceFee, unitDiscount, downpayment, installmentMonth, sumPerInstallment]);

  const onFinish = (value) => onNext(value);

  return (
    <Form
      onFinish={onFinish}
      layout="vertical"
      form={form}
      initialValues={{
        purchaser_type: "existing",
        nationality: 127,
        mobile_prefix: "60",
        mobile_prefix_2: "60",
        identity_type: "ic",
        gender: "male",
        country: 127,
        state: 9,
        mailing_country: 127,
        mailing_state: 9,
        booking_fee_received: bookingOrder?.Price ?? bookingOrder?.TotalPrice ?? 0,
        lot: {
          unitPrice: bookingOrder?.AgreedPrice ?? (salesType === "FSP" ? selectedSlot.Price : selectedSlot.ReferencePrice),
          maintenanceFee: salesType === "FSP" ? 0 : selectedSlot.ReferenceContinuityFee,
          unitDiscount: 0,
          downpayment: bookingOrder?.Price ?? bookingOrder?.TotalPrice ?? 0,
          loanDetails: {
            installmentMonth: 2,
            sumPerInstallment: 1
          }
        },
        ...formData
      }}
    >
      <Row className="w-100 d-block ">
        <Card className="mb-3">
          {salesType === "FSP" ? (
            <div className="lead">
              <TechBaseText content="{{SELECTED_FSP}}" />: {selectedSlot.ProductName}
            </div>
          ) : (
            <div className="lead">
              <TechBaseText content="{{LOT_NO}}" />: {selectedSlot.UnitID}
            </div>
          )}
        </Card>
        <Card className="mb-3">
          <div className="lead">
            <TechBaseText content="{{PAYMENT_DETAILS}}" />
          </div>
          <>
            <Row gutter={32}>
              {DetailFields.map((field, index) => (
                <Col xs={24} md={12} key={index}>
                  <span style={{ color: "#ff0000" }}>* </span>
                  {field.bold ? <strong>{field.title}</strong> : field.title}
                  <div>
                    <Form.Item
                      className="mt-1"
                      name={field.name}
                      rules={[
                        requireRule,
                        ({ getFieldValue }) => ({
                          validator(_, value) {
                            if (value < 0) {
                              return Promise.reject(new Error("Value should not be negative"));
                            }
                            return Promise.resolve();
                          }
                        })
                      ]}
                    >
                      <InputNumber className="w-100" disabled={field.disabled} min={field.min} />
                    </Form.Item>
                  </div>
                </Col>
              ))}
            </Row>

            {/* <Row gutter={32}>
                <Col xs={24} md={24}>
                <span style={{ color: "#ff0000" }}>* </span>
                {field.bold ? <strong>{field.title}</strong> : field.title}
                <div>
                  <Form.Item
                  className="mt-1"
                  name={field.name}
                  rules={[
                    requireRule,
                    ({ getFieldValue }) => ({
                    validator(_, value) {
                      if (value < 0) {
                      return Promise.reject(new Error("Value should not be negative"));
                      }
                      return Promise.resolve();
                    }
                    })
                  ]}
                  >
                  <InputNumber className="w-100" disabled={field.disabled} min={field.min} />
                  </Form.Item>
                </div>
                </Col>
              </Row> */}
            {/* <Divider className="my-3" />
              <Row gutter={32}>
                <Col xs={24} md={12}>
                  <span style={{ color: "#ff0000" }}>*</span>
                  {field.bold ? <strong>{field.title}</strong> : field.title}
                </Col>
                <Col xs={24} md={12}>
                  <Form.Item
                    className="m-0"
                    name={field.name}
                    rules={[
                      requireRule,
                      ({ getFieldValue }) => ({
                        validator(_, value) {
                          if (value < 0) {
                            return Promise.reject(new Error("Value should not be negative"));
                          }
                          return Promise.resolve();
                        }
                      }),
                      ...(_.isEqual(field.name, ["lot", "downpayment"])
                        ? [
                            ({ getFieldValue }) => ({
                              validator(_, value) {
                                if (value < getFieldValue("booking_fee_received")) {
                                  return Promise.reject(
                                    new Error("Downpayment should not be less than booking fee received")
                                  );
                                }
                                return Promise.resolve();
                              }
                            })
                          ]
                        : [])
                    ]}
                  >
                    <InputNumber className="w-100" disabled={field.disabled} />
                  </Form.Item>
                </Col>
              </Row> */}
          </>
        </Card>
        {balanceAmount > 0 && (
          <Card className="mb-3">
            <Space>
              <div className="lead">
                <TechBaseText content="{{INSTALLMENT_DETAILS}}" />
              </div>
            </Space>
            <>
              <Row gutter={32}>
                {InstallmentFields.map((field, index) => (
                  <Col xs={24} md={12} key={index}>
                    <span style={{ color: "#ff0000" }}>* </span>
                    {field.title}
                    <div>
                      <Form.Item
                        className="mt-1"
                        name={field.name}
                        rules={[
                          requireRule,
                          ({ getFieldValue }) => ({
                            validator(_, value) {
                              if (value < 0) {
                                return Promise.reject(new Error("Value should not be negative"));
                              }
                              return Promise.resolve();
                            }
                          })
                        ]}
                      >
                        <InputNumber className="w-100" disabled={field.disabled} min={field.min} />
                      </Form.Item>
                    </div>
                  </Col>
                ))}
              </Row>
              {/* <Row gutter={32}>
                  <Col xs={24} md={24}>
                  <span style={{ color: "#ff0000" }}>* </span>
                  {field.title}
                  <div>
                    <Form.Item
                    className="mt-1"
                    name={field.name}
                    rules={[
                      requireRule,
                      ({ getFieldValue }) => ({
                      validator(_, value) {
                        if (value < 0) {
                        return Promise.reject(new Error("Value should not be negative"));
                        }
                        return Promise.resolve();
                      }
                      })
                    ]}
                    >
                    <InputNumber className="w-100" disabled={field.disabled} min={field.min} />
                    </Form.Item>
                  </div>
                  </Col>
                </Row> */}
              {/* <Divider className="my-3" />
                <Row gutter={32}>
                  <Col xs={24} md={12}>
                    <span style={{ color: "#ff0000" }}>*</span>
                    {field.bold ? <strong>{field.title}</strong> : field.title}
                  </Col>
                  <Col xs={24} md={12}>
                    <Form.Item
                      className="m-0"
                      name={field.name}
                      rules={[
                        requireRule,
                        ({ getFieldValue }) => ({
                          validator(_, value) {
                            if (value < 0) {
                              return Promise.reject(new Error("Value should not be negative"));
                            }
                            return Promise.resolve();
                          }
                        })
                      ]}
                    >
                      <InputNumber className="w-100" disabled={field.disabled} min={field.min} />
                    </Form.Item>
                  </Col>
                </Row> */}
            </>
            {/* <Divider className="my-3" />
          <Row gutter={32}>
            <Col xs={24} md={12}>
              <span style={{ color: "#ff0000" }}>*</span>Sold Condition
            </Col>
            <Col xs={24} md={12}>
              <Form.Item className="m-0" name="sold_condition" rules={requiredRules}>
                <Select size="large" options={needsOptions} />
              </Form.Item>
            </Col>
          </Row> */}
          </Card>
        )}
        <Row className="w-100 d-flex footer-btn">
          <Col>
            <TechBaseButton onClick={() => onBack(form.getFieldsValue())}>
              <TechBaseText content="{{BACK}}" />
            </TechBaseButton>
          </Col>
          <Col>
            <TechBaseButton htmlType="submit" color="primary">
              <TechBaseText content="{{NEXT}}" />
            </TechBaseButton></Col>
        </Row>
      </Row>
    </Form>
  );
};

export default Step2;
