import TechBaseText from "DisplayCoreV2/Text";
import { Card, Col, Form, Input, Row, Select } from "antd";
import { relationshipOptions } from "components/Options";
import React from "react";
import { validateChineseRule, ValidateICRule } from "utils/rules";

const IntendedUserInfo = (props) => {
  const { genderOptions, index } = props;
  return (
    <Card>
      <div className="lead mb-2">
        <TechBaseText content="{{INTENDED_USER_INFORMATION}}" />
      </div>
      <Row gutter={32}>
        <Col xs={24} md={12}>
          <Form.Item
            name={[`intendedUser${index}`, "fullName"]}
            label={<TechBaseText content="{{FULL_NAME_AS_PER_IC}}" />}
          >
            <Input size="large" placeholder={TechBaseText({ content: "{{FULL_NAME}}" })} />
          </Form.Item>
        </Col>
        <Col xs={24} md={12}>
          <Form.Item
            name={[`intendedUser${index}`, "chineseName"]}
            label={<TechBaseText content="{{CHINESE_NAME}}" />}
            rules={[validateChineseRule]}
          >
            <Input size="large" placeholder={TechBaseText({ content: "{{CHINESE_NAME}}" })} />
          </Form.Item>
        </Col>
        <Col xs={24} md={12}>
          <Form.Item
            name={[`intendedUser${index}`, "relationship"]}
            label={<TechBaseText content="{{RELATIONSHIP}}" />}
          >
            <Select size="large" options={relationshipOptions} />
          </Form.Item>
        </Col>
        <Col xs={24} md={12}>
          <Form.Item
            name={[`intendedUser${index}`, "identityNo"]}
            label={<TechBaseText content="{{IDENTITY_CARD_NUMBER}}" />}
            rules={[ValidateICRule()]}
          >
            <Input size="large" placeholder={TechBaseText({ content: "{{IDENTITY_CARD_NUMBER}}" })} />
          </Form.Item>
        </Col>
        <Col xs={24} md={12}>
          <Form.Item name={[`intendedUser${index}`, "gender"]} label={<TechBaseText content="{{GENDER}}" />}>
            <Select size="large" options={genderOptions} />
          </Form.Item>
        </Col>
      </Row>
    </Card>
  );
};

export default IntendedUserInfo;
