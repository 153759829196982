import _ from 'lodash'

export default function permissionsChecker( moduleID, permissionList ) {
  let tmpIndex = _.findIndex( permissionList, { site_module_id: moduleID })

  if ( tmpIndex > -1 ){
    return permissionList[ tmpIndex ]
  } 

  return {
    can_read: false,
    can_update: false,
    can_delete: false,
    can_create: false
  }
}

export const getPermissions = ( moduleNames, siteModules, permissions ) => {
  if( _.isUndefined( moduleNames ) ){
    return {
      can_read: true,
      can_update: true,
      can_delete: true,
      can_create: true
    }
  }
  let tmpModule = _.find( siteModules, { name: moduleNames[0] } )
  let tmpPermission = permissionsChecker( tmpModule?.id, permissions )
  if( moduleNames.length > 1 && tmpPermission.can_read ){
    return getPermissions( moduleNames.slice(1), tmpModule.submodules, permissions )
  }
  return tmpPermission
}