import { createStore, applyMiddleware, compose } from "redux"
import rootReducer from "../reducers"
import reduxImmutableStateInvariant from "redux-immutable-state-invariant"
import thunk from "redux-thunk"
import { persistReducer } from "redux-persist"
import localForage from "localforage"

const persistConfig = {
  key: "neumore-admin-webdb",
  storage: localForage,
  blacklist: ["ajaxReducer", "ClientReducer"],
};

const persistedReducer = persistReducer(persistConfig, rootReducer);

export default function configureStoreFunction(initialState) {
  return createStore(
    persistedReducer,
    initialState,
    compose(applyMiddleware(thunk, reduxImmutableStateInvariant()))
  );
}
