import { useLoaderData, useNavigate, useLocation } from "react-router-dom";


const RouterWrapper = ({component, data}) => {
  const loaderData = useLoaderData();
  const navigate = useNavigate();
  const location = useLocation();

  return component({...data, router: {navigate, location, loaderData}})
};

export default RouterWrapper