import React, { useState, useEffect } from "react";
import _ from "lodash";
import Step1 from "./steps/step1";
import Step2 from "./steps/step2";
import Step3 from "./steps/step3";
import { requestSuccess } from "utils/requestHandler";

const PurchaseForm = (props) => {
  const [step, setStep] = useState(1);
  const [formData, setFormData] = useState({});
  const [selectedItem, setSelectedItem] = useState([]);

  useEffect(() => {
    props.getParamOptions("paymentOptions", "PaymentType");
    props.getProductsOptions();
    // props.getUnitUserCapacity();
  }, []);

  useEffect(() => {
    window.scrollTo(0, 0);
  }, [step]);

  useEffect(() => {
    let tmpItems = [];
    _.map(formData.step1?.items, (value, key) => {
      if (value > 0) {
        tmpItems.push({ ..._.find(props.productList, { ID: key }), quantity: value });
      }
    });
    setSelectedItem(tmpItems);
  }, [formData.step1]);

  const salesPayload = (value, type, bindID) => {
    const paymentPurpose = {
      addOn: "4",
      FSP: "3",
      unit: formData.step2.lot?.loanDetails?.installmentMonth > 1 ? "1" : "8",
    };
    const salesType = {
      addOn: "4",
      FSP: "3",
      unit: "2",
    };
    const step2Details = {
      loanDetails: {
        installmentMonth: 1,
        sumPerInstallment: 1,
      },
      ...formData.step2.lot,
      downpayment: formData.step2.lot.downpayment - formData.step2.booking_fee_received,
    };
    const items = {
      addOn: selectedItem.map((val) => ({
        productCode: val.ProductCode,
        quantity: val.quantity,
        productID: val.ID,
        productName: val.ProductName,
        singlePrice: val.Price,
        totalPrice: val.quantity * val.Price,
      })),
      FSP: [
        {
          bindUnitBookingID: props.bookingOrder?.SalesId ? props.bookingOrder.SalesId : undefined,
          productCode: props.selectedSlot.ProductCode,
          quantity: 1,
          productID: props.selectedSlot.ID,
          productName: props.selectedSlot.ProductName,
          singlePrice: props.bookingOrder?.AgreedPrice ?? props.selectedSlot.Price,
          totalPrice: props.bookingOrder?.AgreedPrice ?? props.selectedSlot.Price,
          paymentDetails: step2Details,
        },
      ],
      unit: [],
    };
    const intendedUser = [];
    if (formData.step1.intended_user_1 && !_.isEmpty(_.omitBy(formData.step1.intendedUser1, _.isNil))) {
      intendedUser.push(formData.step1.intendedUser1);
    }
    if (formData.step1.intended_user_2 && !_.isEmpty(_.omitBy(formData.step1.intendedUser2, _.isNil))) {
      intendedUser.push(formData.step1.intendedUser2);
    }
    return {
      salesByID: props.profile.MemberId,
      purchaserID: formData.step1.purchaserID.MemberId,
      memberID: formData.step1.memberID.MemberId,
      paymentType: value.paymentType.toString(),
      remarks: value.remarks,
      receipt: value.receipt?.base64 !== undefined ? value.receipt.base64 : "",
      salesType: salesType[type],
      paymentPurpose: paymentPurpose[type],
      bindUnitSalesID: bindID,
      unitID: type !== "FSP" ? props.selectedSlot.UnitID : undefined,
      lot: type === "unit" ? step2Details : undefined,
      items: items[type],
      bindUnitBookingID: props.bookingOrder?.SalesId ? props.bookingOrder.SalesId : undefined,
      needCondition: formData.step1.needCondition,
      intendedUsers: intendedUser,
    };
  };

  return (
    <>
      <div >
        {step === 1 && (
          <Step1
            {...props}
            formData={formData.step1}
            disabledPurchaserID={props.disabledPurchaserID}
            hideCreateNewPurchaser={props.hideCreateNewPurchaser}
            onNext={(value) => {
              setStep(step + 1);
              setFormData({
                ...formData,
                step1: value,
              });
            }}
          />
        )}
        {step === 2 && (
          <Step2
            {...props}
            formData={formData.step2}
            onNext={(value) => {
              setStep(step + 1);
              setFormData({ ...formData, step2: value });
            }}
            onBack={(value) => {
              setStep(step - 1);
              setFormData({ ...formData, step2: value });
            }}
          />
        )}
        {step === 3 && (
          <Step3
            {...props}
            formData={formData}
            selectedItem={selectedItem}
            disabledPORefNo={props.disabledPORefNo}
            onNext={(value) => {
              setFormData({ ...formData, step3: value });
              console.log(value);
              if (props.salesType === "FSP") {
                props.createSales(salesPayload(value, "FSP"), (response) => {
                  if (_.isEmpty(props.bookingOrder)) {
                    props.onChangeServiceHOC("showBookingForm", false);
                    props.onChangeServiceHOC("showPurchaseForm", false);
                    props.onChangeServiceHOC("selectedPackage", {});
                  } else {
                    props.onChangeHOC("selectedSlot", {});
                  }
                  requestSuccess(response.Message);
                });
              } else {
                const unitSales = (response) => {
                  props.createSales(salesPayload(value, "unit", response?.Data?.salesID), (response2) => {
                    props.onChangeHOC("page", "listing");
                    props.onChangeHOC("selectedSlot", {});
                    props.getFloorPlan(props.selectedArea);
                    requestSuccess(response2.Message);
                    // if (formData.step1.intended_user_1 && !_.isEmpty(_.omitBy(formData.step1.intendedUser1, _.isNil))) {
                    //   props.assignIntendedUser({
                    //     unitID: props.selectedSlot.UnitID,
                    //     ...formData.step1.intendedUser1,
                    //   });
                    // }
                    // if (formData.step1.intended_user_2 && !_.isEmpty(_.omitBy(formData.step1.intendedUser2, _.isNil))) {
                    //   props.assignIntendedUser({
                    //     unitID: props.selectedSlot.UnitID,
                    //     ...formData.step1.intendedUser2,
                    //   });
                    // }
                  });
                };
                if (selectedItem.length > 0) {
                  props.createSales(salesPayload(value, "addOn"), (response) => unitSales(response));
                } else {
                  unitSales();
                }
              }
            }}
            onBack={(value) => {
              setStep(step - 1);
              setFormData({ ...formData, step3: value });
            }}
          />
        )}
      </div>
    </>
  );
};

export default PurchaseForm;
