import React from "react"
import * as AntdIcons from '@ant-design/icons'

import { applyColor } from 'utils/apply-color'

const TechBaseIcon = props => {
  let {
    className,
    style,
    antIcon
  } = props.item

  if( antIcon ){
    let Component = AntdIcons[ antIcon ]
    return(
      <Component className={ className } style={ applyColor( style, props.colors ) }/>
    )
  }

  return (
    <i style={ applyColor( style, props.colors ) } className={ className || "" }/>
  )
}

export default TechBaseIcon