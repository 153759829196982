import React from "react"
import { Tree } from 'antd'

const TechbaseTree = props => {
  let {
    dataKey,
    className,
    style,
    showLine,
    blockNode,
    switcherIcon,
    selectTreeNodeActionKey
  } = props.item

  return (
    <Tree
      rootClassName={ className }
      style={ style }
      showLine={ showLine }
      blockNode={ blockNode }
      switcherIcon={ switcherIcon }
      expandedKeys={ props.expandedKeys?.[ dataKey ] ? props.expandedKeys[ dataKey ] : null }
      onSelect={ props.onSelectTreeNodeAction ? ( selectedKeys, e ) => { props.onSelectTreeNodeAction( selectTreeNodeActionKey, selectedKeys, e )} : null }
      treeData={ props[ dataKey ] ? props[ dataKey ] : [] }
    />
  )
}

export default TechbaseTree