import React, { Component } from "react";
import { connect } from "react-redux";
import _ from "lodash";

import { Post } from "utils/axios";
import { handleErrorMsg } from "utils";
import { requestError } from "utils/requestHandler";
import { addLoadingContent, removeLoadingContent } from "reducers/LoadingOverlay";

const HOC = (WrappedComponent) => {
  class WithHOC extends Component {
    state = {
      memberList: [],
      totalMembers: 0
    };

    onChangeHOC = (key, value) => this.setState({ [key]: value });
    load = (param) => {
      if (param) {
        this.props.addLoadingContent();
      } else {
        this.props.removeLoadingContent();
      }
    };

    getMemberList = (params) =>
      Post(`/member/viewMemberList`, params, this.getMemberListSuccess, this.getMemberListError, this.load);
    getMemberListSuccess = (payload) => this.setState({ memberList: payload.Data, totalMembers: payload.Total });
    getMemberListError = (error) => requestError(handleErrorMsg(error));

    getCustomerList = (params) =>
      Post(`/member/viewCustomerListSimple`, {...params, customerIC: params.ic, customerIDName: params.name}, this.getCustomerListSuccess, this.getCustomerListError, this.load);
    getCustomerListSuccess = (payload) => this.setState({ memberList: payload.Data, totalMembers: payload.Total });
    getCustomerListError = (error) => requestError(handleErrorMsg(error));

    render = () => {
      return (
        <WrappedComponent
          {...this.props}
          {...this.state}
          getMemberList={this.getMemberList}
          getCustomerList={this.getCustomerList}
        />
      );
    };
  }

  const mapStateToProps = (state) => ({
    data: state,
    loadingContent: state.LoadingOverlayReducer.loadingContent,
    profile: state.ProfileReducer.profile
  });
  return connect(mapStateToProps, { addLoadingContent, removeLoadingContent })(WithHOC);
};

export default HOC;
