import Axios from "axios";
import { toast } from "react-toastify";
import _ from "lodash";
import { getItem, clearItem, storeItem } from "utils/tokenStore";

import { setUserProfile, setCountry, setClientCountry, setPermissions } from "reducers/profile";
import { setSiteModule } from "reducers/client";
import { beginAjaxCall, ajaxCallError, ajaxCallSuccess } from "./Ajax";
import getDomainURL from "utils/api";
import { getCipherHeader } from "utils/cipher-text";
import { processSite } from "utils/host";
import { convertObjectToBase64 } from "utils/objToBase64";

export const getProfile = (client) => (dispatch) => {
  Axios.defaults.headers = {
    common: {
      Authorization: getItem("IMPERSONATE_TOKEN") || getItem("TECHBASE_TOKEN")
    },
    "Access-Control-Allow-Origin": "*",
    Site: processSite(window.location.host)
  };
  Axios.post(`${getDomainURL()}/member/getMemberDetail`, {})
    .then((response) => {
      if(!_.isEmpty(response.headers['authorization']) && !_.isEqual(getItem("TECHBASE_TOKEN"), response.headers['authorization'])){
        storeItem("TECHBASE_TOKEN", response.headers['authorization']);
      }
      dispatch(setUserProfile(response.data.Data));
      getPermissions(dispatch, response.data.Data.RoleID.toString())
    })
    .catch((error) => {
      if (error.response.status === 401) {
        toast.error("Due to inactivity, your session has expired, please login again.");
      }
      if (getItem("IMPERSONATE_TOKEN")) {
        clearItem("IMPERSONATE_TOKEN");
        window.location.href = "/dashboard/member-list";
      } else {
        clearItem("TECHBASE_TOKEN");
        window.location.href = "/login";
      }
    });
};

const getPermissions = (dispatch, roleID) => {
  dispatch(beginAjaxCall());
  Axios.defaults.headers = {
    "Access-Control-Allow-Origin": "*",
    Site: processSite(window.location.host)
  };
  Axios.post(`${getDomainURL()}/menu/getRoleMenuList`, {roleID: roleID})
    .then((response) => {
      dispatch(setPermissions(_.filter(response.data.Data, {StatusX: 1})));
      dispatch(ajaxCallSuccess());
    })
    .catch((error) => {
      if (error.response.status === 401) {
        toast.error("Due to inactivity, your session has expired, please login again.");
      }
      dispatch(ajaxCallError(error.response.data.message));
      if (getItem("IMPERSONATE_TOKEN")) {
        clearItem("IMPERSONATE_TOKEN");
      } else {
        clearItem("TECHBASE_TOKEN");
      }
    });
};

const getCountry = (dispatch, countryIso2) => {
  dispatch(beginAjaxCall());
  Axios.defaults.headers = {
    common: {
      Authorization: getItem("IMPERSONATE_TOKEN") || getItem("TECHBASE_TOKEN")
    },
    "Access-Control-Allow-Origin": "*",
    Site: processSite(window.location.host)
  };
  Axios.get(`${getDomainURL()}/countries/${countryIso2}`)
    .then((response) => {
      dispatch(setCountry(response.data));
      dispatch(ajaxCallSuccess());
    })
    .catch((error) => {
      dispatch(ajaxCallError(error.response.data.message));
    });
};

const getClientCountry = (dispatch, clientId, countryIso2) => {
  dispatch(beginAjaxCall());
  Axios.defaults.headers = {
    common: {
      Authorization: getCipherHeader()
    },
    "Access-Control-Allow-Origin": "*",
    Site: processSite(window.location.host)
  };
  Axios.get(`${getDomainURL()}/clients/${clientId}/countries/${countryIso2}`)
    .then((response) => {
      dispatch(setClientCountry(response.data));
      dispatch(ajaxCallSuccess());
    })
    .catch((error) => {
      dispatch(ajaxCallError(error.response.data.message));
    });
};

const getSitePermission = (dispatch, siteId) => {
  dispatch(beginAjaxCall());
  Axios.defaults.headers = {
    "Access-Control-Allow-Origin": "*",
    Authorization: getItem("IMPERSONATE_TOKEN") || getItem("TECHBASE_TOKEN"),
    Site: processSite(window.location.host)
  };
  Axios.get(`${getDomainURL()}/site_modules/${siteId}?query=${convertObjectToBase64({ status: true, type: "tree" })}`)
    .then((response) => {
      dispatch(setSiteModule(response.data));
      dispatch(ajaxCallSuccess());
    })
    .catch((error) => {
      dispatch(ajaxCallError(error.response.data.message));
    });
};
