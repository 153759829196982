import React, { Component } from "react";
import { connect } from "react-redux";
import _ from "lodash";

import { Post } from "utils/axios";
import { handleErrorMsg } from "utils";
import { requestError, requestSuccess } from "utils/requestHandler";
import { addLoadingContent, removeLoadingContent } from "reducers/LoadingOverlay";

const HOC = (WrappedComponent) => {
  class WithHOC extends Component {
    state = {
      query: { pageNumber: 1, pageSize: 10, productStatus: "1", productType: 4 },
      servicePackageList: [],
      totalServices: 0,
      selectedPackage: {},
      showPurchaseForm: false,
      showBookingForm: false,
      showCreateModal: false,
      showUpdateModal: false
    };

    onChangeServiceHOC = (key, val) => this.setState({ [key]: val });
    load = (param) => {
      if (param) {
        this.props.addLoadingContent();
      } else {
        this.props.removeLoadingContent();
      }
    };

    getServicePackageList = () =>
      Post(
        `/product/getProductList`,
        this.state.query,
        this.getServicePackageListSuccess,
        this.getServicePackageListError,
        this.load,
        true
      );
    getServicePackageListSuccess = (payload) =>
      this.setState({ servicePackageList: payload?.Data, totalServices: payload?.Total });
    getServicePackageListError = (error) => requestError(handleErrorMsg(error));

    createProduct = (params) =>
      Post(
        `/product/createProduct`,
        {
          ...params,
          productCategory: "10004",
          productType: "4",
          productPrice: parseFloat(params.productPrice),
          productDisplayType: ["8", "9"],
          createdBy: this.props.profile.MemberId
        },
        this.createProductSuccess,
        this.createProductError,
        this.load
      );
    createProductSuccess = (payload) => {
      this.getServicePackageList();
      requestSuccess(payload.Data);
      this.setState({ showCreateModal: false })
    };
    createProductError = (error) => requestError(handleErrorMsg(error));

    updateProduct = (params) =>
      Post(
        `/product/updateProductBasic`,
        {
          ...params,
          productCategory: "10004",
          productType: "4",
          productPrice: parseFloat(params.productPrice),
          productDisplayType: ["9", "8"],
          isActive: true,
          createdBy: this.props.profile.MemberId
        },
        this.updateProductSuccess,
        this.updateProductError,
        this.load
      );
    updateProductSuccess = (payload) => {
      this.getServicePackageList();
      requestSuccess(payload.Data);
      this.setState({ showUpdateModal: false })
    };
    updateProductError = (error) => requestError(handleErrorMsg(error));

    render = () => {
      return (
        <WrappedComponent
          {...this.props}
          {...this.state}
          onChangeServiceHOC={this.onChangeServiceHOC}
          getServicePackageList={this.getServicePackageList}
          createProduct={this.createProduct}
          updateProduct={this.updateProduct}
        />
      );
    };
  }

  const mapStateToProps = (state) => ({
    data: state,
    profile: state.ProfileReducer.profile
  });
  return connect(mapStateToProps, { addLoadingContent, removeLoadingContent })(WithHOC);
};

export default HOC;
