import React, { useEffect } from 'react'
import { Form } from 'antd'

import CoreRenderFunction from 'DisplayCore'
import { applyColor } from 'utils/apply-color'

const TechBaseForm = props => {
  let {
    style,
    layout,
    children,
    onFinish,
    onInitial,
    onChange,
    formRef,
    customizedStyle
  } = props.item
  const [ form ] = Form.useForm()

  useEffect(()=>{
    if( onInitial && props[ onInitial ] ){
      props[ onInitial ]( formRef ? props[ formRef ] : form )
    } else if( typeof props.onInitialForm === "function" ){
      props.onInitialForm( formRef ? props[ formRef ] : form )
    } 
  }, [])
  return(
    <Form
      style={ style }
      form={ formRef ? props[ formRef ] : form }
      layout={ layout || 'vertical' }
      onFinish={ onFinish ? props[onFinish] : props.onSubmitForm }
      onValuesChange={ ( changedValue, values ) => {
        if ( onChange && typeof props.onClickAction === 'function' ) {
          props.onClickAction( onChange, values, changedValue )
        } else if( typeof props.updateFormValues === "function" ){
          props.updateFormValues( values, changedValue )
        }
      }}>
      {
        customizedStyle && <style>{ applyColor( customizedStyle, props.colors )}</style>
      }
      {
        children?.map( child => <CoreRenderFunction {...props} item={ child } form={ form } />)
      }
    </Form>
  )
}

export default TechBaseForm