import React, { useState, useEffect } from "react";
import _ from "lodash";
import Step1 from "./steps/step1";
import Step2 from "./steps/step2";
import Step3 from "./steps/step3";
import { requestSuccess } from "utils/requestHandler";

const PurchaseMonumentForm = (props) => {
  const { selectedItems } = props; // Destructure selectedItems from props
  const [step, setStep] = useState(1);
  const [formData, setFormData] = useState({});
  const [isSubmitting, setIsSubmitting] = useState(false);

  useEffect(() => {
    props.getParamOptions("paymentOptions", "PaymentType");
    props.getProductsOptions();
  }, []);


  useEffect(() => {
    window.scrollTo(0, 0);
  }, [step]);



  const resetForm = () => {
    setStep(1);
    setFormData({});
  };


  // Generate sales payload
  const salesPayload = (value) => {
    if (isSubmitting) return;

    const items = selectedItems.map((item) => ({
      productCode: item.ProductCode,
      quantity: item.quantity,
      paymentDetails: {
        downpayment: formData.step2?.lot?.downpayment || 0,
        loanDetails: {
          installmentMonth: formData.step2?.lot?.installmentMonth || 1,
          sumPerInstallment: formData.step2?.lot?.sumPerInstallment || 1,
        },
        unitPrice: item.Price,
        unitDiscount: formData.step2?.lot?.unitDiscount || 0,
        maintenanceFee: formData.step2?.lot?.maintenanceFee || 0,
      },
    }));
    setIsSubmitting(true);

    try {
      return {
        salesByID: props.profile.MemberId,
        purchaserID: formData.step1?.purchaserID?.MemberId,
        memberID: formData.step1?.memberID?.MemberId,
        paymentType: value.paymentType?.toString(),
        remarks: value.remarks || "Monument Sales",
        receipt: value.receipt?.base64 !== undefined ? value.receipt.base64 : "",
        salesType: "9",
        dialectGroupName: formData.step2?.dialectGroupName,
        dialectGroupValue: formData.step2?.dialectGroupValue,
        needCondition: formData.step1?.needCondition,
        items: items, // Include populated items array
      };
    } finally {
      setIsSubmitting(false);
    }
  };

  return (
    <>
      {step === 1 && (
        <Step1
          {...props}
          formData={formData.step1}
          disabledPurchaserID={props.disabledPurchaserID}
          hideCreateNewPurchaser={props.hideCreateNewPurchaser}
          onNext={(value) => {
            setStep(step + 1);
            setFormData({
              ...formData,
              step1: value
            });
          }}
        />
      )}
      {step === 2 && (
        <Step2
          {...props}
          formData={formData.step2}
          onNext={(value) => {
            setStep(step + 1);
            setFormData({ ...formData, step2: value });
          }}
          onBack={(value) => {
            setStep(step - 1);
            setFormData({ ...formData, step2: value });
          }}
        />
      )}
      {step === 3 && (
        <Step3
          {...props}
          formData={formData}
          selectedItems={selectedItems}
          disabledPORefNo={props.disabledPORefNo}
          onNext={(value) => {
            setFormData({ ...formData, step3: value });

            if (props.salesType === "FSP") {
              props.createSales(salesPayload(value, "FSP"), (response) => {
                if (_.isEmpty(props.bookingOrder)) {
                  props.onChangeServiceHOC("showBookingForm", false);
                  props.onChangeServiceHOC("showPurchaseForm", false);
                  props.onChangeServiceHOC("selectedPackage", {});
                } else {
                  props.onChangeHOC("selectedSlot", {});
                }
                requestSuccess(response.Message);
                resetForm(); // Reset form state
              });
            } else {
              const unitSales = (response) => {
                props.createSales(salesPayload(value, "unit", response?.Data?.salesID), (response2) => {
                  props.onChangeHOC("page", "listing");
                  props.onChangeHOC("selectedSlot", {});
                  requestSuccess(response2.Message);
                  // Reset form state
                  resetForm();
                });
              };

              if (selectedItems.length < 0) {
                props.createSales(salesPayload(value, "addOn"), (response) => {
                  unitSales(response);
                  resetForm(); // Reset form state
                });
              } else {
                unitSales();
                resetForm(); // Reset form state
              }
            }
          }}
          onBack={(value) => {
            setStep(step - 1);
            setFormData({ ...formData, step3: value });
          }}
        />
      )}
    </>
  );
};

export default PurchaseMonumentForm;
