import React from 'react'
import { Form, Radio } from 'antd'

import CoreRenderFunction from 'DisplayCore'
import { getSelectedLanguageContent } from 'utils'

const TechBaseRadio = props => {
  let{
    name,
    label,
    style,
    className,
    actionKey,
    dataKey,
    inputConfig,
    rules
  } = props.item

  const processRules = rules => {
    return rules.map(rule => {
      if (Object.keys(rule).includes('equal')) {
        return ({ getFieldValue }) => ({
          validator(_, value) {
            if (!value || getFieldValue(rule.equal) === value) {
              return Promise.resolve();
            }
            return Promise.reject(new Error(getSelectedLanguageContent(rule.message, props.selectedLanguage)))
          },
        })
      } else {
        return { ...rule, message: getSelectedLanguageContent(rule.message, props.selectedLanguage) }
      }
    })
  }

  return (
    <Form.Item
      name={ name }
      label={ getSelectedLanguageContent( label )}
      valuePropName='value'
      rules={rules && processRules(rules)}
    >
      <Radio.Group
        name={ name }
        style={ style }
        valuePropName='value'
        className={ className }
        onChange={ e => {
          if( props.onClickAction ) {
            props.onClickAction( actionKey, e.target.value )
          }
        }}
      >
        {
          inputConfig?.static && props[ dataKey ].map( option => (
            <Radio value={ option.value }>{ getSelectedLanguageContent( option.label )}</Radio>
          ))
        }
        {
          inputConfig?.dynamic && props[ dataKey ].map( option => (
            <Radio
              style={ inputConfig.style }
              className={ inputConfig.className }
              value={ option[ inputConfig.value ]
            }>
              <CoreRenderFunction
                { ...props }
                item={ inputConfig.optionItem }
                optionData={ option }
              />
            </Radio>
          ))
        }
      </Radio.Group>
    </Form.Item>
  )
}

export default TechBaseRadio
