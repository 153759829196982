import _ from "lodash";

import { getContraColor } from "utils";

const defaultColors = {
  primary: "#87430D",
  secondary: "#B8A99C",
  tertiary: "#FFFFFF",
  quaternary: "#87430D",
  header: "#FFFFFF",
  background: "#FFFFFF",
  success: "#22BB33",
  danger: "#BB2124",
  menuActive: "#B8A99C",
  tableThead: "#f3ece6",
  tableTheadText: "#FFFFFF",
};

export const applyColor = (style, colors) => {
  if (typeof style === "string") {
    if (!_.isEmpty(style)) {
      let tmpStyle = style.replaceAll(/\$([a-zA-Z]+)/g, (matched) => `${defaultColors[matched.slice(1)] || matched}`);
      tmpStyle = tmpStyle.replaceAll(
        /\!([a-zA-Z]+)/g,
        (matched) =>
          `${getContraColor(!_.isEmpty(colors) ? colors[matched.slice(1)] : defaultColors[matched.slice(1)]) || matched
          }`
      );
      return tmpStyle;
    } else {
      return "";
    }
  } else if (typeof style === "object") {
    if (!_.isEmpty(style)) {
      let tmp = _.cloneDeep(style);
      _.map(tmp, (val, key) => {
        tmp[key] = applyColor(val, colors);
      });
      return tmp;
    } else {
      return {};
    }
  } else {
    return style;
  }
};
