import React from "react"
import _ from "lodash"
import Moment from "moment"

import { applyColor } from "utils/apply-color"
import { getSelectedLanguageContent, truncateTextWithEllipsis } from "utils"

const TechBaseText = (props) => {
  const { Tag, content, style, className, variant, decimalPoint, maxChars, selectedLanguage } = props

  const getContent = () => {
    let tmpString = _.cloneDeep(content)

    if (variant === "price" && typeof tmpString === "number") {
      tmpString = tmpString.toFixed(2)
      if (!_.isEmpty(tmpString)) {
        tmpString = `${tmpString.replace(/\B(?=(\d{3})+(?!\d))/g, ",")}`
      }
    }

    if (typeof tmpString === "number" && variant === "number") {
      tmpString = tmpString.toFixed(decimalPoint || 0)
    }

    if (variant === "date" && Moment(tmpString).isValid() && typeof tmpString !== "number") {
      tmpString = Moment(tmpString).format("YYYY-MM-DD")
    }
    return getSelectedLanguageContent(tmpString, selectedLanguage)
  }

  if( Tag ){
    return (
      <Tag style={applyColor(style)} className={className}>
        {truncateTextWithEllipsis(getContent(), maxChars)}
      </Tag>
    )
  } else {
    return truncateTextWithEllipsis(getContent(), maxChars)
  }
}

export default TechBaseText
