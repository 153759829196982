import _ from 'lodash'

export const mixAndMatchCurrentStatus = ( mixAndMatchList, subtotal, profile ) => {
  if( mixAndMatchList ){
    let mixAndMatch = _.sortBy( mixAndMatchList, [ "amount" ] )
    let mixAndMatchIndex = -1 
    
    if( profile ){
      mixAndMatch.forEach(( val, index ) => {
        if( 
          ( val.roleChange === "" || val.roleChange === profile.role_name ) &&
          ( val.tierChange === "" || val.tierChange === profile.client_user_member?.client_tier_id )
        ){
          mixAndMatchIndex = index
        }
      })
    }

    if( mixAndMatchIndex > -1 ){
      mixAndMatch = mixAndMatch.slice( mixAndMatchIndex + 1 )
    }
    if( mixAndMatch.length > 0 ){
      let tmp = _.filter( mixAndMatch, val => val.amount <= subtotal )
      let tmpCurrent, tmpNext
      if( tmp.length > 0 ){
        tmpCurrent = tmp[ tmp.length - 1 ]
      } else {
        tmpCurrent = {}
      }

      tmp = _.filter( mixAndMatch, val => val.amount > subtotal )
      if( tmp.length > 0 ){
        tmpNext = tmp[0]
      } else {
        tmpNext = {}
      }
      return({
        current: tmpCurrent,
        next: tmpNext
      })
    }
  }
  return({})
}