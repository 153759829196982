import React, { useState, useEffect } from "react";
import _ from "lodash";
import { Input, Typography } from "antd";

const { Text } = Typography;
const { Search } = Input;

const TBSearchField = (props) => {
  const {
    value,
    valueKey = "value",
    onChange,
    onSearch = () => {},
    extra = () => {},
    updateSearchValue,
    directValue
  } = props;
  const [searchValue, setSearchValue] = useState("");
  const [error, setError] = useState("");

  useEffect(() => {
    if (updateSearchValue) {
      let tmp = typeof value === "string" ? value : value?.[valueKey] ?? searchValue;
      if (searchValue !== tmp) {
        setSearchValue(tmp);
      }
      if (!_.isEmpty(value)) {
        setError("");
      }
    }
  }, [value]);

  return (
    <>
      <Search
        value={searchValue}
        onChange={(e) => {
          directValue ? onChange(e.target.value) : setSearchValue(e.target.value);
        }}
        {..._.omit(props, ["value", "onChange", "onSearch", "extra"])}
        onSearch={(val, target, action) => {
          if (action.source === "input") {
            onSearch(val, onChange, setError);
          } else if (action.source === "clear") {
            onChange();
          }
        }}
      />
      {error && (
        <div>
          <Text type="danger">{error}</Text>
        </div>
      )}
      {extra(value)}
    </>
  );
};

export default TBSearchField;
