import _ from "lodash";
import TechBaseText from "DisplayCoreV2/Text";
import { isOver18YearsOld, validateMyKad } from "utils";

export const requireRule = {
  required: true,
  message: <TechBaseText content="{{REQUIRED_MESSAGE}}" />
};

export const ValidateICRule = (identityType = "IC") => ({
  validator: (x, value) =>
    _.isEmpty(value) || validateMyKad(value) || identityType !== "IC"
      ? Promise.resolve()
      : Promise.reject(new Error(TechBaseText({ content: "{{INVALID_IC_FORMAT}}" })))
});

export const ValidateAgeRule = {
  validator: (x, value) =>
    isOver18YearsOld(value)
      ? Promise.resolve()
      : Promise.reject(new Error(TechBaseText({ content: "{{REGISTER_AGE_RESTRICTION}}" })))
};

export const validateChineseRule = {
  validator: (x, value) => {
    const chineseCharRegex = /^[\u4e00-\u9fa5]+$/;
    return _.isEmpty(value) || chineseCharRegex.test(value)
      ? Promise.resolve()
      : Promise.reject(new Error(TechBaseText({ content: "{{ONLY_CHINESE_CHARACTER_ARE_ALLOWED}}" })));
  }
};

export const validateMobileNumberRule = (withoutPrefix) => ({
  validator: (x, value="") => {
    const cleanedPhoneNumber = value.replace(/[\s-()]/g, "");
    const phoneRegex = withoutPrefix ? /^(\0[0-9]{9,10}|01[0-46-9][0-9]{7,8}|0[2-9][0-9]{7,8})$/ : /^(\+60[0-9]{9,10}|01[0-46-9][0-9]{7,8}|0[2-9][0-9]{7,8})$/;
    return _.isEmpty(value) || phoneRegex.test(cleanedPhoneNumber)
      ? Promise.resolve()
      : Promise.reject(new Error(TechBaseText({ content: "{{INVALID_MOBILE_NUMBER}}" })));
  }
});

export const validatePostCodeRule =  {
  validator: (x, value) => {
    return /^\d{5}$/.test(value)
      ? Promise.resolve()
      : Promise.reject(new Error(TechBaseText({ content: "{{INVALID_POSTCODE}}" })));
  }
}
