import React, { useState, useEffect } from 'react'
import { Button } from 'antd'
import cx from 'classnames'
import _ from 'lodash'

import { getSelectedLanguageContent } from 'utils'

const ProductVariant = props => {
  let {
    selectedLanguage,
    productVariants,
    productVariantGroups,
    productVariantCombination,
    selectedVariant,
    updateSelectedVariantFunc,
    isInvalid
  } = props
  
  const [ level1, updateLevel1 ] = useState([])
  const [ level1Name, updateLevel1Name ] = useState("")
  const [ level2, updateLevel2 ] = useState([])
  const [ level2Name, updateLevel2Name ] = useState("")
  
  useEffect(()=>{
    let tmpLevel1 = _.uniq( _.filter( _.map( productVariantCombination, "variant_level1_id" ), val => !_.isNull( val ) ) )
    let tmpLevel2 = _.uniq( _.filter( _.map( productVariantCombination, "variant_level2_id" ), val => !_.isNull( val ) ) )
    if( !_.isEmpty( tmpLevel1 ) ){
      updateLevel1Name( 
        getSelectedLanguageContent(
          _.find( 
            productVariantGroups, 
            { id: _.find( productVariants, { id: tmpLevel1[0] } )?.site_product_variant_group_id }
          )?.name,
          selectedLanguage
        )
      )
    }
    if( !_.isEmpty( tmpLevel2 ) ){
      updateLevel2Name( 
        getSelectedLanguageContent( 
          _.find( 
            productVariantGroups, 
            { id: _.find( productVariants, { id: tmpLevel2[0] } )?.site_product_variant_group_id }
          )?.name,
          selectedLanguage
        )
      )
    }
    updateLevel1( tmpLevel1 )
    updateLevel2( tmpLevel2 )
  }, [ productVariantCombination, productVariantGroups, productVariants ])

  const checkCombination = ( level, val )=> {
    if( level === 1 && selectedVariant.L2 ){
      let tmp = _.findIndex( _.filter( productVariantCombination, { variant_level2_id: selectedVariant.L2 } ), { variant_level1_id: val } )
      if( tmp === -1 ){
        return true
      }
    } else if( level === 2 && selectedVariant.L1 ) {
      let tmp = _.findIndex( _.filter( productVariantCombination, { variant_level1_id: selectedVariant.L1 } ), { variant_level2_id: val } )
      if( tmp === -1 ){
        return true
      }
    }
    return false
  }

  return(
    <div className='mb-3'>
      {
        !_.isEmpty( level1 ) && (
          <div className='mb-2'>
            <h6><strong>{ level1Name }</strong></h6>
            <div className='d-flex variant-button' style={{ columnGap: '8px', rowGap: '8px' }}>
              {
                level1.map( val => (
                  <Button
                    className={
                      cx( 'product-variant-button', {
                        active: selectedVariant.L1 === val,
                        'not-available': selectedVariant.L1 !== val && checkCombination( 1, val )
                      })
                    }
                    onClick={() => updateSelectedVariantFunc({ L1: selectedVariant.L1 === val ? null : val }) }>
                    { _.find( productVariants, { id: val } )?.name }
                  </Button>
                ))
              }
            </div>
          </div>
        )
      }
      {
        !_.isEmpty( level2 ) && (
          <div className='mb-2'>
            <h6><strong>{ level2Name }</strong></h6>
            <div className='d-flex variant-button' style={{ columnGap: '8px', rowGap: '8px' }}>
              {
                level2.map( val => (
                  <Button
                    // variant='flat'
                    className={
                      cx( 'product-variant-button', {
                        active: selectedVariant.L2 === val,
                        'not-available': selectedVariant.L2 !== val && checkCombination( 2, val )
                      })
                    }
                    onClick={() => updateSelectedVariantFunc({ L2: selectedVariant.L2 === val ? null : val }) }>
                    { _.find( productVariants, { id: val } )?.name }
                  </Button>
                ))
              }
            </div>
          </div>
        )
      }
      {
        isInvalid && <div style={{ fontSize: "12px", color: "#ff0000" }}>{ getSelectedLanguageContent("{{INVALID_COMBINATION}}") }</div>
      }
    </div>
  )
}

export default ProductVariant