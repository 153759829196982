import React, { Component } from "react";
import { connect } from "react-redux";
import _ from "lodash";

import { Get, Post } from "utils/axios";
import { addLoadingContent, removeLoadingContent } from "reducers/LoadingOverlay";

const HOC = (WrappedComponent) => {
  class WithHOC extends Component {
    state = {
      productTypes: [],
      productCategories: [],
      productDisplayTypes: []
    };

    load = (param) => {
      if (param) {
        this.props.addLoadingContent();
      } else {
        this.props.removeLoadingContent();
      }
    };

    getParamOptions = (key, category) =>
      Post(
        `/util/getParameterValue`,
        { category: category },
        (payload) => this.getParamOptionsSuccess(payload, key),
        this.getParamOptionsError,
        this.load,
        true
      );
    getParamOptionsSuccess = (payload, key) => this.setState({ [key]: payload.Data });
    getParamOptionsError = (error) => {};

    getProductParamOptions = () =>
      Get(
        `/product/getProductCreationSetup`,
        this.getProductParamOptionsSuccess,
        this.getProductParamOptionsError,
        this.load
      );
    getProductParamOptionsSuccess = (payload, key) =>
      this.setState({
        productCategories: payload.Data?.categories,
        productTypes: payload.Data?.types,
        productDisplayTypes: payload.Data?.displayTypes
      });
    getProductParamOptionsError = (error) => {};

    render = () => {
      return (
        <WrappedComponent
          {...this.props}
          {...this.state}
          getParamOptions={this.getParamOptions}
          getProductParamOptions={this.getProductParamOptions}
        />
      );
    };
  }

  return connect(() => ({}), { addLoadingContent, removeLoadingContent })(WithHOC);
};

export default HOC;
