export const relationshipOptions = [
  { value: "AN", label: "Ancestor" },
	{ value: "AU", label: "Aunty" },
	{ value: "BR", label: "Brother" },
	{ value: "BIL", label: "Brother In Law" },
	{ value: "CO", label: "Cousin" },
	{ value: "DA", label: "Daughter" },
	{ value: "DIL", label: "Daughter In Law" },
	{ value: "FA", label: "Father" },
	{ value: "FIW", label: "Father In Law" },
	{ value: "FF", label: "Foster Father" },
	{ value: "FM", label: "Foster Mother" },
	{ value: "FR", label: "Friend" },
	{ value: "GRD", label: "Grand daughter" },
	{ value: "GRA", label: "Grandaunty" },
	{ value: "GRF", label: "Grandfather" },
	{ value: "GRM", label: "Grandmother" },
	{ value: "GRS", label: "Grandson" },
	{ value: "GRGF", label: "Great Grandfather" },
	{ value: "GRGM", label: "Great Grandmother" },
	{ value: "HU", label: "Husband" },
	{ value: "MO", label: "Mother " },
	{ value: "MIL", label: "Mother In Law" },
	{ value: "NE", label: "Nephew" },
	{ value: "NI", label: "Niece" },
	{ value: "NO", label: "None" },
	{ value: "SE", label: "Self" },
	{ value: "SI", label: "Sister" },
	{ value: "SIL", label: "Sister In Law" },
	{ value: "SO", label: "Son" },
	{ value: "SF", label: "Step Father" },
	{ value: "SM", label: "Step Mother" },
	{ value: "UN", label: "Uncle" },
	{ value: "WI", label: "Wife" }
]

export const needsOptions = [
	{ value: "A", label: "As-Need" },
	{ value: "P", label: "Pre-Need" }
]

export const paymentOptions = [
	{ value: 55, label: "Cash" },
	{ value: 66, label: "Cheque" },
	{ value: 7, label: "Credit / Debit Card" },
	{ value: 100, label: "Online Payment" }
]