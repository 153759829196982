import { Component } from "react";
import { connect } from "react-redux";
import _ from "lodash";
import moment from "moment";
import { toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";

import { Post } from "utils/axios";
import { handleErrorMsg, thousandSeperatorFormatter } from "utils";
import { requestError, requestSuccess } from "utils/requestHandler";
import { addLoadingContent, removeLoadingContent } from "reducers/LoadingOverlay";
import { PDFGenerator } from "utils/pdf-generator";

const HOC = (WrappedComponent) => {
  class WithHOC extends Component {
    state = {
      query: { pageNumber: 1, pageSize: 10, agentCode_Name_IC: this.props.profile.Role !== 'AD' ? this.props.profile.MemberId : undefined, salesType: [9], },
      orders: [],
      totalOrders: {},
      selectedOrder: {},
      showAddAuthRepModal: false,
      showDetailModal: false
    };

    load = (param) => {
      if (param) {
        this.props.addLoadingContent();
      } else {
        this.props.removeLoadingContent();
      }
    };
    onChangeHOC = (key, val) => this.setState({ [key]: val });

    getOrders = (query) => Post("/sales/getOrderList", query, this.getOrdersSuccess, this.getOrdersError, this.load);
    getOrdersSuccess = (payload) => this.setState({ orders: payload.Data, totalOrders: payload.Total });
    getOrdersError = (error) => { };

    getSelectedOrder = (order, type) =>
      Post(
        `/sales/getOrderDetails`,
        { salesID: order.SalesID },
        (payload) => this.getSelectedOrderSuccess(payload, type, order),
        this.getSelectedOrderError,
        this.load
      );
    getSelectedOrderSuccess = (payload, type, order) => {
      let tmp = {
        ...order,
        ...payload.Data?.[0],
        Subtotal: payload.Data?.MonumentPurchaseDetails?.[0].TotalAmount ?? 0,
        ...payload.Data?.MonumentPurchaseDetails?.[0],
        ...payload.Data?.MonumentPurchaseInstallment?.[0],
        TotalInstallmentAmount: payload.Data?.MonumentPurchaseInstallment?.[0].TotalAmount ?? 0
      };
      this.setState({
        selectedOrder: tmp,
        showDetailModal: type === "details",
        showAddAuthRepModal: type === "authRep",
        showAddSignatureModal: type === "digitalSignature"
      });
    };
    getSelectedOrderError = (error) => requestError(handleErrorMsg(error));

    addAuthorisedRep = (params) =>
      Post(
        `/sales/addAuthorizedRepresentative`,
        params,
        this.addAuthorisedRepSuccess,
        this.addAuthorisedRepError,
        this.load
      );
    addAuthorisedRepSuccess = (payload) => {
      requestSuccess(payload.Data)
      this.setState({ selectedOrder: {}, showAddAuthRepModal: false });
      this.getOrders(this.state.query);
    };
    addAuthorisedRepError = (error) => requestError(handleErrorMsg(error));

    addDigitalSignature = (base64) =>
      Post(
        `/sales/updateSalesSignature`,
        { salesID: this.state.selectedOrder.SalesID, signature: base64 },
        this.addDigitalSignatureSuccess,
        (error) => requestError(handleErrorMsg(error)),
        this.load
      );
    addDigitalSignatureSuccess = (payload) => {
      requestSuccess(payload.Data)
      this.setState({ selectedOrder: {}, showAddSignatureModal: false });
      this.getOrders(this.state.query);
    };

    getPurchaseOrders = (salesID) =>
      Post(
        "/form/getPurchaseOrder",
        { salesID: salesID },
        this.getPurchaseOrdersSuccess,
        this.getPurchaseOrdersError,
        this.load
      );
    getPurchaseOrdersSuccess = (payload) => {
      if (!payload?.Data?.details){
        console.error("Missing details in API response:", payload);
        toast.error("Failed to generate the purchase order. Missing required details.");
        return;
      }
      // const details = payload.Data.details;
      // const items = payload.Data?.item ?? [];
      // const iu = payload.Data?.iu ?? [];
      const data = payload.Data.details;

      const POData = {
      // PDFGenerator("/forms/PurchaseOrderForm.html", {
        poNo: data.SalesID,
        date: moment(data.SalesDate).format("YYYY-MM-DD"),

        purchaserFullname_asIC: data.Fullname,
        purchaserIC_NO: data.IC,
        purchaserNationality: data.Country_Nationality,
        dateOfBirth: data.DOB,
        purchaserPermanentAddress: data.ResidentialAddress,
        purchaserPscode: data.ResidentialPostCode,
        purchaserCorrsAddress: data.MailingAddress,
        purchaserCorrPscode: data.MailingPostCode,
        purchaser_mobilenum: data.MobileNumber,
        purchaserEmail: data.Email,
        purchaserReligion: data.Religion,
        purchaserDialectGrp: data.DialectGroup,
        purchaserMaritalStatus: data.MaritalStatus,
        purchaserMaritalStatusEN: data.MaritalStatus_EN,
        purchaserMaritalStatusZH: data.MaritalStatus_ZH,
        genderMale: data.Gender === "M" ? "checked" : "",
        genderFemale: data.Gender === "F" ? "checked" : "",

        // intendeduserFullname: payload.Data.iu[0]?.FullName ?? "",
        // intendeduserCNname: payload.Data.iu[0]?.ChineseName ?? "",
        // intendeduserNRIC: payload.Data.iu[0]?.IC ?? "",
        // intendeduserRelationship: payload.Data.iu[0]?.Relationship ?? "",
        // intendeduserGender: payload.Data.iu[0]?.Gender ?? "",

        // fspnName: payload.Data.details.ProductName,
        // fspCode: payload.Data.details.FSPCode,
        // fspPurchase: payload.Data.details?.UnitPrice ? `RM ${thousandSeperatorFormatter(payload.Data.details.UnitPrice)}` : "",
        // fspOthers: payload.Data.details?.MaintenanceFee ? `RM ${thousandSeperatorFormatter(payload.Data.details.MaintenanceFee)}` : "",
        // fspDiscount: "",
        totalPayment: data?.TotalPrice ? `RM ${thousandSeperatorFormatter(data.TotalPrice)}` : "",

        isFullPayment: data.IsFullPayment === 1 ? "checked" : "",
        isInstallment: data.IsFullPayment === 0 ? "checked" : "",
        months: "-",
        // cash: payload.Data.details.cash ? `RM ${thousandSeperatorFormatter(payload.Data.details.cash)}` : "",
        paymentbank: "",
        paymentOthers: "",
        paymenttotal: thousandSeperatorFormatter(data.paymenttotal),

        repName: "",
        repNRICNo: "",
        repContactNo: "",
        cert_collect_date: "",

        salespersonnel_name: data.Agent_Fullname,
        funeralDirector: "",
        salespersonnel_code: data.AgentID,
        salespersonnel_signature: "",

        officeUse_RandA: "",
        officeUse_CertNo: "",
        officeUse_UpdateBy: "",
        officeUse_Remarks: data.Remarks,

        unitID: data.UnitID,
        zone: data.ZoneX,
        row: data.RowX,
        hall: data.Hall,
        level: data.LevelX,
        plotSize: data.AreaSize,
        installment_period: data.InstallmentPeriod,
        isFullPayment: data.IsFullPayment === 1 ? "checked" : "",
        isInstallment: data.IsFullPayment  !== 1 ? "checked" : "",

        isAsNeed: data.NeedCondition === 'AN' ? '&#10004' : '',
        isAsNeedEN: data.NeedCondition_EN === 'As-Need' ? '&#10004' : '',
        isAsNeedZH: data.NeedCondition_ZH === '按需' ? '&#10004' : '',
        isPreNeed: data.NeedCondition === 'PN' ? '&#10004' : '',
        isPreNeedEN: data.NeedCondition_EN === 'Pre-Need' ? '&#10004' : '',
        isPreNeedZH: data.NeedCondition_ZH === '预需' ? '&#10004' : '',

        // hasUrn: payload.Data?.item.length > 0 ? 'text-decoration: line-through;': '',
        // hasNoUrn: payload.Data?.item.length === 0 ? 'text-decoration: line-through;' : '',
        // UrnCode: payload.Data?.item?.[0]?.ProductCode ?? "",
        // UrnQty: payload.Data?.item?.[0]?.Quantity ?? "",

        signature: payload.Data?.details.Signature
      };
      PDFGenerator(`/forms/PurchaseOrderForm.html`, POData, "legal");
    }

    getPurchaseOrdersError = (error) => {
      console.error("Error fetching addendum data:", error);
    if (error.response?.status === 401) {
      toast.error("Unauthorized access. Please login again.");
    } else {
      toast.error("Failed to fetch the addendum. Please try again.");
    }
  };

    getPurchaseInvoice = (salesID) => {
      Post(
        "/form/getPurchaseOrder",
        { salesID },
        this.handlePurchaseInvoiceSuccess,
        this.handlePurchaseInvoiceError,
        this.load
      );
    };
    
    handlePurchaseInvoiceSuccess = (payload) => {
      if (!payload?.Data?.details) {
        console.error("Missing details in API response:", payload);
        toast.error("Failed to generate the purchase invoice. Missing required details.");
        return;
      }
    
      const details = payload.Data.details;
      const items = payload.Data?.item ?? [];
      const iu = payload.Data?.iu ?? [];
    
      const invoiceData = {
        rbNO: details.SalesID,
        purchaseorder_date: moment(details.SalesDate).format("YYYY-MM-DD"),
    
        purchaserFullname_asIC: details.Fullname,
        purchaserIC_NO: details.IC,
        purchaserNationality: details.Country_Nationality,
        dateOfBirth: details.DOB,
        purchaserPermanentAddress: details.ResidentialAddress,
        purchaserPscode: details.ResidentialPostCode,
        purchaserCorrsAddress: details.MailingAddress,
        purchaserCorrPscode: details.MailingPostCode,
        purchaser_mobilenum: details.MobileNumber,
        purchaserEmail: details.Email,
        purchaserReligion: details.Religion,
        purchaserDialectGrp: details.DialectGroup,
        purchaserMaritalStatus: details.MaritalStatus,
        purchaserMaritalStatusEN: details.MaritalStatus_EN,
        purchaserMaritalStatusZH: details.MaritalStatus_ZH,
        genderMale: details.Gender === "M" ? "checked" : "",
        genderFemale: details.Gender === "F" ? "checked" : "",
        singleSize: details.UserCapacity === 1 ? "checked" : "",
        doubleSize: details.UserCapacity === 2 ? "checked" : "",
        otherSize: details.UserCapacity > 2 ? "checked" : "",
    
        intendeduserFullname: iu[0]?.FullName ?? "-",
        intendeduserCNname: iu[0]?.ChineseName ?? "-",
        intendeduserNRIC: iu[0]?.IC ?? "-",
        intendeduserRelationship: iu[0]?.Relationship ?? "-",
        intendeduserGender: iu[0]?.Gender ?? "-",
    
        intendeduserFullname2: iu[1]?.FullName ?? "",
        intendeduserCNname2: iu[1]?.ChineseName ?? "",
        intendeduserNRIC2: iu[1]?.IC ?? "",
        intendeduserRelationship2: iu[1]?.Relationship ?? "",
        intendeduserGender2: iu[1]?.Gender ?? "",
    
        plotZone: details.ZoneX,
        plotRow: details.RowX,
        plotLotno: details.UnitID,
        plotSize: details.AreaSize,
        plotPrice: thousandSeperatorFormatter(details.UnitPrice),
        plotMtn_Fclty: thousandSeperatorFormatter(details.MaintenanceFee),
        plotBckwall_walkwy: "",
        plotMonumentpckg: "",
        plotothers: "",
        plotTotal: thousandSeperatorFormatter(details.UnitPrice + details.MaintenanceFee),
    
        cash: "",
        paymentbank: "",
        paymentOthers: "",
        paymenttotal: thousandSeperatorFormatter(details.PaidAmount),
    
        repName: "",
        repIC: "",
        repContactNo: "",
        cert_collect_date: "",
    
        salespersonnel_name: details.Agent_Fullname,
        funeralDirector: "",
        salespersonnel_code: details.AgentID,
        salespersonnel_signature: "",
    
        officeUse_RandA: "",
        officeUse_CertNo: "",
        officeUse_UpdateBy: "",
        officeUse_Remarks: "",
    
        zone: details.ZoneX,
        hall: details.Hall,
        level: details.LevelX,
        installment_period: details.InstallmentPeriod,
        isFullPayment: details.IsFullPayment === 1 ? "checked" : "",
        isInstallment: details.IsFullPayment !== 1 ? "checked" : "",
    
        isAsNeed: details.NeedCondition === "AN" ? "&#10004;" : "",
        isAsNeedEN: details.NeedCondition_EN === "As-Need" ? "&#10004;" : "",
        isAsNeedZH: details.NeedCondition_ZH === "按需" ? "&#10004;" : "",
        isPreNeed: details.NeedCondition === "PN" ? "&#10004;" : "",
        isPreNeedEN: details.NeedCondition_EN === "Pre-Need" ? "&#10004;" : "",
        isPreNeedZH: details.NeedCondition_ZH === "预需" ? "&#10004;" : "",
    
        hasUrn: items.length > 0 ? "text-decoration: line-through;" : "",
        hasNoUrn: items.length === 0 ? "text-decoration: line-through;" : "",
        UrnCode: items[0]?.ProductCode ?? "",
        UrnQty: items[0]?.Quantity ?? "",
    
        signature: details.Signature,
      };
    
      // Generate the PDF
      PDFGenerator(`/forms/PurchaseInvoice.html`, invoiceData, "legal");
  
    };
    
    handlePurchaseInvoiceError = (error) => {
      console.error("Error fetching purchase invoice:", error);
      if (error.response?.status === 401) {
        toast.error("Unauthorized access. Please login again.");
      } else {
        toast.error("Failed to fetch the purchase invoice. Please try again.");
      }
    };

    getAddendum = (salesID) => {
      Post(
        "/sales/getOrderDetails",
        { salesID },
        this.handleAddendumSuccess,
        this.handleAddendumError,
        this.load
      );
    };
    
    handleAddendumSuccess = (payload) => {
      if (!payload?.Data?.MonumentPurchaseDetails?.[0] || !payload?.Data?.MonumentPurchaseInstallment?.[0]) {
        console.error("Missing required data in API response:", payload);
        toast.error("Failed to generate the addendum. Missing required details.");
        return;
      }
    
      const data = payload.Data.MonumentPurchaseDetails[0];
      const installment = payload.Data.MonumentPurchaseInstallment[0];
    
      const addendumData = {
        purchaseOrderDate: moment(data.SalesDate).format("DD/MM/YYYY"),
        name: data.PurchaserName,
        ICNo: data.IC,
        ProductName: data.ProductName,
        ProductCode: data.ProductCode,
        plotNo: data.UnitID,
        PONo: data.SalesId,
        months: installment.TotalInstallments,
        totalPurchasePrice: thousandSeperatorFormatter(data.TotalPrice),
        totaldownPayment: thousandSeperatorFormatter(data.Downpayment),
        totalInstallmentAmount: thousandSeperatorFormatter(installment.TotalAmount),
        totalMonths: installment.TotalInstallments,
        perMonthPayment: thousandSeperatorFormatter(installment.SumPerInstallment),
        installmentMonths: installment.TotalInstallments,
        firstPayment: thousandSeperatorFormatter(installment.FirstInstallment),
        fromDate: moment(installment.InstallmentDateRange?.split("~")[0]?.trim()).format("DD/MM/YYYY"),
        everyDateOfMonth: moment(installment.InstallmentDateRange?.split("~")[0]?.trim()).format("Do"),
        datePurchaser: moment(data.SalesDate).format("DD/MM/YYYY"),
        witName: "",
        witIC: "",
        witDate: "",
        recName: "",
        recDate: "",
        verName: "",
        verDate: "",
        appName: "",
        appDate: ""
      };
    
      // Generate the addendum PDF
      PDFGenerator(`/forms/MonumentAddendum.html`, addendumData, "legal");
    
      // Open the generated addendum (optional)

    };
    
    handleAddendumError = (error) => {
      console.error("Error fetching addendum data:", error);
      if (error.response?.status === 401) {
        toast.error("Unauthorized access. Please login again.");
      } else {
        toast.error("Failed to fetch the addendum. Please try again.");
      }
    };
    

    render = () => {
      return (
        <WrappedComponent
          {...this.props}
          {...this.state}
          onChangeHOC={this.onChangeHOC}
          getOrders={this.getOrders}
          getSelectedOrder={this.getSelectedOrder}
          addAuthorisedRep={this.addAuthorisedRep}
          getPurchaseOrders={this.getPurchaseOrders}
          getAddendum={this.getAddendum}
          getPurchaseInvoice={this.getPurchaseInvoice}
          addDigitalSignature={this.addDigitalSignature}
        />
      );
    };
  }

  const mapStateToProps = (state) => ({
    data: state,
    selectedLanguage: state.ProfileReducer.profile.preferred_language,
    profile: state.ProfileReducer.profile
  });
  return connect(mapStateToProps, { addLoadingContent, removeLoadingContent })(WithHOC);
};

export default HOC;
