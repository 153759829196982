import React, { useMemo } from 'react'
import { Input } from 'reactstrap'
import { DatePicker } from "antd"
import { getSelectedLanguageContent } from 'utils'
import _ from 'lodash'

const { RangePicker } = DatePicker;

export const Filter = ({ column }) => {
  return (
    <div style={{ marginTop: '5px' }}>
      { column.canFilter && column.render( 'Filter' ) }
    </div>
  )
}

export const DefaultColumnFilter = ({
  column: {
    filterValue,
    setFilter,
    id
  },
  onChangeKey,
  onChangeAction
}) => {
  return (
    <Input
      value={ filterValue || '' }
      onChange={ e => {
        setFilter( e.target.value || null )
        if (onChangeAction) {
          onChangeAction(onChangeKey, id, e.target.value);
        }
      }}
      placeholder={ `${ getSelectedLanguageContent("{{SEARCH}}") } ...` }
    />
  )
}


export const SelectColumnFilter = ({
  column: { filterValue, setFilter, preFilteredRows, id }
}) => {
  const options = useMemo( () => {
    const options = new Set()
    preFilteredRows.forEach( row => {
      options.add( row.values[ id ] )
    })
    return [ ...options.values() ]
  }, [ id, preFilteredRows ] )

  return (
    <Input
      id='custom-select'
      type='select'
      value={ filterValue }
      onChange={ e => {
        setFilter( e.target.value || undefined )
      }}
      className='form-control'
    >
      <option value=''>{ getSelectedLanguageContent("{{ALL}}") }</option>
      { 
        options.map( option => (
          <option key={ option } value={ option }>
            { option }
          </option>
        ))
      }
    </Input>
  )
}

export const EqualCurrentLanguageFilter = (rows, id, filterValues, selectedLanguage) => {
  return rows.filter(row => {
    const cellData = getSelectedLanguageContent(row.values[id], selectedLanguage)
    if (filterValues !== 'NONE') {
      return cellData == filterValues
    } 
    else {
      return cellData == ""
    }    
  })
}

export const ContainsCurrentLanguageFilter = (rows, id, filterValues, selectedLanguage) => {
  if (_.isEmpty(filterValues)) {
    return rows.filter(row => true);
  }
  else {
    const filterVal = filterValues.toLowerCase()
    return rows.filter(row => {
      const cellData = getSelectedLanguageContent(row.values[id], selectedLanguage)
      return cellData.toLowerCase().indexOf(filterVal) >= 0
    })
  }
}

export const SelectMultiLanguageColumnFilter = ({
  column: { filterValue, setFilter, preFilteredRows, id },
  selectedLanguage
}) => {
  const options = useMemo( () => {
    const options = new Set()
    preFilteredRows.forEach( row => {
      options.add( getSelectedLanguageContent(row.values[ id ], selectedLanguage) )
    })
    return [ ...options.values() ]
  }, [ id, preFilteredRows ] )

  return (
    <Input
      id='custom-select'
      type='select'
      value={ filterValue }
      onChange={ e => {
        setFilter( e.target.value || undefined )
      }}
      className='form-control'
    >
      <option value=''>{ getSelectedLanguageContent("{{ALL}}") }</option>
      { 
        options.map( option => (
          <option key={ option } value={ !_.isEmpty(option) ? option : 'NONE' }>
            { !_.isEmpty(option) ? option : getSelectedLanguageContent("{{NONE}}") }
          </option>
        ))
      }
    </Input>
  )
}

export const NumberRangeColumnFilter = ({
  column: { filterValue = [], preFilteredRows, setFilter, id }
}) => {
  return (
    <div className='d-flex'>
      <Input
        value={filterValue[0] || ""}
        type="number"
        onChange={e => {
          const val = e.target.value;
          setFilter((old = []) => [
            val ? parseInt(val, 10) : undefined,
            old[1]
          ]);
        }}
        placeholder={'Min'}
        style={{ width: "40%" }}
        className='mr-1'
      />
      -
      <Input
        value={filterValue[1] || ""}
        type="number"
        onChange={e => {
          const val = e.target.value;
          setFilter((old = []) => [
            old[0],
            val ? parseInt(val, 10) : undefined
          ]);
        }}
        placeholder={'Max'}
        style={{ width: "40%" }}
        className='ml-1'
      />
    </div>
  );
}

export const BetweenDatesFilter = (rows, id, filterValues) => {
  const sd = filterValues[0] ? new Date(filterValues[0]).setUTCHours(0,0,0,0) : undefined
  const ed = filterValues[1] ? new Date(filterValues[1]).setUTCHours(23,59,59,999) : undefined

  if (ed || sd) {
    return rows.filter(r => {
      const cellDate = new Date(r.values[id])
      if (ed && sd) {
        return cellDate >= sd && cellDate <= ed
      } else if (sd){
        return cellDate >= sd
      } else if (ed){
        return cellDate <= ed
      }
    })
  } else {
    return rows
  }
}

export const DateRangeColumnFilter = ({
  column: { setFilter }
}) => {
  return (
    <div>
      <RangePicker 
        allowEmpty={ [true, true] }
        onChange={(val) => {
          if (val == null) {
            setFilter(() => [undefined, undefined])
          } 
          else {
            setFilter(() => val)
          }          
        }}
      />
    </div>
  );
}

export const DateColumnFilter = ({
  column: { setFilter }
}) => {
  return (
    <div>
      <DatePicker 
        onChange={( date, dateString ) => {
          setFilter( dateString || null )
        }}
      />
    </div>
  )
}
