import React from 'react'
import _ from 'lodash'
import { Modal } from 'antd'
import { connect } from "react-redux"

import LoadingOverlay from 'components/Indicator/LoadingOverlay'
import CoreRenderFunction from 'DisplayCore'
import { getSelectedLanguageContent } from 'utils'

const TechBaseModal = props => {
  let {
    width,
    title,
    titleType,
    bodyStyle,
    children,
    openActionKey,
    closeActionKey,
    closable,
    className
  } = props.item

  if(openActionKey){ // this is here to ensure form in modal always call onInitial
    if(openActionKey.includes('||')){
      if(!openActionKey.split('||').some(key => props[key])){
        return <></>
      }
    } else if(!openActionKey.split('&&').every(key => props[key])){
      return <></>
    }
  }

  return (
    <Modal
      open
      className={ className }
      title={ titleType != 'custom' ? (
          getSelectedLanguageContent( title, props.selectedLanguage ) 
        ) : (
          <CoreRenderFunction { ...props } item={ title } /> 
        )
      }
      footer={ null }
      width={ width }
      bodyStyle={ bodyStyle }
      closable={ closable }
      onCancel={ props.onClickAction ? () => props.onClickAction( closeActionKey ) : () => {}}
    >
      {
        children?.map( child => <CoreRenderFunction { ...props } item={ child } /> )
      }
      {
        props.loadingContent.length > 0 && (
          <LoadingOverlay/>
        )
      }
    </Modal>
  )
}

const mapStateToProps = (state) => ({ 
  loadingContent: state.LoadingOverlayReducer.loadingContent
})

export default connect( mapStateToProps )(TechBaseModal)
