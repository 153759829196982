import React, { useEffect, useState } from "react";
import _ from "lodash";
import { compose } from "redux";
import { Dropdown, Button, Table, Card, InputNumber } from "antd";

import BookingForm from "containers/Dashboard/Mall/List/components/booking";
import PurchaseForm from "containers/Dashboard/Mall/List/components/purchase";
import WithOrderHOC from "containers/Dashboard/Mall/List/actions";
import WithServiceMallHOC from "./actions";
import TechBaseText from "DisplayCoreV2/Text";
import TechBaseButton from "DisplayCoreV2/Button";
import { ArrowLeftOutlined } from "@ant-design/icons";
import RegistrationCustomerV2 from "containers/RegistrationV2/Customer";
import ProductForm from "./productForm";

const MonumentMall = (props) => {
    const {
    // ServiceMallHOC
    query,
    createProduct,
    updateProduct,
    totalServices,
    servicePackageList,
    selectedPackage,
    showPurchaseForm,
    showBookingForm,
    showCreateModal,
    showUpdateModal,
    onChangeServiceHOC,
    getServicePackageList
  } = props;
  const { can_read, can_update, can_delete, can_create } = props.permission ?? {};

  const actions = [
    {
      key: "1",
      label: <TechBaseText content="{{UPDATE}}" />
    }
  ];

  const columns = [
    {
      title: <TechBaseText content="{{NUMBER}}" />,
      render: (text, record, index) =>
        (query.pageNumber - 1) * query.pageSize + index + 1,
    },
    {
      title: <TechBaseText content="{{PRODUCT_CODE}}" />,
      dataIndex: "ProductCode",
      key: "ProductCode",
    },
    {
      title: <TechBaseText content="{{PRODUCT_NAME}}" />,
      dataIndex: "ProductName",
      key: "ProductName",
    },
    {
      title: <TechBaseText content="{{CATEGORY}}" />,
      dataIndex: "ProductCategoryName",
      key: "ProductCategoryName",
    },
    {
      title: <TechBaseText content="{{PRICE}} (RM)" />,
      dataIndex: "Price",
      key: "Price",
    },
    {
      title: <TechBaseText content="{{ACTIONS}}" />,
      key: "action",
      render: (text, record) => (
        <Dropdown
          trigger="click"
          menu={{
            items: actions,
            onClick: (e) => {
              switch (e.key) {
                case "1":
                  onChangeServiceHOC("showUpdateModal", true);
                  onChangeServiceHOC("selectedPackage", record);
                  break;
                default:
              }
            },
          }}
        >
          <Button>
            <TechBaseText content="{{ACTIONS}}" />
          </Button>
        </Dropdown>
      ),
    },
  ];
  useEffect(() => {
    getServicePackageList(query);
  }, [query]);

  if (!can_read) {
    return <></>;
  }

  return (
    <> 
      <TechBaseText Tag="h3" content="{{MONUMENT_PACKAGE}}" />
      <div className="d-flex justify-content-end mb-2">
        <TechBaseButton size="large" color="primary" onClick={() => onChangeServiceHOC("showCreateModal", true)}>
          <TechBaseText content="{{CREATE_NEW}}" />
        </TechBaseButton>
      </div>
      <Table
        bordered
        columns={columns}
        className="techbase-table"
        dataSource={servicePackageList}
        pagination={{
          current: query.pageNumber,
          pageSize: query.pageSize,
          total: totalServices,
          showSizeChanger: true,
          onChange: (page, pageSize) =>
            onChangeServiceHOC("query", { ...query, pageNumber: page, pageSize: pageSize })
        }}
      />
      <ProductForm
        open={showCreateModal}
        title={<TechBaseText content="{{CREATE_NEW}}" />}
        onCancel={() => onChangeServiceHOC("showCreateModal", false)}
        onSubmit={(formData) => createProduct(formData)}
      />
      <ProductForm
        open={showUpdateModal && !_.isEmpty(selectedPackage)}
        selectedPackage={selectedPackage}
        title={<TechBaseText content="{{UPDATE}}" />}
        onCancel={() => {
          onChangeServiceHOC("showUpdateModal", false);
          onChangeServiceHOC("selectedPackage", {});
        }}
        onSubmit={(formData) => updateProduct(formData)}
      />
    </>
  );
};

export default compose(WithServiceMallHOC, WithOrderHOC)(MonumentMall);
