import _ from 'lodash'

export const ADD_LOADING_CONTENT = 'ADD_LOADING_CONTENT'
export const REMOVE_LOADING_CONTENT = 'REMOVE_LOADING_CONTENT'
export const CLEAR_LOADING_CONTENT = 'CLEAR_LOADING_CONTENT'

const initialState = {
  loadingContent: []
}

export const addLoadingContent = payload => ({
  type: ADD_LOADING_CONTENT,
  payload,
})

export const removeLoadingContent = payload => ({
  type: REMOVE_LOADING_CONTENT,
  payload,
})

export const clearLoadingContent = payload => ({
  type: CLEAR_LOADING_CONTENT,
  payload,
})

export default ( state = initialState, action ) => {
  switch( action.type ) {
    case ADD_LOADING_CONTENT:
      return {
        ...state,
        loadingContent: [ ...state.loadingContent, 1 ]
      }
    case REMOVE_LOADING_CONTENT:
      let tmp = _.cloneDeep( state.loadingContent )
      tmp.pop()
      return {
        ...state,
        loadingContent: tmp
      }
    case CLEAR_LOADING_CONTENT:
      return {
        ...state,
        loadingContent: []
      }
    default: return state
  }
}