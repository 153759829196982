import React from "react"
import { getSelectedLanguageContent } from 'utils'

const PageTitle = props => {

  let {
    heading,
    path
  } = props.item

  return (
    <div className="techbase-page-title d-flex flex-row justify-content-between">
      <div>
        <h2>{ getSelectedLanguageContent( heading, props.selectedLanguage ) }</h2>
      </div>
      <div>
        <nav aria-label="breadcrumb">
          <ol className="breadcrumb">
            {
              path?.map( section => {
                return (
                  <li class="breadcrumb-item">
                    <a href={ section.disabled ? 'javascript:void(0)': section.link } >
                      { getSelectedLanguageContent( section.text, props.selectedLanguage ) }
                    </a>
                  </li>
                )
              })
            }
          </ol>
        </nav>
      </div>
    </div>
  )
}

export default PageTitle