import React from "react"

import { handleNestedObjectKey } from "utils"

const TechBaseImage = (props) => {
  let { altText, src, dataKey, dataType, style={}, className="" } = props.item
  const source = src
    ? src
    : handleNestedObjectKey(props, dataKey) || '/logo.png'
  const sourceType = (handleNestedObjectKey(props, dataType) || source.includes('.mp4') || source.includes('.mpeg')) ? "video" : "image"
  if (sourceType === "video") {
    return (
      <video className={className} style={style} width="100%" height="100%" autoPlay loop muted>
        <source src={source} />
        Your browser does not support the video tag.
      </video>
    )
  } else {
    return (
      <img
        className={className}
        style={style}
        alt={altText}
        src={source}
      />
    )
  }
}

export default TechBaseImage
