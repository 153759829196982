import React from "react"
import _ from "lodash"
import { handleNestedObjectKey } from 'utils'

import CoreRenderFunction from "DisplayCore"

const DefaultComponent = (props) => {
  let CustomTag = props.item.type
  let tmpItem = _.cloneDeep(props.item)
  _.map( tmpItem, (value, key) => {
    if( typeof value ==='string' && value[0] === '$' ){
      tmpItem[key] = handleNestedObjectKey(props, value.slice(1))
    }
  })

  if( _.isEmpty(CustomTag) ){
    return (
      <>
        {
          tmpItem.children?.map((child) => {
            return <CoreRenderFunction {...props} item={child} />
          })
        }
      </>
    )
  } else {
    return (
      <CustomTag {...tmpItem}>
        {
          tmpItem.children?.map((child) => {
            return <CoreRenderFunction {...props} item={child} />
          })
        }
      </CustomTag>
    )
  }
}

export default DefaultComponent