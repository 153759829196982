import React, { useState, useEffect } from "react";
import _ from "lodash";
import { Card, Form, Space, Row, Col, Input, Divider, Select } from "antd";
import TechBaseButton from "DisplayCoreV2/Button";
import TechBaseFilePond from "DisplayCoreV2/FilePond";
import TechBaseText from "DisplayCoreV2/Text";
import { requireRule } from "utils/rules";

const PaymentFields = [
  // { title: "Security Password", name: "security_password" },
  { title: <TechBaseText content="{{PO_REF_NO}}" />, name: "salesID" },
  { title: <TechBaseText content="{{INTRODUCER_ID}}" />, name: "introducerDetail" },
  { title: <TechBaseText content="{{REMARKS}}" />, name: "remarks" }
];

const Step3 = (props) => {
  const { selectedSlot, selectedItem, onNext, onBack, formData, paymentOptions, salesType } = props;
  const [form] = Form.useForm();
  const [summaryValue, setSummaryValue] = useState({});

  useEffect(() => {
    let tmp = {};
    tmp.lotPayment = formData.step2.lot.downpayment - (formData.step2.booking_fee_received || 0);
    tmp.urnPayment = _.sumBy(selectedItem, (val) => val.quantity * val.Price);
    tmp.final = tmp.lotPayment + tmp.urnPayment;
    setSummaryValue(tmp);
  }, [formData, selectedItem]);

  useEffect(() => {
    if (!_.isEmpty(props.bookingOrder)) {
      form.setFieldValue('salesID', props.bookingOrder.SalesId)
    }
  }, [props.bookingOrder])

  useEffect(() => {
    _.map(PaymentFields, (field) => {
      if (props.disabledPORefNo && field.name === 'salesID') {
        field.disabled = true
      } else {
        delete field.disabled
      }
    })
  }, [props.disabledPORefNo])

  useEffect(() => {
    if (paymentOptions && paymentOptions.length > 0) {
      const filteredOptions = _.filter(
        paymentOptions,
        (opt) => opt.Sort === 0 && !opt.ParameterName.toLowerCase().includes("wallet")
      );
      if (filteredOptions.length) {
        form.setFieldValue("paymentType", filteredOptions[0].ParameterValue);
      }
    }
  }, [paymentOptions]);

  const Summary = [
    ...(salesType === "FSP"
      ? [{ title: <TechBaseText content="{{PRODUCT_NAME}}" />, value: selectedSlot.ProductName }]
      : [
        { title: <TechBaseText content="{{LOT_NO}}" />, value: selectedSlot.UnitID },
        {
          title: <TechBaseText content="{{LOT_PAYMENT}} (RM)" />,
          value: summaryValue.lotPayment?.toFixed(2)
        },
        { title: <TechBaseText content="{{URN_PAYMENT}}" />, value: summaryValue.urnPayment?.toFixed(2) }
      ]),
    {
      title: <TechBaseText content="{{FINAL_PAYMENT}} (RM)" />,
      value: summaryValue.final?.toFixed(2),
      bold: true
    }
  ];

  const onFinish = (value) => {
    const filteredValue = _.omitBy(value, _.isNil);
    onNext(filteredValue);
  };

  return (
    <Form
      onFinish={onFinish}
      layout="vertical"
      form={form}
      initialValues={{
        purchaser_type: "existing",
        nationality: 127,
        mobile_prefix: "60",
        mobile_prefix_2: "60",
        identity_type: "ic",
        gender: "male",
        country: 127,
        state: 9,
        mailing_country: 127,
        mailing_state: 9,
        paymentType: paymentOptions[0]?.ParameterValue || "",
        salesID: props.bookingOrder?.SalesId || "",
        introducerDetail: "",
        remarks: "",
        receipt: "",
        ...formData.step3,

      }}
    >
      <Row className="w-100 d-block ">
        <Card className="mb-3">

          {salesType === "FSP" ? (
            <div className="lead">
              <TechBaseText content="{{SELECTED_FSP}}" />: {selectedSlot.ProductName}
            </div>
          ) : (
            <div className="lead">
              <TechBaseText content="{{LOT_NO}}." />: {selectedSlot.UnitID}
            </div>
          )}
        </Card>
        <Card className="mb-3">
          <div className="lead">
            <TechBaseText content="{{PURCHASE_SUMMARY}}" />
          </div>
          {Summary.map((field) => (
            <>
              <Divider className="my-3" />
              <Row gutter={32}>
                <Col xs={24} md={12}>
                  {field.bold ? <strong>{field.title}</strong> : field.title}
                </Col>
                <Col xs={24} md={12}>
                  {field.value}
                </Col>
              </Row>
            </>
          ))}
        </Card>
        <Card className="mb-3">
          <div className="lead">
            <TechBaseText content="{{PAYMENT}}" />
          </div>
          <Divider className="my-3" />
          <Row gutter={32}>
            <Col xs={24} md={12}>
              <Form.Item className="m-0" name="paymentType" label="Payment Type"
                rules={[requireRule]}
              >
                <Select
                  size="large"
                  options={_.filter(paymentOptions, opt => opt.Sort === 0 && !opt.ParameterName.toLowerCase().includes('wallet'))}
                  fieldNames={{ label: "ParameterName", value: "ParameterValue" }}
                />
              </Form.Item>
            </Col>
            {PaymentFields.map((field) => (
              <>
                <Col xs={24} md={12}>
                  <Form.Item className="m-0" name={field.name} label={field.title}>
                    <Input size="large" disabled={field.disabled} />
                  </Form.Item>
                </Col>
              </>
            ))}
            <Divider className="my-3" />
            <Col xs={24} md={12}>
              <Form.Item name="receipt" label={<TechBaseText content="{{PROOF_OF_PAYMENT}}" />}
              // rules={[requireRule]}
              >
                <TechBaseFilePond acceptedFileTypes={["image/*"]} />
              </Form.Item>
            </Col>
            {/* <Col xs={24}>
              <Form.Item name="sales_date" label="Sales Date" rules={[requireRule]}>
                <DatePicker
                  size="large"
                  style={{
                    width: 300,
                  }}
                />
              </Form.Item>
            </Col> */}
          </Row>
        </Card>
        <Row className="w-100 d-flex footer-btn">
          <Col>
            <TechBaseButton onClick={() => onBack(form.getFieldsValue())}>
              <TechBaseText content="{{BACK}}" />
            </TechBaseButton>
          </Col>
          <Col>
            <TechBaseButton htmlType="submit" color="primary">
              <TechBaseText content="{{SUBMIT}}" />
            </TechBaseButton>
          </Col>


        </Row>
      </Row>
    </Form>
  );
};

export default Step3;
