import React from 'react'

import CoreRenderFunction from "DisplayCore"

const Announcement = props => {
  let {
    empty_text,
    template
  } = props.item
  
  if( props.announcements ){
    return  (
      <div style={{ height: '100%' }}>
        {
          props.announcements.map(announcement => {
            return <CoreRenderFunction 
              {...props } 
              item={ template } 
              announcement={ announcement }
              onClickAction={ (actionKey) => {
                props.onClickAction( actionKey, announcement.id)
              }}
            />
          })
        }
      </div>
    )
  } else {
    return(
      <div className="d-flex justify-content-center align-items-center" style={{ height: '80%' }}>
        { empty_text }
      </div>
    )
  }
}

export default Announcement