import React, { Component } from "react";
import { connect } from "react-redux";
import MetisMenu from "react-metismenu";
import _ from "lodash";
import i18next from "i18next";

import { setEnableMobileMenu } from "reducers/ThemeOptions";
import { applyColor } from "utils/apply-color";
import { MainNav } from "./dataMainNav";

class VerticalNavWrapper extends Component {
  state = {
    readMenu: []
  };

  componentDidMount = () => {
    if (!_.isEmpty(this.props.profile)) {
      this.setState({
        readMenu: this.readPermissionHandler(MainNav, this.props.permissions)
      });
    } else {
      this.setState({
        readMenu: []
      });
    }
  };

  componentDidUpdate = (pp) => {
    if (!_.isEqual(pp.profile, this.props.profile) || !_.isEqual(pp.permissions, this.props.permissions)) {
      if (!_.isEmpty(this.props.profile)) {
        this.setState({
          readMenu: this.readPermissionHandler(MainNav, this.props.permissions)
        });
      } else {
        this.setState({
          readMenu: []
        });
      }
    }
  };

  readPermissionHandler = (navItems, permissions=[]) => {
    let tmpMenu = [];
    let tmpActiveMenu = _.filter(
      navItems,
      (navItem) =>
        !navItem.disabled &&
        !navItem.routeOnly &&
        _.find(permissions, (permission) => permission.KeyName === navItem.module)
    );
    tmpActiveMenu.map((item) => {
      if (_.isUndefined(item.content)) {
        tmpMenu.push({
          ...item,
          label: i18next.t(item.label)
        });
      } else {
        let tmpContent = this.readPermissionHandler(item.content, _.find(permissions, (permission) => permission.KeyName === item.module)?.ChildItems);
        if (tmpContent.length > 0) {
          tmpMenu.push({
            ...item,
            label: i18next.t(item.label),
            content: tmpContent
          });
        }
      }
    });
    return tmpMenu;
  };

  render = () => {
    return (
      <>
        <style>
          {`
            .vertical-nav-menu .metismenu-container .metismenu-link.active{ background: ${applyColor(
              "$menuActive",
              this.props.colors
            )} }
            .vertical-nav-menu .metismenu-container .metismenu-link:hover{ background: ${applyColor(
              "$menuActive",
              this.props.colors
            )} }
          `}
        </style>
        <br />
        <MetisMenu
          content={this.state.readMenu}
          onSelected={() => this.props.setEnableMobileMenu(!this.props.enableMobileMenu)}
          activeLinkFromLocation
          className="vertical-nav-menu"
          iconNamePrefix=""
          classNameStateIcon="pe-7s-angle-down"
        />
      </>
    );
  };
}

const mapStateToProps = (state) => ({
  data: state,
  enableMobileMenu: state.ThemeOptions.enableMobileMenu,
  colors: state.ClientReducer.clientInfo.styles?.colors,
  siteModules: state.ClientReducer.siteModules,
  clientInfo: state.ClientReducer.clientInfo,
  profile: state.ProfileReducer.profile,
  permissions: state.ProfileReducer.permissions
});

export default connect(mapStateToProps, {
  setEnableMobileMenu
})(VerticalNavWrapper);
