import React, { Component } from "react";
import { connect } from "react-redux";
import {
  DropdownToggle,
  DropdownMenu,
  Button,
  UncontrolledButtonDropdown,
  Tooltip,
} from "reactstrap";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faAngleDown } from "@fortawesome/free-solid-svg-icons";
import Hamburger from "react-hamburgers";
import Userbox from "../../Header/Userbox";
import LanguageSwitcher from "../../LanguageSwitcher";
import {
  setEnableMobileMenu,
  setEnableMobileMenuSmall,
  clearCart,
  clearUpgradeCart,
  clearRedemptionCart
} from "reducers/ThemeOptions";

class MobileMenu extends Component {
  state = {
    showUserBox: false,
  };

  toggleUserBox = () => {
    this.setState((prevState) => ({ showUserBox: !prevState.showUserBox }));
  };

  handleUpdateProfile = () => {
    const { setEnableMobileMenuSmall, navigate } = this.props;
    this.setState({ showUserBox: false });
    setEnableMobileMenuSmall(false);
    navigate("/dashboard/profile");
  };

  handleLogout = () => {
    const { clearCart, clearUpgradeCart, clearRedemptionCart, navigate } = this.props;

    clearCart();
    clearUpgradeCart();
    clearRedemptionCart();

    if (localStorage.getItem("IMPERSONATE_TOKEN")) {
      localStorage.removeItem("IMPERSONATE_TOKEN");
      navigate("/dashboard/member-list");
    } else {
      localStorage.removeItem("TECHBASE_TOKEN");
      navigate("/login");
    }
  };

  render() {
    const { showUserBox } = this.state;
    const { profile, enableMobileMenu } = this.props;
    const { Email = "", FirstName = "", LastName = "", profile_photo_url = "" } = profile || {};

    return (
      <>
        <div className="app-header__mobile-menu">
          <Hamburger
            active={enableMobileMenu}
            type="elastic"
            onClick={() => this.props.setEnableMobileMenu(!enableMobileMenu)}
          />
        </div>
        <div className="app-header__menu">
          <div className="mobile-header-content__right">
            <LanguageSwitcher buttonClass={"btn-switch-lang btn-primary"} from={"header"} />
            {/* Add ShoppingCart component back if required */}
            <Userbox />
          </div>
        </div>
      </>
    );
  }
}

const mapStateToProps = (state) => ({
  profile: state.ProfileReducer.profile,
  enableMobileMenu: state.ThemeOptions.enableMobileMenu,
  enableMobileMenuSmall: state.ThemeOptions.enableMobileMenuSmall,
});

export default connect(mapStateToProps, {
  setEnableMobileMenu,
  setEnableMobileMenuSmall,
  // clearCart,
  // clearUpgradeCart,
  // clearRedemptionCart,
})(MobileMenu);
