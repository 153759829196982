import React, { useEffect } from 'react'
import { Form } from 'antd'
import _ from 'lodash'

import ClientUsersHOC from './actions'

import CoreRenderFunction from 'DisplayCore'
import { KYC } from 'PageLayout/KYC'

const KYCSubmission = props => {
  const {
    profile,
    siteInfo,
    clientInfo,
    onChangeClientUsersHOC,
    getBanks,
    updateClientUser,
    showKYC,
    updateShowKYC,
    reuploadIc,
    uploadedICFront,
    uploadedICBack,
    ICFrontImageInfo,
    ICBackImageInfo,
    uploadedBankStatement,
    BankStatementImageInfo,
    selectedDocumentType,
    uploadedSSMCert,
    SSMCertImageInfo,
    SSMWithICImageInfo,
    showFormFeedbackICFront,
    showFormFeedbackICBack,
    showFormFeedbackBankStatement,
    showFormFeedbackSSMCert
  } = props

  const [ kycForm ] = Form.useForm()

  useEffect( () => {
    // getBanks()
  }, [])

  const onClickAction = ( actionKey, value ) => {
    switch( actionKey ) {
      case 'CLOSE_KYC':
        updateShowKYC( false )
        break
      case 'UPLOAD_IC_FRONT':
        if( value && value.length > 0 ) {
          onChangeClientUsersHOC( 'uploadedICFront', value )
          const reader = new FileReader()
          reader.onload = () => {
            onChangeClientUsersHOC( 'ICFrontImageInfo', {
                base64: reader.result,
                file_name: value[ 0 ].filename
              }
            )
          }
          reader.readAsDataURL( value[ 0 ].file )
        }
        onChangeClientUsersHOC( 'showFormFeedbackICFront', false )
        break
      case 'UPLOAD_IC_BACK':
        if( value && value.length > 0 ) {
          onChangeClientUsersHOC( 'uploadedICBack', value )
          const reader = new FileReader()
          reader.onload = () => {
            onChangeClientUsersHOC( 'ICBackImageInfo', {
                base64: reader.result,
                file_name: value[ 0 ].filename
              }
            )
          }
          reader.readAsDataURL( value[ 0 ].file )
        }
        onChangeClientUsersHOC( 'showFormFeedbackICBack', false )
        break
      case 'UPLOAD_SSM_CERT':
        if( value && value.length > 0 ) {
          onChangeClientUsersHOC( 'uploadedSSMCert', value )
          const reader = new FileReader()
          reader.onload = () => {
            onChangeClientUsersHOC( 'SSMCertImageInfo', {
                base64: reader.result,
                file_name: value[ 0 ].filename
              }
            )
          }
          reader.readAsDataURL( value[ 0 ].file )
        }
        onChangeClientUsersHOC( 'showFormFeedbackSSMCert', false )
        break
      case 'UPLOAD_SSM_CERT_WITH_IC':
        if( value && value.length > 0 ) {
          onChangeClientUsersHOC( 'uploadedSSMCertWithIC', value )
          const reader = new FileReader()
          reader.onload = () => {
            onChangeClientUsersHOC( 'SSMWithICImageInfo', {
                base64: reader.result,
                file_name: value[ 0 ].filename
              }
            )
          }
          reader.readAsDataURL( value[ 0 ].file )
        }
        break
      case 'ON_CHANGE_KYC_FORM':
        if( value.hasOwnProperty( 'document_type' )){
          onChangeClientUsersHOC( 'selectedDocumentType', value.document_type )
        }
        break
      case 'UPLOAD_BANK_STATEMENT':
        if( value && value.length > 0 ) {
          onChangeClientUsersHOC( 'uploadedBankStatement', value )
          const reader = new FileReader()
          reader.onload = () => {
            onChangeClientUsersHOC( 'BankStatementImageInfo', {
                base64: reader.result,
                file_name: value[ 0 ].filename
              }
            )
          }
          reader.readAsDataURL( value[ 0 ].file )
        }
        onChangeClientUsersHOC( 'showFormFeedbackBankStatement', false )
        break
      default: break
    }
  }

  const hideComponent = actionKey => {
    switch( actionKey ){
      case 'hideIdentityNumber':
        return true;
      case 'hideBankDetail':
      case 'hideSecurityPin':
        return false;
      case 'hideFormFeedbackICFront':
        return !showFormFeedbackICFront
      case 'hideFormFeedbackICBack':
        return !showFormFeedbackICBack
      case 'hideFormFeedbackBankStatement':
        return !showFormFeedbackBankStatement
      case 'hideSSM':
        return selectedDocumentType !== 'ssm'
      case 'hideBackIC':
        return selectedDocumentType === 'passport'
      case 'hideFormFeedbackSSMCert':
        return !showFormFeedbackSSMCert
      default: return false
    }
  }

  const onInitialtKYCForm = form => {
    form.setFieldsValue({
      holder_name: profile.full_name,
      document_type: 'ic'
    })
  }

  const onSubmitKYCForm = formData => {
    if( _.isEmpty( uploadedICFront ) ) { 
      onChangeClientUsersHOC( 'showFormFeedbackICFront', true ) 
    }
    if( _.isEmpty( uploadedICBack ) ) { 
      onChangeClientUsersHOC( 'showFormFeedbackICBack', true ) 
    }
    if( _.isEmpty( uploadedBankStatement ) ) { 
      onChangeClientUsersHOC( 'showFormFeedbackBankStatement', true ) 
    }
    // if( formData.document_type === 'ssm' && _.isEmpty( SSMCertImageInfo )) { onChangeClientUsersHOC( 'showFormFeedbackSSMCert', true ) }
    if( _.isEmpty( uploadedICFront ) 
    || _.isEmpty( uploadedICBack ) 
    || _.isEmpty( uploadedBankStatement ) ) { 
      return 
    }
    
    let data = {}
     {
      data = {
        bank_id: formData.bank_id || null,
        account_number: formData.account_number || null,
        holder_name: formData.holder_name || 'N/A',
        is_default: true,
        income_tax_number: formData.income_tax_number || '',
        security_pin: formData.security_pin ||'999999',
        security_pin_confirmation: formData.security_pin_confirmation || '999999',
        // document_type: formData.document_type,
        // ic_number: formData.ic_number,
        ic_front: ICFrontImageInfo
      }
      if( !_.isEmpty( ICBackImageInfo )) {
        data.ic_back = ICBackImageInfo
      }
      if( !_.isEmpty( BankStatementImageInfo)){
        data.bank_statement = BankStatementImageInfo
      }
      if( formData.document_type === 'ssm' ){
        data.ssm_cert = SSMCertImageInfo
        if( !_.isEmpty( SSMWithICImageInfo )) {
          data.ssm_cert_with_ic = SSMWithICImageInfo
        }
      }
      updateClientUser( profile.id, data )
    } 
  }

  return (
    <CoreRenderFunction
      { ...props }
      item={ KYC.content }
      onClickAction={ onClickAction }
      hideComponent={ hideComponent }
      onInitialtKYCForm={ onInitialtKYCForm }
      onSubmitKYCForm={ onSubmitKYCForm }
      kycForm={ kycForm }
    />
  )
}

export default ClientUsersHOC( KYCSubmission )
