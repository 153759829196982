import React, { useEffect, useState } from "react";
import _ from "lodash";
import { compose } from "redux";
import { Dropdown, Button, Table, Card, InputNumber } from "antd";

import BookingForm from "containers/Dashboard/Mall/List/components/booking";
import PurchaseForm from "containers/Dashboard/Mall/List/components/purchase";
import WithOrderHOC from "containers/Dashboard/Mall/List/actions";
import WithServiceMallHOC from "./actions";
import TechBaseText from "DisplayCoreV2/Text";
import TechBaseButton from "DisplayCoreV2/Button";
import { ArrowLeftOutlined } from "@ant-design/icons";
import RegistrationCustomerV2 from "containers/RegistrationV2/Customer";
import ProductForm from "./productForm";
import PurchaseMonumentForm from "../List/components/purchaseMonument";
import MonumentBookingForm from "../List/components/monumentbooking";

const MonumentMall = (props) => {
  const [showCreateCustomer, setShowCreateCustomer] = useState(false);
  const [newCustomer, setNewCustomer] = useState("");
  const [selectedItems, setSelectedItems] = useState([]);

  const handleItemSelection = (record) => {
    setSelectedItems((prev) => {
      // Check if the item is already selected
      const existing = prev.find((item) => item.ID === record.ID);
      if (existing) {
        // If already selected, remove it (toggle behavior)
        return prev.filter((item) => item.ID !== record.ID);
      }
      // Otherwise, add it with quantity 1
      return [...prev, { ...record, quantity: 1 }];
    });
  };

  const {
    // ServiceMallHOC
    query,
    totalServices,
    servicePackageList,
    selectedPackage,
    showPurchaseForm,
    showBookingForm,
    onChangeServiceHOC,
    getServicePackageList
  } = props;
  const { can_read, can_update, can_delete, can_create } = props.permission ?? {};

  const actions = [
    {
      key: "1",
      label: <TechBaseText content="{{BOOKING}}" />
    },
    {
      key: "2",
      label: <TechBaseText content="{{PLACE_ORDER}}" />
    },
  ];

  const columns = [
    {
      title: <TechBaseText content="{{NUMBER}}" />,
      render: (text, record, index) =>
        (query.pageNumber - 1) * query.pageSize + index + 1,
    },
    {
      title: <TechBaseText content="{{PRODUCT_CODE}}" />,
      dataIndex: "ProductCode",
      key: "ProductCode",
    },
    {
      title: <TechBaseText content="{{PRODUCT_NAME}}" />,
      dataIndex: "ProductName",
      key: "ProductName",
    },
    {
      title: <TechBaseText content="{{CATEGORY}}" />,
      dataIndex: "ProductCategoryName",
      key: "ProductCategoryName",
    },
    {
      title: <TechBaseText content="{{PRICE}}" />,
      dataIndex: "Price",
      key: "Price",
    },
    {
      title: <TechBaseText content="{{SELECT}}" />,
      key: "select",
      render: (text, record) => (
        <Button
          type={selectedItems.some((item) => item.ID === record.ID) ? "primary" : "default"}
          onClick={() => handleItemSelection(record)}
          disabled={selectedItems.length > 0 && !selectedItems.some((item) => item.ID === record.ID)}
        >
          {selectedItems.some((item) => item.ID === record.ID)
            ? <TechBaseText content="{{DESELECT}}" />
            : <TechBaseText content="{{SELECT}}" />}
        </Button>
      ),
    },
    {
      title: <TechBaseText content="{{ACTIONS}}" />,
      key: "action",
      render: (text, record) => (
        <Dropdown
          trigger="click"
          menu={{
            items: actions,
            onClick: (e) => {
              switch (e.key) {
                case "1": // Booking action
                  onChangeServiceHOC("showBookingForm", true);
                  onChangeServiceHOC("selectedPackage", record);
                  break;
                case "2": // Purchase action
                  if (!selectedItems.length) {
                    console.error("No items selected for purchase.");
                    alert("Please select one item to purchase.");
                    return;
                  }

                  onChangeServiceHOC("showPurchaseForm", true);
                  onChangeServiceHOC("selectedPackage", { ...record, selectedItems });
                  break;
                default:
              }
            },
          }}
        >
          <Button>
            <TechBaseText content="{{ACTIONS}}" />
          </Button>
        </Dropdown>
      ),
    },
  ];
  useEffect(() => {
    getServicePackageList(query);
  }, [query]);

  if (!can_read) {
    return <></>;
  }

  return (
    <>
      <div className="d-flex">
        {(showPurchaseForm || showBookingForm) && (
          <TechBaseButton
            type="text"
            onClick={() => {
              if (showCreateCustomer) {
                setShowCreateCustomer(false);
              } else {
                onChangeServiceHOC("showPurchaseForm", false);
                onChangeServiceHOC("showBookingForm", false);
              }
            }}
          >
            <ArrowLeftOutlined />
          </TechBaseButton>
        )}
        <TechBaseText Tag="h3" content="{{MONUMENT_PACKAGE}}" />
      </div>
      {showPurchaseForm && (
        <div className={showCreateCustomer ? "d-none" : ""}>
          <PurchaseMonumentForm
            {...props}
            salesType="9"
            selectedSlot={selectedPackage} // This should contain the row and selectedItems data
            selectedItems={selectedItems} // This can also be passed directly if needed
            newCustomer={newCustomer}
            setNewCustomer={setNewCustomer}
            toggleCreateCustomer={() => setShowCreateCustomer(!showCreateCustomer)}
          />
        </div>
      )}
      {showBookingForm && (
        <div className={showCreateCustomer ? "d-none" : ""}>
          <MonumentBookingForm
            {...props}
            salesType="8"

            selectedSlot={selectedPackage}
            newCustomer={newCustomer}
            setNewCustomer={setNewCustomer}
            toggleCreateCustomer={() => setShowCreateCustomer(!showCreateCustomer)}
          />
        </div>
      )}
      {!showBookingForm && !showPurchaseForm && (
        <Table
          bordered
          columns={columns}
          className="techbase-table"
          dataSource={servicePackageList}
          pagination={{
            current: query.pageNumber,
            pageSize: query.pageSize,
            total: totalServices,
            showSizeChanger: true,
            onChange: (page, pageSize) =>
              onChangeServiceHOC("query", { ...query, pageNumber: page, pageSize: pageSize })
          }}
        />
      )}
      {showCreateCustomer && (
        <Card>
          <RegistrationCustomerV2
            {...props}
            onSuccessCreate={(memberId) => {
              setShowCreateCustomer(false);
              setNewCustomer(memberId);
            }}
          />
        </Card>
      )}
    </>
  );
};

export default compose(WithServiceMallHOC, WithOrderHOC)(MonumentMall);
