import React, { Suspense, useState, useEffect } from "react";
import { compose } from "redux";
import { connect } from "react-redux";
import { Outlet } from "react-router-dom";
import { getItem } from "utils/tokenStore";
import _ from "lodash";

import { applyColor } from "utils/apply-color";
import TemplateContainerMain from "components/Template";
import TemplateHeader from "components/Header";
import TemplateSidemenu from "components/Menu/Sidemenu";
import LoadingOverlay from "components/Indicator/LoadingOverlay";
import TermsAndConditions from "components/TermsAndConditions";
import TechBaseFooter from "DisplayCore/Footer";
import KYCSubmission from "../KYCSubmission";
import KYCStatusModal from "../KYCSubmission/statusModal";

const SuspenseWrapper = ({ children }) => <Suspense fallback={<LoadingOverlay />}>{children}</Suspense>;

const Dashboard = (props) => {
  const [showKYC, updateShowKYC] = useState(false);
  const [showStatusModal, updateShowStatusModal] = useState(false);

  useEffect(() => {
    if(!getItem("TECHBASE_TOKEN")){
      props.router.navigate('/login');
    } else if (props.KYCRoleConfig?.includes(props.profile?.role_name)) {
      if (props.profile.status === "pending") {
        updateShowKYC(true);
      } else if (props.profile.status === "verification-failed") {
        updateShowStatusModal(true);
      } else {
        updateShowKYC(false);
        updateShowStatusModal(false);
      }
    } else {
      updateShowKYC(false);
      updateShowStatusModal(false);
    }
  }, [props.profile.status, props.KYCRoleConfig, props.profile.role_name, getItem("TECHBASE_TOKEN")]);

  return (
    <TemplateContainerMain>
      <TemplateHeader history={props.history} windowHash={window.location.hash} />
      <div className={`app-main ${props.clientInfo.name}`}>
        <TemplateSidemenu userPermissions={props.userPermissions} user={props.user} userRole={props.userRole} />
        <div className="app-main__outer" style={{ backgroundColor: applyColor("$background") }}>
          <div className="app-main__inner">
            <KYCSubmission
              showKYC={showKYC}
              updateShowKYC={updateShowKYC}
              status={props.profile.status}
              updateShowStatusModal={updateShowStatusModal}
            />
            <KYCStatusModal
              data={props.data}
              colors={props.colors}
              updateShowKYC={updateShowKYC}
              showStatusModal={showStatusModal}
              updateShowStatusModal={updateShowStatusModal}
            />
            <SuspenseWrapper>
              <Outlet/>
            </SuspenseWrapper>
          </div>
          <TechBaseFooter data={props.data} />
        </div>
      </div>
      {props.loadingContent.length === 0 && props.siteInfo.terms_and_conditions_content && (
        <TermsAndConditions isOpen={!_.isEmpty(props.profile) && _.isNull(props.profile.date_accepted_tnc)} />
      )}
    </TemplateContainerMain>
  );
};

const mapStateToProps = (state) => ({
  data: state,
  profile: state.ProfileReducer.profile,
  siteInfo: state.ClientReducer.siteInfo,
  clientInfo: state.ClientReducer.clientInfo,
  colors: state.ClientReducer.clientInfo.styles?.colors,
  KYCRoleConfig: state.ClientReducer.clientInfo.client_overall_config?.showKYC.roles,
  siteModules: state.ClientReducer.siteModules,
  permissions: state.ProfileReducer.permissions,
  loadingContent: state.LoadingOverlayReducer.loadingContent
});

export default compose(connect(mapStateToProps))(Dashboard);
