import React, { useEffect, useState } from "react";
import { Card, Form, Space, Row, Col, InputNumber, Divider, Input, Select } from "antd";
import _ from "lodash";
import { Post } from "utils/axios";
import TechBaseText from "DisplayCoreV2/Text";
import TechBaseButton from "DisplayCoreV2/Button";
import { requireRule } from "utils/rules";

const InstallmentFields = [
  { title: <TechBaseText content="{{NO_OF_INSTALLMENT}}" />, name: ["lot", "loanDetails", "installmentMonth"], min: 2 },
  { title: <TechBaseText content="{{SUM_PER_INSTALLMENT}}" />, name: ["lot", "loanDetails", "sumPerInstallment"] },
  {
    title: <TechBaseText content="{{FIRST_INSTALLMENT}}" />,
    name: ["lot", "loanDetails", "firstInstallment"],
    disabled: true
  }
];

const Step2 = (props) => {
  const { selectedSlot, bookingOrder, onNext, onBack, formData, salesType } = props;
  const [form] = Form.useForm();
  const unitPrice = Form.useWatch(["lot", "unitPrice"], form);
  const maintenanceFee = Form.useWatch(["lot", "maintenanceFee"], form);
  const unitDiscount = Form.useWatch(["lot", "unitDiscount"], form);
  const balanceAmount = Form.useWatch("balance_amount", form);
  const downpayment = Form.useWatch(["lot", "downpayment"], form);
  const installmentMonth = Form.useWatch(["lot", "loanDetails", "installmentMonth"], form);
  const sumPerInstallment = Form.useWatch(["lot", "loanDetails", "sumPerInstallment"], form);

  const DetailFields = [
    { title: <TechBaseText content="{{UNIT_PRICE}}" />, name: ["lot", "unitPrice"], disabled: salesType === "FSP" },
    { title: <TechBaseText content="{{MAINTENANCE_FEE}}" />, name: ["lot", "maintenanceFee"] },
    { title: <TechBaseText content="{{TOTAL}}" />, name: "total", disabled: true },
    { title: <TechBaseText content="{{UNIT_DISCOUNT}}" />, name: ["lot", "unitDiscount"] },
    { title: <TechBaseText content="{{BALANCE_PAYABLE}}" />, name: "balance_payable", disabled: true },
    { title: <TechBaseText content="{{DOWNPAYMENT}}" />, name: ["lot", "downpayment"], bold: true },
    { title: <TechBaseText content="{{BALANCE_AMOUNT}}" />, name: "balance_amount", disabled: true },
    { title: <TechBaseText content="{{BOOKING_FEE_RECEIVED}}" />, name: "booking_fee_received", disabled: true }
  ];

  const [dialectGroups, setDialectGroups] = useState([]);
  const [showOtherInput, setShowOtherInput] = useState(false);
  // Fetch DialectGroup data
  useEffect(() => {
    const fetchDialectGroups = async () => {
      try {
        const response = await Post(
          "/util/getParameterValue",
          { category: "DialectGroup" },
          (data) => {
            if (data?.Success) {
              setDialectGroups(data.Data || []);
            } else {
              console.error("Failed to fetch DialectGroup data:", data);
            }
          },
          (error) => {
            console.error("Error fetching DialectGroup data:", error);
          },
          () => { } // Placeholder for the load function
        );
      } catch (error) {
        console.error("Error fetching DialectGroup data:", error);
      }
    };
    fetchDialectGroups();
  }, []);

  useEffect(() => {
    let total = 0,
      payableBalance = 0,
      balanceAmount = 0,
      firstInstallment = 0;
    total = unitPrice + maintenanceFee;
    payableBalance = total - unitDiscount;
    balanceAmount = payableBalance - downpayment;
    firstInstallment = balanceAmount - (installmentMonth - 1) * (sumPerInstallment || 0);

    form.setFieldsValue({
      total: total,
      balance_payable: payableBalance,
      balance_amount: balanceAmount
    });

    form.setFieldValue(["lot", "loanDetails", "firstInstallment"], firstInstallment);
  }, [unitPrice, maintenanceFee, unitDiscount, downpayment, installmentMonth, sumPerInstallment]);

  const onFinish = (value) => {
    // Add dialectGroupName or otherDialectGroup if applicable
    const dialectData = {
      dialectGroupValue: value.dialectGroupValue,
      dialectGroupName: value.otherDialectGroup || value.dialectGroupName, // Use otherDialectGroup if provided
    };

    // Pass the dialect data with the rest of the form values
    onNext({ ...value, ...dialectData });
  };

  return (
    <Form
      onFinish={onFinish}
      layout="vertical"
      form={form}
      initialValues={{
        dialectGroupValue: "1", // Replace with the correct value
        dialectGroupName: "cantonese",// Replace with the correct value
        purchaser_type: "existing",
        nationality: 127,
        mobile_prefix: "60",
        mobile_prefix_2: "60",
        identity_type: "ic",
        gender: "male",
        country: 127,
        state: 9,
        mailing_country: 127,
        mailing_state: 9,
        booking_fee_received: bookingOrder?.Price ?? bookingOrder?.TotalPrice ?? 0,
        lot: {
          unitPrice: selectedSlot.Price,
          maintenanceFee: selectedSlot.ReferenceContinuityFee || 0,
          unitDiscount: 0,
          downpayment: bookingOrder?.Price ?? bookingOrder?.TotalPrice ?? 0,
          loanDetails: {
            installmentMonth: 2,
            sumPerInstallment: 1
          }
        },
        ...formData
      }}
    >
      <Space direction="vertical" className="w-100">
        <Card className="mb-3">
            <div className="lead">
              <TechBaseText content="{{DETAILS}}" />
            </div>
          <Row gutter={32}>
            <Col xs={24} md={12}>
              <TechBaseText content="{{DIALECT_GROUP}}" />
            {/* </Col>
            <Col xs={24} md={12}> */}
              <Form.Item
                name="dialectGroupValue"
                rules={[requireRule]}
                className="m-0"
              >
                <Select
                  placeholder="Select Dialect Group"
                  onChange={(value) => {
                    const selectedGroup = dialectGroups.find(
                      (group) => group.ParameterValue === value
                    );
                    form.setFieldsValue({
                      dialectGroupName: selectedGroup?.ParameterName || "",
                    });
                    setShowOtherInput(selectedGroup?.ParameterName === "Others");
                  }}
                  options={dialectGroups.map((group) => ({
                    value: group.ParameterValue,
                    label: group.ParameterName,
                  }))}
                />
              </Form.Item>
              <Form.Item name="dialectGroupName" hidden>
                <input type="hidden" />
              </Form.Item>
            </Col>
          </Row>


          {showOtherInput && (
            <Row gutter={32}>
              <Divider></Divider>
              <Col xs={24} md={12}>
                <TechBaseText content="{{DIALECT_GROUP_VALUE}}" />
              </Col>
              <Col xs={24} md={12}>
                <Form.Item
                  name="otherDialectGroup"

                  rules={[
                    { required: true, message: "Please specify the dialect group." },
                  ]}
                >
                  <Input placeholder="Type your dialect group" />
                </Form.Item>

              </Col>
            </Row>
          )}
        </Card>
        <Card>
          <div className="lead">
            <TechBaseText content="{{PAYMENT_DETAILS}}" />
          </div>
          <>
            <Row gutter={32}>
            {DetailFields.map((field, index) => (
              <Col xs={24} md={12} key={index}>
                <span style={{ color: "#ff0000" }}>* </span>
                {field.bold ? <strong>{field.title}</strong> : field.title}
                <div>
                  <Form.Item
                    className="mt-1"
                    name={field.name}
                    rules={[
                      requireRule,
                      ({ getFieldValue }) => ({
                        validator(_, value) {
                          if (value < 0) {
                            return Promise.reject(new Error("Value should not be negative"));
                          }
                          return Promise.resolve();
                        }
                      })
                    ]}
                  >
                    <InputNumber className="w-100" disabled={field.disabled} min={field.min} />
                  </Form.Item>
                </div>
              </Col>
            ))}
          </Row>
          </>
        </Card>
        {balanceAmount > 0 && (
          <Card>
            <Space>
              <div className="lead">
                <TechBaseText content="{{INSTALLMENT_DETAILS}}" />
              </div>
            </Space>
              <>
              <Row gutter={32}>
              {InstallmentFields.map((field, index) => (
                <Col xs={24} md={12} key={index}>
                  <span style={{ color: "#ff0000" }}>* </span>
                  {field.title}
                  <div>
                    <Form.Item
                      className="mt-1"
                      name={field.name}
                      rules={[
                        requireRule,
                        ({ getFieldValue }) => ({
                          validator(_, value) {
                            if (value < 0) {
                              return Promise.reject(new Error("Value should not be negative"));
                            }
                            return Promise.resolve();
                          }
                        })
                      ]}
                    >
                      <InputNumber className="w-100" disabled={field.disabled} min={field.min} />
                    </Form.Item>
                  </div>
                </Col>
              ))}
            </Row>
              </>

          </Card>
        )}
        <Space className="d-flex justify-content-end" align="center">
          <TechBaseButton onClick={() => onBack(form.getFieldsValue())}>
            <TechBaseText content="{{BACK}}" />
          </TechBaseButton>
          <TechBaseButton htmlType="submit" color="primary">
            <TechBaseText content="{{NEXT}}" />
          </TechBaseButton>
        </Space>
      </Space>
    </Form >
  );
};

export default Step2;
