import React from 'react'
import { Select } from 'antd'

const TechBaseChartFilter = props => {
  let {
    options,
    style
  } = props.item
  return(
    <Select style={ style || {} } value={options[0]}>
      {
        options?.map( option => (
          <Select.Option value={option}>{ option }</Select.Option>
        ))
      }
    </Select>
  )
}

export default TechBaseChartFilter