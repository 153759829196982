import React, { useState, useEffect } from 'react'
import { FilePond, registerPlugin } from 'react-filepond'
import FilePondPluginFileValidateType from 'filepond-plugin-file-validate-type'
import FilePondPluginPdfPreview from "filepond-plugin-pdf-preview"
import { browserName } from "react-device-detect"

import 'filepond/dist/filepond.min.css'
import _ from 'lodash'
if( browserName === "Safari" ){
  registerPlugin( FilePondPluginFileValidateType )
} else {
  registerPlugin( FilePondPluginPdfPreview, FilePondPluginFileValidateType )
}

const TechBaseFilePond = props => {
  let{
    files,
    acceptedFileTypes,
    allowMultiple,
    actionKey,
  } = props.item

  const [ hasFilesProps, updateHasFileProps ] = useState( false )
  useEffect(() => {
    let tmp = false
    if( !_.isUndefined( props[ files ] ) ){
      tmp = true
    }
    if( !_.isEqual( tmp, hasFilesProps ) ){
      updateHasFileProps( tmp )
    }
  }, [ props[ files ] ])
  return(
    <FilePond
      { ...hasFilesProps ? { files: props[ files ] } : {} }
      acceptedFileTypes={ acceptedFileTypes }
      onupdatefiles={ props.onClickAction ? fileItems => props.onClickAction( actionKey, fileItems ) : () => {}}
      allowMultiple={ allowMultiple }
    />
  )
}

export default TechBaseFilePond