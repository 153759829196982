import React, { Component } from "react";
import { connect } from "react-redux";
import _ from "lodash";

import { Get, Post } from "utils/axios";
import { getSelectedLanguageContent, handleErrorMsg } from "utils";
import { requestError, requestSuccess } from "utils/requestHandler";
import { addLoadingContent, removeLoadingContent } from "reducers/LoadingOverlay";

const HOC = (WrappedComponent) => {
  class WithHOC extends Component {
    state = {
      unitProperties: {},
      selectedSlot: {},
      floorPlans: {},
      memberList: [],
      totalMembers: 0,
      productList: [],
      userCapacity: {},
      paymentOptions: [],
      genderOptions: [
        { label: getSelectedLanguageContent("{{MALE}}"), value: "M" },
        { label: getSelectedLanguageContent("{{FEMALE}}"), value: "F" },
      ],
      maritalStatusOptions: [
        { label: getSelectedLanguageContent("{{SINGLE}}"), value: "single" },
        { label: getSelectedLanguageContent("{{MARRIED}}"), value: "married" },
        { label: getSelectedLanguageContent("{{DIVORCED}}"), value: "divorced" },
        { label: getSelectedLanguageContent("{{WIDOWED}}"), value: "widowed" },
      ],
      countriesOption: [{ id: 127, phone_code: "60", iso2: "MY", name: "Malaysia" }],
      statesOption: [{ id: 9, name: "Pulau Pinang" }],
      page: "listing",
    };

    onChangeHOC = (key, value) => this.setState({ [key]: value });
    load = (param) => {
      if (param) {
        this.props.addLoadingContent();
      } else {
        this.props.removeLoadingContent();
      }
    };

    getUnitProperties = (payload) =>
      Get(`/product/getUnitProperties`, this.getUnitPropertiesSuccess, this.getUnitPropertiesError, this.load);
    getUnitPropertiesSuccess = (payload) => this.setState({ unitProperties: payload.Data });
    getUnitPropertiesError = (error) => requestError(handleErrorMsg(error));

    getFloorPlan = (payload) => {
      if (!payload || _.isEmpty(payload)) {
        console.error("Invalid payload for getFloorPlan:", payload);
        return;
      }

      console.log("Calling getFloorPlan with payload:", payload);

      Post(`/product/getFloorPlan`, payload, this.getFloorPlanSuccess, this.getFloorPlanError, this.load);
    };

    getFloorPlanSuccess = (payload) => {
      if (payload && payload.Data) {
        console.log("Floor plans retrieved successfully:", payload.Data);
        this.setState({ floorPlans: payload.Data });
      } else {
        console.error("Unexpected response structure:", payload);
        requestError("Failed to load floor plans.");
      }
    };

    getFloorPlanError = (error) => {
      console.error("Error in getFloorPlan API call:", error.response?.data || error.message);
      requestError(handleErrorMsg(error));
    };

    getMemberList = (params) =>
      Post(`/member/viewMemberList`, params, this.getMemberListSuccess, this.getMemberListError, this.load);
    getMemberListSuccess = (payload) => this.setState({ memberList: payload.Data, totalMembers: payload.Total });
    getMemberListError = (error) => requestError(handleErrorMsg(error));
    getMemberDetails = (id, callback, setError) =>
      Post(
        `/member/getMemberDetail`,
        { memberID: id },
        (payload) => this.getMemberDetailsSuccess(payload, callback),
        (error) => this.getMemberDetailsError(error, callback, setError),
        this.load
      );
    getMemberDetailsSuccess = (payload, callback) => callback(payload.Data);
    getMemberDetailsError = (error, callback, setError) => {
      setError("Member not found");
      callback();
    };

    getProductsOptions = () =>
      Post(
        `/product/getProductList`,
        { pageNumber: 1, pageSize: 10, productStatus: "1", productType: 1 },
        this.getProductsSuccess,
        this.getProductsError,
        this.load,
        true
      );

    getProductsSuccess = (payload) => this.setState({ productList: payload.Data });
    getProductsError = (error) => { };
    getUnitUserCapacity = (selectedSlot) =>
      Post(
        `/lot/getUnitUserCapacity`,
        { unitID: selectedSlot },
        this.getUnitUserCapacitySuccess,
        this.getUnitUserCapacityError,
        this.load
      );

    getUnitUserCapacitySuccess = (payload) => this.setState({ userCapacity: payload.Data });
    getUnitUserCapacityError = (error) => { };

    getParamOptions = (key, category) =>
      Post(
        `/util/getParameterValue`,
        { category: category },
        (payload) => this.getParamOptionsSuccess(payload, key),
        this.getParamOptionsError,
        this.load,
        true
      );
    getParamOptionsSuccess = (payload, key) => this.setState({ [key]: payload.Data });
    getParamOptionsError = (error) => { };

    createSales = (data, successCallback, isFSP) =>
      Post(
        `/sales/insertSales`,
        data,
        (payload) => this.createSalesSuccess(payload, successCallback, isFSP),
        this.createSalesError,
        this.load
      );
    createSalesSuccess = (payload, successCallback = () => { }) => {
      successCallback(payload);
    };
    createSalesError = (error) => requestError(handleErrorMsg(error));

    // assignIntendedUser = (data) =>
    //   Post(
    //     `/lot/assignIntendedUserToUnit`,
    //     data,
    //     this.assignIntendedUserSuccess,
    //     this.assignIntendedUserError,
    //     this.load
    //   );
    // assignIntendedUserSuccess = (payload) => {
    //   requestSuccess("Intended User Added Successfully");
    // };
    // assignIntendedUserError = (error) => requestError(handleErrorMsg(error));

    render = () => {
      return (
        <WrappedComponent
          {...this.props}
          {...this.state}
          getUnitProperties={this.getUnitProperties}
          onChangeHOC={this.onChangeHOC}
          getFloorPlan={this.getFloorPlan}
          getMemberList={this.getMemberList}
          getProductsOptions={this.getProductsOptions}
          getUnitUserCapacity={this.getUnitUserCapacity}
          getMemberDetails={this.getMemberDetails}
          getParamOptions={this.getParamOptions}
          createSales={this.createSales}
        // assignIntendedUser={this.assignIntendedUser}
        />
      );
    };
  }

  const mapStateToProps = (state) => ({
    data: state,
    profile: state.ProfileReducer.profile,
  });
  return connect(mapStateToProps, { addLoadingContent, removeLoadingContent })(WithHOC);
};

export default HOC;
