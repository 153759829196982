import React, { Component } from 'react'
import { connect } from 'react-redux'
import _ from 'lodash'

import { Get, Post, Put } from 'utils/axios'
import { getProfile } from 'actions/profile' 
import { handleErrorMsg, getSelectedLanguageContent } from "utils"
import { requestError, requestSuccess } from 'utils/requestHandler'
import { addLoadingContent, removeLoadingContent } from 'reducers/LoadingOverlay'

const HOC = ( WrappedComponent ) => {
  class WithHOC extends Component {
    state = {
      banks: [],
      uploadedICFront:[],
      ICFrontImageInfo: {},
      uploadedICBack: [],
      ICBackImageInfo: {},
      uploadedBankStatement: [],
      BankStatementImageInfo: {},
      documentOption: [
        { label: getSelectedLanguageContent("{{IC}}"), value: 'ic' },
        { label: getSelectedLanguageContent("{{PASSPORT}}"), value: 'passport' },
        { label: getSelectedLanguageContent("{{BUSINESS_REGISTRATION_NO}}"), value: 'ssm' }
      ],
      selectedDocumentType: 'ic',
      uploadedSSMCert:[],
      SSMCertImageInfo: {},
      uploadedSSMCertWithIC: [],
      SSMWithICImageInfo: {},
      showFormFeedbackICFront: false,
      showFormFeedbackICBack: false,
      showFormFeedbackBankStatement: false,
      showFormFeedbackSSMCert: false
    }

    load = param => {
      if( param ){
        this.props.addLoadingContent()
      } else {
        this.props.removeLoadingContent()
      }
    }

    onChangeClientUsersHOC = ( key, val ) => this.setState({ [ key ]: val })

    getBanks = () => Get(
      '/banks',
      this.getBanksSuccess,
      this.getBanksError,
      this.load
    )
    getBanksSuccess = payload => this.setState({
      banks: payload.map( item => ({
        id: item.id, 
        label: item.name, 
        value: item.name
      }) )
    })
    getBanksError = error => requestError( handleErrorMsg( error ) )

    updateClientUser = ( id, data ) => Post(
      `/client_users/${ id }/kyc`,
      data,
      this.updateClientUserSuccess,
      this.updateClientUserError,
      this.load
    )
    updateClientUserSuccess = () => {
      requestSuccess( getSelectedLanguageContent("{{SUBMIT_SUCCESS}}") )
      this.props.getProfile( this.props.data.ClientReducer )
      this.props.updateShowStatusModal( true )
      this.props.updateShowKYC( false )
    }
    updateClientUserError = error => {
      requestError( 
        error.error
          ? _.findIndex( error.error , { type: "unique violation", path: "ic_number" } ) > -1
            ? getSelectedLanguageContent("{{IC_REGISTERED_BEFORE}}")
            : handleErrorMsg( error )
          : handleErrorMsg( error )
      )
    }

    reuploadIc = ( id, data ) => Put(
      `/client_users/kyc_reupload/${ id }`,
      data,
      this.reuploadIcSuccess,
      this.reuploadIcError,
      this.load
    )
    reuploadIcSuccess = () => {
      requestSuccess( getSelectedLanguageContent("{{SUBMIT_SUCCESS}}") )
      this.props.getProfile( this.props.data.ClientReducer )
      this.props.updateShowStatusModal( true )
      this.props.updateShowKYC( false )
    }
    reuploadIcError = error => requestError( handleErrorMsg( error ) )

    render = () => {
      return (
        <WrappedComponent
          { ...this.props }
          onChangeClientUsersHOC={ this.onChangeClientUsersHOC }
          getBanks={ this.getBanks }
          updateClientUser={ this.updateClientUser }
          reuploadIc={ this.reuploadIc }
          banks={ this.state.banks }
          clientUserDetails={ this.state.clientUserDetails }
          uploadedICFront={ this.state.uploadedICFront }
          uploadedICBack={ this.state.uploadedICBack }
          ICFrontImageInfo={ this.state.ICFrontImageInfo }
          ICBackImageInfo={ this.state.ICBackImageInfo }
          uploadedBankStatement = {this.state.uploadedBankStatement}
          BankStatementImageInfo = {this.state.BankStatementImageInfo}
          documentOption={ this.state.documentOption }
          selectedDocumentType={ this.state.selectedDocumentType }
          uploadedSSMCert={ this.state.uploadedSSMCert }
          SSMCertImageInfo={ this.state.SSMCertImageInfo }
          uploadedSSMCertWithIC={ this.state.uploadedSSMCertWithIC }
          SSMWithICImageInfo={ this.state.SSMWithICImageInfo }
          showFormFeedbackICFront={ this.state.showFormFeedbackICFront }
          showFormFeedbackICBack = {this.state.showFormFeedbackICBack}
          showFormFeedbackBankStatement = {this.state.showFormFeedbackBankStatement}
          showFormFeedbackSSMCert={ this.state.showFormFeedbackSSMCert } />
      )
    }
  }
  const mapStateToProps = state => ({ 
    data: state,
    colors: state.ClientReducer.clientInfo.styles?.colors,
    profile: state.ProfileReducer.profile,
    siteInfo: state.ClientReducer.siteInfo,
    clientInfo: state.ClientReducer.clientInfo,
    loadingContent: state.LoadingOverlayReducer.loadingContent
  })
  return connect( mapStateToProps, {
    getProfile, addLoadingContent, removeLoadingContent
  })( WithHOC )
}

export default HOC
