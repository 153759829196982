import React from 'react'
import _ from 'lodash'
import { Button, ConfigProvider } from 'antd'

import { applyColor } from 'utils/apply-color'

const TechBaseButton = props => {
  const {
    color="",
    children,
  } = props

  return(
    <ConfigProvider
      theme={{
        token: {
          colorPrimary: color && applyColor(`$${color}`)
        },
      }}
    >
      {
        !props.hidden && ( <Button
          type={ color ? 'primary' : 'default' }
          {..._.omit(props, ['color', 'children'])}
        >
          {children}
        </Button> )
      }
    </ConfigProvider>
  )
}

export default TechBaseButton