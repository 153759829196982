import React, { useState, useEffect } from 'react'
import QRCode from "react-qr-code"
import { connect } from 'react-redux'

import { requestSuccess } from 'utils/requestHandler'
import { convertObjectToBase64 } from 'utils/objToBase64'

const ReferralLink = props => {
  let {
    size
  } = props.item

  const [ linkType, updateLinkType ] = useState("")
  useEffect(() => {
    if( props.showSponsorLink && props.showSponsorLink.includes( props.profile.role_name )){
      updateLinkType( "sponsor" )
    } else if( props.showReferralLink && props.showReferralLink.includes( props.profile.role_name )){
      updateLinkType( "referral" )
    } else {
      updateLinkType("")
    }
  }, [ props.profile ])
  
  if( linkType ){
    return(
      <>
        <QRCode
          size={ size }
          style={{ cursor: 'pointer' }}
          onClick={() => {
            const text = `https://jombiz-ecomm-staging.junomax.co/en/signup/${ convertObjectToBase64({ sponsor: props.profile.username })}`
            navigator.clipboard.writeText( text )
            .then(
              () => requestSuccess( `Registration link is copied to clipboard` ),
              () => {
                var tmpInput = document.createElement( 'input' )
                document.getElementById( 'tmp-cont' ).appendChild( tmpInput )

                tmpInput.value = text
                tmpInput.select()
                document.execCommand( 'copy' )
                tmpInput.remove()

                requestSuccess( `Registration link is copied to clipboard` )
              }
            )
          }}
          value={`${ window.location.protocol }//${ window.location.host }/register/${ convertObjectToBase64( linkType === "sponsor" ? { sponsor: props.profile.unique_hash, ...props.clientInfo.client_overall_config?.enable_placement_tree ? { placement: props.profile.unique_hash } : {} } : { referral: props.profile.unique_hash } ) }`}
        />
        <div id='tmp-cont'></div>
      </>
    )
  } else {
    return <></>
  }
}

const mapStateToProps = (state) => ({ 
  showReferralLink: state.ClientReducer.clientInfo?.client_overall_config?.showReferralLink,
  showSponsorLink: state.ClientReducer.clientInfo?.client_overall_config?.showSponsorLink,
  profile: state.ProfileReducer.profile,
  clientInfo: state.ClientReducer.clientInfo
})

export default connect(mapStateToProps)( ReferralLink )
