import { GET_ACCESS_TOKEN, LOGIN_REQUEST, LOGOUT_REQUEST } from "actions/types";

let initialState = {
  accessToken: "",
};

export default function AuthReducer(state = initialState, action) {
  switch (action.type) {
    case LOGIN_REQUEST:
      return {
        ...state,
        accessToken: action.payload,
      };
    case LOGOUT_REQUEST:
      return initialState;
    default:
      return state;
  }
}
