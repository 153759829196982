import React from "react"
import { Form } from "antd"
import _ from "lodash"

import { handleNestedObjectKey, getSelectedLanguageContent } from 'utils'
import CoreRenderFunction from "DisplayCore"

const TechBaseFormItem = props => {
  let{
    name,
    label,
    extra,
    rules,
    style,
    className,
    children
  } = props.item

  const processRules = rules => {
    return rules.map( rule => {
      if( typeof rule === 'function' ){
        return ({ getFieldValue }) => rule(getFieldValue, props)
      } else if( Object.keys( rule ).includes('equal') ){
        return ({ getFieldValue }) => ({
          validator(_, value) {
            if (!value || getFieldValue( rule.equal ) === value) {
              return Promise.resolve();
            }
            return Promise.reject(new Error( getSelectedLanguageContent( rule.message, props.selectedLanguage ) ))
          },
        })
      } else {
        return {...rule, message: getSelectedLanguageContent( rule.message, props.selectedLanguage )}
      }
    })
  }
  
  return(
    <Form.Item
      name={ name[0] === "$" ? handleNestedObjectKey( props, name.slice(1) ) : name }
      className={ className }
      label={ getSelectedLanguageContent( label, props.selectedLanguage ) }
      extra={ getSelectedLanguageContent( extra, props.selectedLanguage ) }
      style={ style }
      rules={ rules && processRules( rules ) }>
      {
        children?.map( child => {
          return <CoreRenderFunction { ...props } item={ child }/>
        })
      }
    </Form.Item>
  )
}

export default TechBaseFormItem