import React from 'react'
const TechBaseFooter = props => {
  const clientName = props.data.ClientReducer.clientInfo.name || 'Papan Memorial Group';
  return (
    <div className="text-center py-2 techbase-footer" style={props.item?.style || {}}>
      {`© Copyright 2025 ${clientName} - All rights reserved.`}
    </div>
  );
}

export default TechBaseFooter