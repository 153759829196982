import { Modal, Form, Input, Row, Col } from "antd";
import { useEffect } from "react";
import _ from "lodash";
import TechBaseButton from "DisplayCoreV2/Button";
import TechBaseText from "DisplayCoreV2/Text";
import { requireRule } from "utils/rules";

const ProductForm = ({ title, open, onCancel, onSubmit, selectedPackage }) => {
  const [ProductForm] = Form.useForm();

  useEffect(() => {
    if (!_.isEmpty(selectedPackage)) {
      ProductForm.setFieldsValue({
        productCode: selectedPackage.ProductCode,
        productName: selectedPackage.ProductName,
        productPrice: selectedPackage.Price
      });
    }
  }, [selectedPackage]);
  return (
    <Modal open={open} size={"md"} onCancel={() => onCancel()} footer={null} title={title}>
      <Form preserve={false} layout="vertical" form={ProductForm} onFinish={onSubmit}>
        <Row gutter={32}>
          <Col xs={24}>
            <Form.Item label={<TechBaseText content="{{PRODUCT_CODE}}" />} name="productCode" rules={[requireRule]}>
              <Input size="large" disabled={!_.isEmpty(selectedPackage)} />
            </Form.Item>
          </Col>
          <Col xs={24}>
            <Form.Item label={<TechBaseText content="{{PRODUCT_NAME}}" />} name="productName" rules={[requireRule]}>
              <Input size="large" />
            </Form.Item>
          </Col>
          <Col xs={24}>
            <Form.Item label={<TechBaseText content="{{PRODUCT_PRICE}}" />} name="productPrice" rules={[requireRule]}>
              <Input size="large" type="number" />
            </Form.Item>
          </Col>
        </Row>
        {/* <TechBaseButton size="large" color="primary" htmlType="submit">
          <TechBaseText content="{{SUBMIT}}" />
        </TechBaseButton> */}
        <div className="techbase-button-wrapper-modal">
          <TechBaseButton className="techbase-button-modal" size="large" color="primary" htmlType="submit">
            <TechBaseText content="{{SUBMIT}}" />
          </TechBaseButton>
        </div>
      </Form>
    </Modal>
  );
};

export default ProductForm;
