// App.js
import React, { useEffect } from "react";
import { HashRouter } from "react-router-dom";
import { connect } from "react-redux";
import { Helmet } from 'react-helmet';
import _ from 'lodash';
import { registerPlugin } from 'react-filepond';
import FilePondPluginImagePreview from 'filepond-plugin-image-preview';
import FilePondPluginFileValidateSize from 'filepond-plugin-file-validate-size';
import FilePondPluginFileValidateType from 'filepond-plugin-file-validate-type';

import i18next from 'i18next';
import { initReactI18next } from 'react-i18next';
import LanguageDetector from 'i18next-browser-languagedetector';

import { en } from 'locale/en';
import { zh } from 'locale/zh';
import { ms } from 'locale/ms';
import LoadingOverlay from 'components/Indicator/LoadingOverlay';
import { clearLoadingContent } from 'reducers/LoadingOverlay';
import TechBaseRouter from "router";
import CacheBuster from 'CacheBuster';


import "./App.css";
import 'swiper/css';
import 'swiper/css/navigation';
import 'swiper/css/thumbs';
import 'filepond/dist/filepond.min.css';
import 'filepond-plugin-image-preview/dist/filepond-plugin-image-preview.css';
import { applyColor } from "utils/apply-color";

registerPlugin(FilePondPluginImagePreview, FilePondPluginFileValidateSize, FilePondPluginFileValidateType);


// Initialize i18next
i18next
  .use(LanguageDetector)
  .use(initReactI18next)
  .init({
    resources: { en, zh, ms },
    fallbackLng: 'en',
    debug: true,
    detection: {
      order: ['localStorage', 'navigator'],
      caches: ['localStorage'], // Cache language in localStorage
    },
    interpolation: {
      escapeValue: false, // React already does escaping
    },
  });

function App(props) {
  useEffect(() => {
    props.clearLoadingContent();
  }, []);

  useEffect(() => {
    // Change language based on profile preference, if it exists
    if (props.profile?.preferred_language) {
      i18next.changeLanguage(props.profile.preferred_language);
    }
  }, [props.profile.preferred_language]);

  return (
    <CacheBuster>
      {({ loading, isLatestVersion, refreshCacheAndReload }) => {
        if (loading) return null;
        if (!loading && !isLatestVersion) {
          refreshCacheAndReload();
        }

        return (
          <>
            <Helmet>
              <link rel="icon" href="/favicon.ico" sizes="16x16" />
              <title>Papan Memorial</title>
            </Helmet>
            <TechBaseRouter />
            <style>
              {`
                .techbase-table .ant-table {
                  overflow-y: scroll;
                }
                 .techbase-dropdown .ant-collapse-header, .techbase-container, .techbase-list .ant-list-header{
                  color: #343a40 !important;
                  background: ${applyColor('$tableThead')};
                }
                .techbase-dropdown{
                  .ant-collapse-header{
                    border-radius: 8px !important;
                  }
                  .ant-collapse-item-active .ant-collapse-header{
                    border-radius: 8px 8px 0 0 !important;
                  }
                }
                .techbase-container, .techbase-list{
                  border-radius: 8px;
                  overflow: hidden;
                }
              `}
            </style>
            {props.loadingContent.length > 0 && <LoadingOverlay />}
          </>
        );
      }}
    </CacheBuster>
  );
}

const mapStateToProps = (state) => ({
  data: state,
  loadingContent: state.LoadingOverlayReducer.loadingContent,
  profile: state.ProfileReducer.profile
});

export default connect(mapStateToProps, { clearLoadingContent })(App);