import React from "react";
import _ from "lodash";
import { Result, ConfigProvider } from "antd";

import { applyColor } from "utils/apply-color";
import { FormatPainterFilled } from "@ant-design/icons";

const TechBaseConstruction = (props) => {
  return (
    <ConfigProvider
      theme={{
        token: {
          colorPrimary: applyColor(`$primary`)
        }
      }}
    >
      <Result
        icon={<FormatPainterFilled />}
        title="Our Website is Still Drying"
        subTitle="We're thrilled about our new website, but the paint hasn't quite dried yet. We're working hard to get everything ready for you. Stay tuned for the final reveal!"
      />
    </ConfigProvider>
  );
};

export default TechBaseConstruction;
