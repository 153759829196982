import React, { useRef, useEffect } from "react"
import { message } from "antd"
import _ from "lodash"
import qs from 'qs'

import LoadingOverlay from 'components/Indicator/LoadingOverlay'
import WithHOC from "./action"
import CoreRenderFunction from "DisplayCore"
import { LoginLayout } from "PageLayout/Login"
import { getItem, storeItem, clearItem } from 'utils/tokenStore'

const Login = props => {
  let {
    data,
    profile,
    siteInfo,
    onLoadLogin,
    loginRequest,
    setUserProfile,
    router
  } = props

  useEffect(() => {
    const tokenParam = qs.parse(router.location.search, { ignoreQueryPrefix: true } ).token
    if( !_.isEmpty(tokenParam) ){
      clearItem("IMPERSONATE_TOKEN")
      storeItem( 'TECHBASE_TOKEN', tokenParam )
      return router.navigate( '/dashboard' )
    } else if( getItem( 'TECHBASE_TOKEN' )) {
      return router.navigate( '/dashboard' )
    } else {
      clearItem( 'IMPERSONATE_TOKEN' )
      if( !_.isEmpty( profile )){
        setUserProfile({}) 
      }
    }
  })
  
  let captchaRef = useRef()

  const onSubmitForm = e => {
    if( captchaRef.current.validateCaptcha( e.captcha ) ){
      loginRequest({ username: e.username, password: e.password })
    } else {
      message.error("Invalid Captcha")
    }
  }

  return (
    <>
      <CoreRenderFunction 
        { ...props } 
        item={ LoginLayout }
        onSubmitForm={ onSubmitForm } 
        captchaRef={ captchaRef }/>
      {
        onLoadLogin && <LoadingOverlay />
      }
    </>
  )
}

export default WithHOC( Login )
