import React from 'react'

import CoreRenderFunction from 'DisplayCore'

const TechBaseCharts = props => {
  let {
    style,
    className,
    children
  } = props.item
  //This part will control the data for filter and content
  return(
    <div style={ style } className={ className || "" }>
      {
        children?.map( child => <CoreRenderFunction { ...props } item={ child } /> )
      }
    </div>
  )
}

export default TechBaseCharts