import React from 'react'
import { Statistic } from 'antd'
import { handleNestedObjectKey } from 'utils'

const TechBaseStatistic = props => {
  let {
    title,
    value,
    prefix,
    suffix,
    valueDataKey,
    prefixDataKey,
    suffixDataKey,
    valueStyle
  } = props.item

  const getSuffix = ( suffix, suffixDataKey, props ) => {
    if( suffixDataKey ) {
      const data = handleNestedObjectKey( props, suffixDataKey )
      if( suffix ) {
        return suffix.replaceAll( '${PLACEHOLDER}', data )
      }
      return `/ ${ data }`
    } else {
      return suffix
    }
  }

  return(
    <Statistic
      title={ title }
      value={ value || handleNestedObjectKey( props, valueDataKey )}
      prefix={ prefix || handleNestedObjectKey( props, prefixDataKey )}
      suffix={ getSuffix( suffix, suffixDataKey, props )}
      valueStyle={ valueStyle }
    />
  )
}

export default TechBaseStatistic
