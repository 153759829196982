import React, { useState } from "react"
import { Table, Modal, Button } from "antd"
import { handleNestedObjectKey } from 'utils'

const ListOptions = (props) => {
  const { open, onCancel, columns, dataSource, onSubmit, selectedKeys } = props.item
  const [selectedValues, setSelectedValues] = useState(handleNestedObjectKey(props, selectedKeys))

  return (
    <Modal open={handleNestedObjectKey(props, open)} width={800} onCancel={() => props.onClickAction( onCancel )} footer={null}>
      <Table
        rowKey="id"
        className="mt-4"
        rowSelection={{
          type: "checkbox",
          selectedRowKeys: selectedValues.keys,
          onChange: (selectedRowKeys, selectedRows) => {
            setSelectedValues({keys: selectedRowKeys, rows: selectedRows})
          },
        }}
        columns={
          handleNestedObjectKey(props, columns) || [
            {
              title: "Options",
              dataIndex: "label",
            },
          ]
        }
        dataSource={handleNestedObjectKey(props, dataSource) || []}
      />
      <div className="d-flex justify-content-end">
        <Button onClick={()=>{props.onClickAction( onSubmit, selectedValues )}}>Add</Button>
      </div>
    </Modal>
  )
}

export default ListOptions
