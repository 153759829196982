import React, { useState, useEffect } from "react"
import { Form, Select } from "antd"
import Flag from 'react-world-flags'
import _ from "lodash"

import CoreRenderFunction from 'DisplayCore'
import { checkIfComponentDisabled, getSelectedLanguageContent } from 'utils'
import './index.scss'
const { Option } = Select

const TechBaseSelect = props => {
  let{
    name,
    label,
    rules,
    style,
    className,
    inputConfig,
    roleConfig,
    children,
    actionKey,
    onSearchActionKey,
    mode
  } = props.item
  const [ options, updateOptions ] = useState([])

  useEffect(() => {
    props.form.setFieldValue( `${ name }`, inputConfig.defaultValue )
    if( props[ inputConfig.dataKey ] ){
      updateOptions( props[ inputConfig.dataKey ] )
    } else {
      updateOptions([])
    }
  }, [ props[ inputConfig.dataKey ] ])

  const checkIsDisabled = () => {
    if( inputConfig.isDisabled ) {
      return checkIfComponentDisabled( props.disabledComponent, inputConfig )
    }
    return inputConfig.disabled || roleConfig && props.disabled
  }

  return(
    <Form.Item
      name={ name }
      className={ className }
      label={ getSelectedLanguageContent( label )}
      style={ style }
      rules={ rules?.map(rule => ({...rule, message: getSelectedLanguageContent( rule.message, props.selectedLanguage )}))}>
      <Select 
        mode={ mode }
        showSearch={ _.isUndefined( inputConfig.showSearch ) ? true : inputConfig.showSearch }
        allowClear={ inputConfig.allowClear }
        disabled={ checkIsDisabled()}
        placement="bottomLeft"
        size={ inputConfig.size }
        style={ inputConfig.style }
        className={ ( inputConfig.className || "" ) + ( inputConfig.showFlag ? " show-flag" : "" ) }
        getPopupContainer={trigger => trigger.parentElement}
        placeholder={ getSelectedLanguageContent( inputConfig.placeholder )}
        showArrow={ inputConfig.showArrow }
        defaultActiveFirstOption={ inputConfig.defaultActiveFirstOption }
        defaultValue={ inputConfig.defaultValue }
        optionLabelProp={children ? 'label' : undefined}
        onSearch={ props.onClickAction ? ( value ) => { props.onClickAction( onSearchActionKey, value )} : null }
        onChange={ props.onClickAction ? ( value ) => { props.onClickAction( actionKey, value )} : null }
        filterOption={(input, option) => 
          ( typeof option.value === "number" ? option.value.toString().indexOf(input.toLowerCase()) >= 0  : option.value.toLowerCase().indexOf(input.toLowerCase()) >= 0 ) ||
          ( typeof option.children[1] === "number" ? option.children[1].toString().indexOf(input.toLowerCase()) >= 0 : option.children[1].toLowerCase().indexOf(input.toLowerCase()) >= 0 )
        }>
        { 
          inputConfig.isNullable && (
            <Option key={ "null" } value={ null }></Option>
          )
        }
        { inputConfig.options?.map( option => <option value={ option.value }>{ getSelectedLanguageContent( option.label ) }</option> ) }
        {
          options.map( option => (
            <Option key={ option.id } value={ option[ inputConfig.value || "value" ] } label={option[ inputConfig.label || "label" ]}>
              { inputConfig.showFlag && <div style={{ width: '35px'}} className="mr-2"><Flag code={option.iso2} /></div>}
              { 
                children ? (
                  children.map( child => <CoreRenderFunction { ...props } item={ child } selectedItem={ option }/> )
                ) : (
                  getSelectedLanguageContent( option[ inputConfig.label || "label" ] ) 
                )
              }
            </Option>
          ))
        }
      </Select>
    </Form.Item>
  )
}

export default TechBaseSelect