import React, { useEffect, useState } from "react";
import { Card, Form, Checkbox, Space, Table, InputNumber, Col, Row, Radio } from "antd";

import TechBaseText from "DisplayCoreV2/Text";
import TechBaseButton from "DisplayCoreV2/Button";
import TechbaseMemberFinder from "DisplayCoreV2/MemberFinder";
import IntendedUserInfo from "../IntendedUserInfo";
import { requireRule } from "utils/rules";

const urnListColumns = [
  {
    title: <TechBaseText content="{{NUMBER}}" />,
    render: (text, record, index) => index + 1,
  },
  {
    title: <TechBaseText content="{{PRODUCT_CODE}}" />,
    dataIndex: "ProductCode",
    key: "ProductCode",
  },
  {
    title: <TechBaseText content="{{PRODUCT_NAME}}" />,
    dataIndex: "ProductName",
    key: "ProductName",
  },
  {
    title: <TechBaseText content="{{PRICE}}" />,
    dataIndex: "Price",
    key: "Price",
  },
  {
    title: <TechBaseText content="{{CATEGORY}}" />,
    dataIndex: "ProductCategoryName",
    key: "ProductCategoryName",
  },
  {
    title: <TechBaseText content="{{PLACE_ORDER}}" />,
    key: "action",
    render: (text, record) => (
      <Form.Item className="m-0" name={["items", record.ID]}>
        <InputNumber min={0} max={5} />
      </Form.Item>
    ),
  },
];

const Step1 = (props) => {
  const {
    profile,
    selectedSlot,
    onNext,
    formData,
    productList,
    salesType,
    bookingOrder,
    newCustomer,
    toggleCreateCustomer,
    setNewCustomer,
    userCapacity,
  } = props;
  const [form] = Form.useForm();
  const intendedUser1 = Form.useWatch("intended_user_1", form);
  const intendedUser2 = Form.useWatch("intended_user_2", form);
  const [intendedUsers, setIntendedUsers] = useState({});

  const handleCheckboxChange = (userIndex, checked) => {
    setIntendedUsers((prev) => ({ ...prev, [`intendedUser${userIndex}`]: checked }));
  };
  useEffect(() => {
    if (newCustomer) {
      form.setFieldValue("purchaserID", newCustomer);
      setNewCustomer("");
    }
  }, [newCustomer]);
  
  useEffect(() => {
    if (selectedSlot.UnitID) {
    props.getUnitUserCapacity(selectedSlot.UnitID);
    }
  }, []);
  const onFinish = (value) => onNext(value);
  return (
    <Form
      scrollToFirstError
      onFinish={onFinish}
      layout="vertical"
      form={form}
      initialValues={{
        memberID: bookingOrder?.AgentID,
        purchaserID: bookingOrder?.PurchaserID,
        needCondition: salesType !== "FSP" ? "PN" : undefined, // Set default value for needCondition
        ...formData,
      }}
    >
      <Row className="w-100 d-block ">
        <Card className="mb-3">
          {salesType === "FSP" ? (
            <div className="lead">
              <TechBaseText content="{{SELECTED_FSP}}" />: {selectedSlot.ProductName}
            </div>
          ) : salesType === "monument" ? (
            <div className="lead">
              <TechBaseText content="{{SELECTED_MONUMENT}}" />: {selectedSlot.ProductName}
            </div>
          ) : (
            <div className="lead">
              <TechBaseText content="{{LOT_NO}}" />: {selectedSlot.UnitID}
            </div>
          )}
        </Card>
        <Card className="mb-3">
          <div className="lead">
            <TechBaseText content="{{PERSONAL_INFORMATION}}" />
          </div>
          <Row gutter={32}>
            {salesType !== "FSP" && (
              <Col xs={24}>
                <Form.Item name="needCondition" rules={[requireRule]}>
                  <Radio.Group>
                    <Radio value="PN">
                      {" "}
                      <TechBaseText content="{{PRE_NEED}}" />{" "}
                    </Radio>
                    <Radio value="AN">
                      {" "}
                      <TechBaseText content="{{AS_NEED}}" />{" "}
                    </Radio>
                  </Radio.Group>
                </Form.Item>
              </Col>
            )}
            <Col xs={24} md={12}>
              <Form.Item label={<TechBaseText content="{{AGENT_ID}}" />} rules={[requireRule]} name="memberID">
                <TechbaseMemberFinder
                  role="M"
                  size="large"
                  placeholder={TechBaseText({ content: "{{AGENT_ID}}" })}
                  disabledSearch={profile.Role !== "AD"}
                />
              </Form.Item>
            </Col>
            <Col xs={24} md={12}>
              <Form.Item label={<TechBaseText content="{{PURCHASER_ID}}" />} rules={[requireRule]} name="purchaserID">
                <TechbaseMemberFinder
                  role="C"
                  size="large"
                  placeholder={TechBaseText({ content: "{{PURCHASER_ID}}" })}
                  disabledSearch={props.disabledPurchaserID}
                />
              </Form.Item>
              <TechBaseButton
                size="large"
                style={{ width: "300px" }}
                onClick={toggleCreateCustomer}
                hidden={props.hideCreateNewPurchaser}
              >
                <TechBaseText content="{{CREATE_NEW_PURCHASER}}" />
              </TechBaseButton>
            </Col>
          </Row>
        </Card>

        {salesType !== "FSP" && (
          <>
            <Card className="mb-3">
              <div className="lead">
                <TechBaseText content="{{URN_LIST}}" />
              </div>
              <Table
                bordered
                className="techbase-table"
                columns={urnListColumns}
                dataSource={productList}
                pagination={false}
              />
            </Card>
            <Card className="mb-3">
              <div className="lead">
                <TechBaseText content="{{ADD_INTENDED_USER}}" />
              </div>
              {Array.from({ length: userCapacity }, (_, index) => {
                const userIndex = index + 1;
                return (
                  <React.Fragment key={userIndex}>
                    <Form.Item className="m-0" name={`intended_user_${userIndex}`} valuePropName="checked">
                      <Checkbox onChange={(e) => handleCheckboxChange(userIndex, e.target.checked)}>
                        <TechBaseText content="{{USER}}" /> {userIndex}
                      </Checkbox>
                    </Form.Item>
                    {intendedUsers[`intendedUser${userIndex}`] && <IntendedUserInfo {...props} index={userIndex} />}
                  </React.Fragment>
                );
              })}
            </Card>
          </>
        )}

        <div className="techbase-button-wrapper-modal">
          <TechBaseButton className="techbase-button-modal" htmlType="submit" color="primary">
            <TechBaseText content="{{NEXT}}" />
          </TechBaseButton>
        </div>
      </Row>
    </Form>
  );
};

export default Step1;
