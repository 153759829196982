import React, { useEffect } from "react";
import _ from "lodash";
import { Card, Input, Form, Checkbox, Space, InputNumber, Select, Row, Col } from "antd";

import TechbaseMemberFinder from "DisplayCoreV2/MemberFinder";
import TechBaseButton from "DisplayCoreV2/Button";
import TechBaseText from "DisplayCoreV2/Text";
import TechBaseFilePond from "DisplayCoreV2/FilePond";
import { requireRule } from "utils/rules";
import { requestSuccess } from "utils/requestHandler";

const BookingForm = (props) => {
  const {
    profile,
    newCustomer,
    salesType,
    selectedSlot,
    paymentOptions,
    getParamOptions,
    createSales,
    getFloorPlan,
    onChangeHOC,
    onChangeServiceHOC,
    toggleCreateCustomer,
    setNewCustomer,
    selectedArea
  } = props;
  const [form] = Form.useForm();
  const hasIntroducer = Form.useWatch("booked_via_introducer", form);
  const onFinish = (formData) => {
    const processedFormData = {
      ...formData,
      totalPrice: Number(formData.totalPrice || 0), // Ensure totalPrice is a number
      agreedPrice: Number(formData.agreedPrice || 0), // Ensure agreedPrice is a number
    };

    createSales(
      {
        ...processedFormData,
        salesType: salesType === "FSP" ? "7" : "6",
        ...(salesType === "FSP"
          ? {
            items: [
              {
                productCode: selectedSlot.ProductCode,
                quantity: 1,
                productID: selectedSlot.ID,
                productName: selectedSlot.ProductName,
                singlePrice: selectedSlot.Price,
                totalPrice: processedFormData.totalPrice,
              },
            ],
          }
          : { unitID: selectedSlot.UnitID }),
        salesByID: profile.MemberId,
        purchaserID: processedFormData.purchaserID.MemberId,
        memberID: processedFormData.salesByID.MemberId,
        introducerDetail: processedFormData.introducerDetail?.MemberId,
        // receipt: formData.receipt?.[0]?.base64 || "",
        receipt: processedFormData.receipt?.base64 != null ? processedFormData.receipt.base64 : "",
        paymentPurpose: "9",
      },
      (response) => {
        requestSuccess(response.Message); // Use your success handler
        form.resetFields();

        if (salesType === "FSP") {
          onChangeServiceHOC("showBookingForm", false);
          onChangeServiceHOC("selectedPackage", {});
        } else {
          onChangeHOC("page", "listing");
          onChangeHOC("selectedSlot", {});

          if (selectedArea && selectedArea.someRequiredField) {
            console.log("Calling getFloorPlan with selectedArea:", selectedArea);
            try {
              getFloorPlan(selectedArea);
            } catch (error) {
              console.error("Error in getFloorPlan:", error.response?.data || error.message);
            }
          } else {
            console.log("Skipping getFloorPlan due to invalid selectedArea.");
          }
        }
        getFloorPlan(selectedArea);
      },
      salesType === "FSP"
    );
  };


  useEffect(() => {
    getParamOptions("paymentOptions", "PaymentType");
  }, []);

  useEffect(() => {
    // If paymentOptions is fetched asynchronously, wait for the data
    if (paymentOptions && paymentOptions.length > 0) {
      // Filter out the relevant options
      const filteredOptions = _.filter(
        paymentOptions,
        (opt) => opt.Sort === 0 && !opt.ParameterName.toLowerCase().includes("wallet")
      );
      // If there's at least one filtered option, set it as default
      if (filteredOptions.length) {
        form.setFieldValue("paymentType", filteredOptions[0].ParameterValue);
      }
    }
  }, [paymentOptions]);

  useEffect(() => {
    if (newCustomer) {
      form.setFieldValue("purchaserID", newCustomer);
      setNewCustomer("");
    }
  }, [newCustomer]);

  return (
    <Form
      onInitial={props.onInitial}
      onFinish={onFinish}
      layout="vertical"
      form={form}
      initialValues={{
        // totalPrice: salesType === "FSP" ? selectedSlot.Price || 0 : 0, // Ensure default is a number
        totalPrice: salesType === "FSP" ? 0 : 0, // Ensure default is a number
        agreedPrice: salesType === "FSP" ? selectedSlot.Price : selectedSlot.ReferencePrice || 0,
      }}
    >
      <Row className="w-100 d-block ">
        <Card className="mb-3">
          <Row gutter={32}>
            {/* Left column: Lot No / Agent fields / checkboxes */}
            <Col xs={24} md={12}>
              {/* Top text: Lot No or Selected FSP */}
              <div className="lead mb-2">
                {salesType === "FSP" ? (
                  <>
                    <TechBaseText content="{{SELECTED_FSP}}" />: {selectedSlot.ProductName}
                  </>
                ) : (
                  <>
                    <TechBaseText content="{{LOT_NO}}" />: {selectedSlot.UnitID}
                  </>
                )}
              </div>

              {/* Agent ID */}
              <Form.Item
                label={<TechBaseText content="{{AGENT_ID}}" />}
                rules={[requireRule]}
                name="salesByID"
              >
                <TechbaseMemberFinder
                  role="M"
                  size="large"
                  placeholder={TechBaseText({ content: "{{AGENT_ID}}" })}
                  disabledSearch={profile.Role !== "AD"}
                />
              </Form.Item>

              {/* Checkboxes */}
              <Form.Item className="m-0" name="isMailToPurchaserId" valuePropName="checked">
                <Checkbox>
                  <TechBaseText content="{{MAIL_TO_PURCHASER}}?" />
                </Checkbox>
              </Form.Item>
              <Form.Item className="m-0" name="booked_via_introducer" valuePropName="checked">
                <Checkbox>
                  <strong>
                    <TechBaseText content="{{BOOKED_VIA_INTRODUCER}}?" />
                  </strong>
                </Checkbox>
              </Form.Item>
            </Col>

            {/* Right column: Purchaser heading above Purchaser ID + Create button */}
            <Col xs={24} md={12}>
              <div className="lead mb-2">
                <TechBaseText content="{{PURCHASER_DETAILS}}" />
              </div>

              <Form.Item
                label={<TechBaseText content="{{PURCHASER_ID}}" />}
                rules={[requireRule]}
                name="purchaserID"
              >
                <TechbaseMemberFinder
                  role="C"
                  size="large"
                  placeholder={TechBaseText({ content: "{{PURCHASER_ID}}" })}
                />
              </Form.Item>

              <TechBaseButton size="large" style={{ width: "300px" }} onClick={toggleCreateCustomer}>
                <TechBaseText content="{{CREATE_NEW_PURCHASER}}" />
              </TechBaseButton>
            </Col>
          </Row>
        </Card>

        {hasIntroducer && (
          <Card className="mb-3">
            <div className="lead mb-2">
              <TechBaseText content="{{INTRODUCER_DETAILS}}" />
            </div>

            <Row>
              <Col xs={24} md={8}>
                <Form.Item
                  label={<TechBaseText content="{{INTRODUCER_ID}}" />}
                  rules={[requireRule]}
                  name="introducerDetail"
                >
                  <TechbaseMemberFinder size="large" placeholder={TechBaseText({ content: "{{INTRODUCER_ID}}" })} />
                </Form.Item>
              </Col>
            </Row>
          </Card>
        )}
        <Card className="mb-3">
          <div className="lead mb-2">
            <TechBaseText content="{{PAYMENT_DETAILS}}" />
          </div>
          <Row gutter={32}>
            <Col xs={24} md={12}>
              <Form.Item name="paymentType" label={<TechBaseText content="{{PAYMENT_TYPE}}" />} rules={[requireRule]}>
                <Select
                  size="large"
                  options={_.filter(
                    paymentOptions,
                    (opt) => opt.Sort === 0 && !opt.ParameterName.toLowerCase().includes("wallet")
                  )}
                  fieldNames={{ label: "ParameterName", value: "ParameterValue" }}
                  placeholder={<TechBaseText content="{{PLEASE_SELECT}} {{PAYMENT_TYPE}}" />}
                />
              </Form.Item>
            </Col>
            <Col xs={24} md={12}>
              <Form.Item name="remarks" label={<TechBaseText content="{{REMARKS}}" />}>
                <Input size="large" placeholder={TechBaseText({ content: "{{REMARKS}}" })} />
              </Form.Item>
            </Col>

            <Col xs={24} md={12}>

              <Form.Item
                name="totalPrice"
                label={<TechBaseText content="{{BOOKING_AMOUNT}}" />}
                rules={[
                  requireRule,
                  {
                    type: "number",
                    message: "Total price must be a number.",
                  },
                ]}
              >
                <InputNumber
                  className="w-100"
                  min={0}
                  size="large"
                  placeholder={TechBaseText({ content: "{{BOOKING_AMOUNT}}" })}
                />
              </Form.Item>
            </Col>

            <Col xs={24} md={12}>
              <Form.Item
                validateFirst
                name="agreedPrice"
                label={<TechBaseText content="{{AGREED_TOTAL}}" />}
                dependencies={["totalPrice"]}
                rules={[
                  requireRule,
                  ({ getFieldValue }) => ({
                    validator(_, value) {
                      if (value < getFieldValue('totalPrice')) {
                        return Promise.reject(new Error("Agreed total should not less than booking amount"));
                      }
                      return Promise.resolve();
                    }
                  })
                ]}
              >
                <InputNumber
                  className="w-100"
                  min={0}
                  size="large"
                  placeholder={TechBaseText({ content: "{{AGREED_TOTAL}}" })}
                />
              </Form.Item>
            </Col>
            <Col xs={24} md={12}>
              <Form.Item
                name="receipt"
                label={<TechBaseText content="{{PROOF_OF_PAYMENT}}" />}
              // valuePropName="fileList"
              // getValueFromEvent={(e) => {
              //   if (Array.isArray(e)) {
              //     return e;
              //   }
              //   return e?.fileList;
              // }}
              >
                <TechBaseFilePond acceptedFileTypes={["image/*"]} />
              </Form.Item>
            </Col>
          </Row>
        </Card>
        <div className="techbase-button-wrapper-modal">
          <TechBaseButton className="techbase-button-modal" htmlType="submit" color="primary">
            <TechBaseText content="{{SUBMIT}}" />
          </TechBaseButton>
        </div>
      </Row>
    </Form>
  );
};

export default BookingForm;
