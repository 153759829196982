import React from 'react'
import { Avatar } from 'antd'
import TechBaseIcon from 'DisplayCore/Icon'
import { getSelectedLanguageContent, handleNestedObjectKey } from 'utils'

const TechBaseAvatar = props => {
  let {
    size,
    style,
    antIcon,
    content,
    dataKey
  } = props.item

  return(
    <Avatar
      size={ size }
      style={ style }
      icon={ antIcon && <TechBaseIcon { ...props }/>}
      src={ dataKey && handleNestedObjectKey( props, dataKey )}
    >
      { getSelectedLanguageContent( content, props.selectedLanguage )}
    </Avatar>
  )
}

export default TechBaseAvatar
