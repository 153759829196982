import React from 'react'
import { Tabs } from 'antd'
import { checkIfComponentHidden } from 'utils'

import CoreRenderFunction from "DisplayCore"

const TechBaseTab = props => {
  let {
    items,
    defaultActiveKey,
    actionKey
  } = props.item

  return(
    <Tabs 
      defaultActiveKey={ defaultActiveKey || '0' }
      onTabClick={ key => {
        if ( typeof props.onClickAction === 'function' ) {
          props.onClickAction( actionKey, parseInt( key ))
        }
      }}
      items={ 
        items.map( ( val, index ) => {
          if( checkIfComponentHidden( props.hideComponent, val ) ){
            return
          }
          return {
            key: String( index ),
            label: <CoreRenderFunction { ...props } item={ val.label }/>,
            children: <CoreRenderFunction { ...props } item={ val.children }/>
          }
        }) 
      } />
  )
}

export default TechBaseTab