import React from 'react'
import { Divider } from 'antd'
import _ from 'lodash'

import { applyColor } from 'utils/apply-color'
import CoreRenderFunction from 'DisplayCore'

const TechBaseDivider = props => {
  let {
    style,
    className,
    orientation,
    content
  } = props.item
  return(
    <Divider
      orientation={ orientation }
      style={ applyColor( style ) }
      className={ className }>
      { !_.isEmpty( content ) && <CoreRenderFunction {...props} item={ content }/> }
    </Divider>
  )
}

export default TechBaseDivider