import React, { Component } from "react";
import { connect } from "react-redux";
import _ from "lodash";

import { Post } from "utils/axios";
import { handleErrorMsg } from "utils";
import { requestError } from "utils/requestHandler";
import { addLoadingContent, removeLoadingContent } from "reducers/LoadingOverlay";

const HOC = (WrapperComponent) => {
  class WithHOC extends Component {
    state = {
      token: "",
      step: ""
    };
    load = (param) => {
      if (param) {
        this.props.addLoadingContent();
      } else {
        this.props.removeLoadingContent();
      }
    };
    onChangeHOC = (key, value) => this.setState({ [key]: value });

    onSubmitForm = (formData) => {
      if (_.isEmpty(this.state.token)) {
        this.forgetPassword(formData.username);
      } else {
        this.changePassword(formData.password);
      }
    };

    forgetPassword = (username) =>
      Post(
        `/forgetPassword`,
        { username: username },
        this.forgetPasswordSuccess,
        this.forgetPasswordError,
        this.load,
        true
      );
    forgetPasswordSuccess = () => {
      this.setState({ step: "done" });
    };
    forgetPasswordError = (error) => requestError(handleErrorMsg(error));

    verifyToken = (token) =>
      Post(
        `/verifyForgetPassword`,
        { token: token },
        () => this.verifyTokenSuccess(token),
        this.verifyTokenError,
        this.load,
        true
      );
    verifyTokenSuccess = (token) => {
      console.log(token);
      this.setState({ token: token });
    };
    verifyTokenError = (error) => {
      console.log(error);
      this.setState({ step: "error" });
    };

    changePassword = (password) =>
      Post(
        `/forgetPasswordChange`,
        { token: this.state.token, password: password },
        this.changePasswordSuccess,
        this.changePasswordError,
        this.load,
        true
      );
    changePasswordSuccess = () => {
      this.setState({ step: "done" });
    };
    changePasswordError = (error) => requestError(handleErrorMsg(error));

    render = () => {
      return (
        <WrapperComponent
          {...this.props}
          {...this.state}
          onChangeHOC={this.onChangeHOC}
          onSubmitForm={this.onSubmitForm}
          verifyToken={this.verifyToken}
        />
      );
    };
  }

  const mapStateToProps = (state) => ({
    data: state
  });

  return connect(mapStateToProps, { addLoadingContent, removeLoadingContent })(WithHOC);
};

export default HOC;
