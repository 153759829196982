import React from "react";
import _ from "lodash";
import { Collapse, Form, Input, DatePicker, Select, Row, Col, Space } from "antd";

import TechBaseIcon from "DisplayCoreV2/Icon";
import TechBaseText from "DisplayCoreV2/Text";
import TechBaseButton from "DisplayCoreV2/Button";
import MemberFinder from "./MemberFinder";

const { Panel } = Collapse;

const TechBaseFilter = (props) => {
  const { fields, onFinish, onReset } = props;
  const [form] = Form.useForm();

  return (
    <Collapse className="techbase-dropdown mb-3 w-100" defaultActiveKey={["1"]}>
      <Panel
        showArrow={false}
        header={
          <div className="d-flex filter-header">
            <TechBaseIcon antIcon="FilterOutlined" />
            <TechBaseText content="{{FILTER_BY}}" />
          </div>
        }
        key="1"
      >
        <Form
          form={form}
          layout="vertical"
          onFinish={onFinish}
          initialValues={_.reduce(
            fields,
            (result, current) => {
              return _.merge(result, { [current.name]: current.default });
            },
            {}
          )}
        >
          <Row gutter={32}>
            {fields.map((field) => {
              let component = <></>;
              switch (field.type) {
                case "input":
                  component = <Input allowClear size={field.size || "large"} placeholder={field.placeholder} />;
                  break;
                case "select":
                  component = (
                    <Select
                      allowClear
                      mode={field.mode}
                      showSearch={field.showSearch}
                      size={field.size || "large"}
                      options={field.options || []}
                      fieldNames={field.fieldNames}
                      optionRender={field.optionRender}
                      labelRender={field.labelRender}
                      filterOption={field.filterOption}
                    />
                  );
                  break;
                case "date-picker":
                  component = <DatePicker className="w-100" size={field.size || "large"} />;
                  break;
                case "member-finder":
                  component = <MemberFinder role={field.role} placeholder={field.placeholder} />;
                  break;
                default:
              }
              return (
                <Col xs={field.xs ?? 24} sm={field.sm ?? 12} md={field.md ?? 12} lg={field.lg ?? 8} xl={field.xl ?? 8}>
                  <Form.Item label={<TechBaseText content={field.label} />} name={field.name}>
                    {component}
                  </Form.Item>
                </Col>
              );
            })}
          </Row>


          <Row className="w-100 d-flex footer-btn">
            <Col >
              <TechBaseButton

                size="large"
                onClick={() => {
                  form.resetFields();
                  onReset();
                }}
              >
                <TechBaseText content="{{RESET}}" />
              </TechBaseButton>
            </Col>
            <Col >
              <TechBaseButton size="large" htmlType="submit" color="primary">
                <TechBaseText content="{{SEARCH}}" />
              </TechBaseButton>
            </Col>
          </Row>


        </Form>
      </Panel>
    </Collapse>
  );
};

export default TechBaseFilter;
