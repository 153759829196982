import React from 'react'
import { Descriptions } from 'antd'

const TechBaseDescriptions = props => {
  let {
    title,
    listItem,
    layout,
    column
  } = props.item

  return(
    <Descriptions
      title={ title }
      layout={ layout }
      column={ column }
    >
      {
        listItem && props[ listItem ] && props[ listItem ].map( item => {
          return (
            <Descriptions.Item label={ item.label }>
              { item.children }
            </Descriptions.Item>
          )
        })
      }
    </Descriptions>
  )
}

export default TechBaseDescriptions
