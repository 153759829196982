import { combineReducers } from "redux"

import ThemeOptions from "./ThemeOptions"
import AjaxReducer from "./Ajax"
import AuthReducer from "./Auth"
import ProfileReducer from "./profile"
import ClientReducer from "./client"
import ShopCartReducer from "./ShopCart"
import LoadingOverlayReducer from "./LoadingOverlay"

export default combineReducers({
  AjaxReducer,
  ThemeOptions,
  AuthReducer,
  ProfileReducer,
  ClientReducer,
  ShopCartReducer,
  LoadingOverlayReducer
})
