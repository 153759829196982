import React from 'react'

import CoreRenderFunction from "DisplayCore"
import { handleNestedObjectKey } from 'utils'
import _ from 'lodash'

const BoardList = props => {
  let {
    dataKey,
    template
  } = props.item
  
  return !_.isEmpty( handleNestedObjectKey( props, dataKey ))
    ? handleNestedObjectKey( props, dataKey )?.map(( val, index ) => {
        if( template[ index + 1 ]) {
          return(
            <CoreRenderFunction { ...props } item={ template[ index + 1 ]} listItem={ val }/>
          )
        } else {
          return(
            <CoreRenderFunction { ...props } item={ template.item } listItem={ val }/>
          )
        }
      })
    : <div className='d-flex align-items-center'>No Data</div>
}

export default BoardList
