import React, { useEffect } from "react";
import _ from "lodash";

import WithHOC from "./action";
import { Card, Form, Input } from "antd";
import TechBaseImage from "DisplayCoreV2/Image";
import TechBaseText from "DisplayCoreV2/Text";
import TechBaseButton from "DisplayCoreV2/Button";
import { EyeInvisibleOutlined, EyeTwoTone } from "@ant-design/icons";
import TechBaseResult from "DisplayCoreV2/Result";

const requiredRules = [
  {
    required: true,
    message: <TechBaseText content="{{REQUIRED_MESSAGE}}" />
  }
];

const ForgotPassword = (props) => {
  const { step, onSubmitForm, verifyToken, onChangeHOC } = props;
  const hasToken = window.location.hash.includes("TokenID=");

  useEffect(() => {
    if (hasToken) {
      verifyToken(window.location.hash.split("TokenID=")[1]);
      onChangeHOC("step", "");
    }
  }, [hasToken]);

  return (
    <div className="w-100 h-100 d-flex justify-content-center align-items-center">
      <Card className="p-4" style={{ backgroundColor: "#00000070", width: "500px" }}>
        <div className="text-center mb-5">
          <TechBaseImage style={{ width: "200px" }} />
        </div>
        {step === "" && (
          <Form onFinish={onSubmitForm} validateTrigger="onSubmit" layout="vertical">
            <div className="mb-4">
              {hasToken ? (
                <>
                  <TechBaseText Tag="h6" className="font-weight-bold mb-4" content="{{RESET_PASSWORD}}" />
                  <Form.Item
                    name="password"
                    label={<TechBaseText content="{{PASSWORD}}" />}
                    rules={[
                      {
                        required: true,
                        message: <TechBaseText content="{{PLEASE_ENTER_YOUR}} {{PASSWORD}}" />
                      },
                      {
                        max: 16,
                        message: <TechBaseText content="{{SHOULD_NOT_EXCEED}} 16 {{CHARACTERS}}" />
                      },
                      {
                        min: 8,
                        message: <TechBaseText content="{{SHOULD_NOT_LESS_THAN}} 8 {{CHARACTERS}}" />
                      }
                    ]}
                  >
                    <Input.Password
                      size="large"
                      iconRender={(visible) => (visible ? <EyeTwoTone /> : <EyeInvisibleOutlined />)}
                    />
                  </Form.Item>
                  <Form.Item
                    name="password_confirmation"
                    dependencies={["password"]}
                    label={<TechBaseText content="{{CONFIRM_PASSWORD}}" />}
                    rules={[
                      {
                        required: true,
                        message: <TechBaseText content="{{PLEASE_ENTER_YOUR}} {{CONFIRM_PASSWORD}}" />
                      },
                      ({ getFieldValue }) => ({
                        validator(_, value) {
                          if (!value || getFieldValue("password") === value) {
                            return Promise.resolve();
                          }
                          return Promise.reject(new Error(TechBaseText({ content: "{{PASSWORD_DO_NOT_MATCH}}" })));
                        }
                      })
                    ]}
                  >
                    <Input.Password
                      size="large"
                      iconRender={(visible) => (visible ? <EyeTwoTone /> : <EyeInvisibleOutlined />)}
                    />
                  </Form.Item>
                </>
              ) : (
                <>
                  <TechBaseText Tag="h6" className="font-weight-bold" content="{{FORGOT_PASSWORD}}" />
                  <div className="mb-4">
                    <TechBaseText content="{{ENTER_USERNAME_MSG}}" />
                  </div>
                  <Form.Item name="username" label={<TechBaseText content="{{USERNAME}}" />} rules={requiredRules}>
                    <Input size="large" placeholder={TechBaseText({ content: "{{USERNAME}}" })} />
                  </Form.Item>
                </>
              )}
            </div>
            <div className="d-flex" style={{ gap: "8px" }}>
              <TechBaseButton href="/login" size="large" className="w-100" color="primary">
                <TechBaseText content="{{BACK_TO_LOGIN_PAGE}}" />
              </TechBaseButton>
              <TechBaseButton htmlType="submit" size="large" className="w-100" color="quaternary">
                <TechBaseText content="{{SUBMIT}}" />
              </TechBaseButton>
            </div>
          </Form>
        )}
        {step === "done" && (
          <div>
            {hasToken ? (
              <TechBaseResult
                status="success"
                className="p-2"
                title={<TechBaseText content="{{SUCCESSFUL}}" />}
                subTitle={<TechBaseText content="{{PASSWORD_HAS_BEEN_RESET}}" />}
                extra={[
                  <TechBaseButton href="/login" size="large" className="w-100" color="primary">
                    <TechBaseText content="{{BACK_TO_LOGIN_PAGE}}" />
                  </TechBaseButton>
                ]}
              />
            ) : (
              <TechBaseResult
                status="success"
                title={<TechBaseText content="{{SUCCESSFUL}}" />}
                subTitle={<TechBaseText content="{{VERIFICATION_EMAIL_HAS_BEEN_SENT}}" />}
              />
            )}
          </div>
        )}
        {step === "error" && hasToken && (
          <TechBaseResult
            status="error"
            className="p-2"
            title={<TechBaseText content="{{ERROR}}" />}
            subTitle={<TechBaseText content="{{INVALID_TOKEN_MSG}}" />}
            extra={[
              <div className="d-flex" style={{ gap: "8px" }}>
                <TechBaseButton href="/login" size="large" className="w-100" color="primary">
                  <TechBaseText content="{{BACK_TO_LOGIN_PAGE}}" />
                </TechBaseButton>
                <TechBaseButton
                  href="/forgot-password"
                  onClick={() => {
                    onChangeHOC("step", "");
                  }}
                  size="large"
                  className="w-100"
                  color="primary"
                >
                  <TechBaseText content="{{TRY_AGAIN}}" />
                </TechBaseButton>
              </div>
            ]}
          />
        )}
      </Card>
    </div>
  );
};

export default WithHOC(ForgotPassword);
