import React, { useEffect, useState, forwardRef, useImperativeHandle } from 'react'
import { RedoOutlined } from '@ant-design/icons'
import _ from 'lodash'

import Captcha from "utils/captcha"

const TechBaseCaptcha = ( props, ref ) => {
  let{
    style,
    imageStyle,
    height,
    width,
    canRefresh
  } = props.item

  const [ captcha, setCaptcha ] = useState({})

  useEffect( () => {
    createCaptcha()
  }, [])
  
  const createCaptcha = () => {
    var newCaptcha = Captcha()
    newCaptcha.height = height
    newCaptcha.width = width
    setCaptcha( newCaptcha )
  }

  useImperativeHandle(ref, () => ({
    validateCaptcha(val){
      if( captcha.value === val ){
        return true
      } else {
        createCaptcha()
        return false
      }
    }
  }))

  if( !_.isEmpty( captcha ) ){
    return(
      <div
        style={{ ...style, backgroundColor: "#F6F1EB" }} >
        <img
          style={ imageStyle }
          src={captcha.image} 
          height={captcha.height} 
          width={captcha.width} />
        {
          canRefresh && (
            <RedoOutlined onClick={ createCaptcha } className="pr-2" style={{ color: "#bbbbbb" }}/>
          )
        }
      </div>
    )
  } else {
    return <></>
  }
}

export default forwardRef( TechBaseCaptcha )