import { Modal, Table, Input, Typography } from "antd";
import React, { useState, useEffect } from "react";
import _ from "lodash";

import TechBaseButton from "DisplayCoreV2/Button";

import WithHOC from "./actions";
import TechBaseText from "DisplayCoreV2/Text";

const { Text } = Typography;
const { Search } = Input;

const columns = [
  {
    title: "Code",
    key: "Id",
    render: (text, original) => original.MemberId ?? original.MemberID,
  },
  {
    title: "Name",
    dataIndex: "Fullname",
    key: "Fullname",
  },
  {
    title: "IC/Passport",
    dataIndex: "IC",
    key: "IC",
  },
];

const TechbaseMemberFinder = (props) => {
  const {
    size,
    role,
    error,
    value,
    profile,
    onChange,
    memberList,
    totalMembers,
    placeholder,
    getMemberList,
    getCustomerList,
    getMemberDetails,
    userCapacity,
  } = props;
  const [timer, setTimer] = useState(null);
  const [showModal, setShowModal] = useState(false);
  const [searchValue, setSearchValue] = useState("");
  const [query, setQuery] = useState({
    role: role,
    pageNumber: 1,
    pageSize: 10,
  });
  useEffect(() => {
    if (role === "C") {
      getCustomerList(query);
    } else if (role === "M") {
      if (profile.Role === "AD") {
        getMemberList(query);
      } else {
        onChange(profile);
      }
    } else {
      if (profile.Role === "AD") {
        getMemberList(query);
      } else {
        getCustomerList(query);
      }
    }
  }, [query]);

  useEffect(() => {
    if (!_.isEmpty(value)) {
      if (typeof value === "string") {
        getMemberDetails(value);
      } else {
        setSearchValue(value.MemberId);
      }
    }
  }, [value]);

  const onValuesChange = (value) => {
    if (timer) {
      clearTimeout(timer);
    }
    const newTimer = setTimeout(() => {
      getMemberDetails(value);
    }, 500);

    setTimer(newTimer);
  };

  return (
    <>
      <Search
        allowClear
        size={size}
        placeholder={placeholder}
        value={searchValue}
        disabled={props.disabledSearch}
        onChange={(e) => {
          setSearchValue(e.target.value);
          if (e.target.value) {
            onValuesChange(e.target.value);
          }
        }}
        {..._.omit(props, ["value", "onChange", "onSearch", "extra"])}
        onSearch={(val, target, action) => {
          if (action.source === "input") {
            setShowModal(true);
          } else if (action.source === "clear") {
            setSearchValue("");
            onChange(undefined);
          }
        }}
      />
      {!_.isEmpty(value) && (
        <div>
          <TechBaseText content="{{SELECTED_MEMBER}}" />:{" "}
          {value.Fullname ?? `${value.FirstName} ${value.LastName}`}
        </div>
      )}
      {error && (
        <div>
          <Text type="danger">{error}</Text>
        </div>
      )}
      <Modal
        open={showModal}
        footer={null}
        onCancel={() => setShowModal(false)}
        title={<TechBaseText content="{{MEMBER_FINDER}}" />}
      >
        <Table
          columns={columns}
          dataSource={memberList}
          onRow={(record) => {
            return {
              onClick: () => {
                onChange(record);
                setShowModal(false);
              },
            };
          }}
          pagination={{
            current: query.pageNumber,
            pageSize: query.pageSize,
            total: totalMembers,
            showSizeChanger: true,
            onChange: (page, pageSize) =>
              setQuery({ ...query, pageNumber: page, pageSize: pageSize }),
          }}
        />
        <div className="techbase-button-wrapper-modal">
          <TechBaseButton
            className="techbase-button-modal"
            onClick={() => setShowModal(false)}
          >
            <TechBaseText content="{{CLOSE}}" />
          </TechBaseButton>
        </div>
      </Modal>
    </>
  );
};

export default WithHOC(TechbaseMemberFinder);
