import html2pdf from "html2pdf.js";
import Handlebars from "handlebars";

export const PDFGenerator = async (fileSrc, data, pageType = 'a4') => {
  const response = await fetch(fileSrc);
  const templateSource = await response.text();
  var template = Handlebars.compile(templateSource);

  Handlebars.registerHelper("inc", function (value, options) {
    return parseInt(value) + 1;
  });
  Handlebars.registerHelper("ifEquals", function (arg1, arg2, options) {
    return arg1 === arg2 ? options.fn(this) : options.inverse(this);
  });
  Handlebars.registerHelper("ifGreater", function (arg1, arg2, options) {
    return arg1 > arg2 ? options.fn(this) : options.inverse(this);
  });

  const opt = {
    margin: 8,
    filename: 'document.pdf',
    image: { type: 'jpeg', quality: 5 },
    html2canvas: {
      dpi: 1000,
      scale: 4,
      letterRendering: true,
      useCORS: true
    },
    jsPDF: { unit: 'mm', format: pageType, orientation: 'portrait' }
  };

  var htmlData = template(data);
  html2pdf().from(htmlData).set(opt).outputPdf('blob').then((pdfBlob) => {
    const pdfUrl = URL.createObjectURL(pdfBlob);
    window.open(pdfUrl, '_blank');
  });
};
