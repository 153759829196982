import React, { useEffect } from "react";
import qs from "qs";
import _ from "lodash";
import dayjs from "dayjs";
import moment from "moment";
import { useLocation } from "react-router-dom";
import {
  Input,
  Form,
  DatePicker,
  Checkbox,
  Radio,
  Row,
  Col,
  Select,
  Space,
  AutoComplete,
} from "antd";

import TechBaseCard from "DisplayCoreV2/Card";
import TechBaseText from "DisplayCoreV2/Text";
import TechBaseFilePond from "DisplayCoreV2/FilePond";

import WithHOC from "./actions";
import "./index.scss";
import TechBaseButton from "DisplayCoreV2/Button";
import { convertBase64ToObject } from "utils/objToBase64";
import { requestError } from "utils/requestHandler";
import { isOver18YearsOld, validateMyKad } from "utils";

const requiredRules = [
  {
    required: true,
    message: <TechBaseText content="{{REQUIRED_MESSAGE}}" />,
  },
];

const Registration = (props) => {
  const location = useLocation();
  const [form] = Form.useForm();
  const country = Form.useWatch(["residentialAddress", "country"], form);
  const identityType = Form.useWatch("identityType", form);
  const identityNo = Form.useWatch("identityNo", form);
  const {
    history,

    sponsor,
    bankOptions,
    stateOptions,
    genderOptions,
    countryOptions,
    paymentOptions,
    documentOptions,
    decisionOptions,
    maritalStatusOptions,
    registrationFee,

    getSponsor,
    getRegistrationFee,
    getCountryOptions,
    getParamOptions,
    getStateOptions,
    getBankOptions,
    registerMember,
  } = props;

  useEffect(() => {
    getBankOptions();
    getStateOptions();
    getCountryOptions();
    getRegistrationFee();
    getParamOptions("paymentOptions", "PaymentType");
    getParamOptions("documentOptions", "IdentityType");
    getParamOptions("maritalStatusOptions", "Marriage");
  }, []);

  useEffect(() => {
    const param = qs.parse(location.search, { ignoreQueryPrefix: true }).query;
    if (param) {
      try {
        const details = convertBase64ToObject(param);
        getSponsor(details.sponsor);
      } catch (error) {
        requestError(
          TechBaseText({ content: "{{REGISTRATION_LINK_INVALID}}" })
        );
        history.push("/login");
      }
    } else {
      getSponsor("000001");
    }
  }, []);

  useEffect(() => {
    if (!_.isEmpty(sponsor)) {
      form.setFieldsValue({
        sponsorID: sponsor.MemberId,
        sponsorName: sponsor.Fullname,
      });
    }
  }, [sponsor]);

  useEffect(() => {
    if (
      !_.find(stateOptions, {
        State_Country: country,
        State_Status: 1,
        ID: form.getFieldValue(["residentialAddress", "state"]),
      })
    ) {
      form.setFieldValue(["residentialAddress", "state"], undefined);
    }
  }, [country]);

  useEffect(() => {
    if (validateMyKad(identityNo)) {
      if (identityNo[11] % 2 === 0) {
        form.setFieldsValue({
          gender: "F",
        });
      } else {
        form.setFieldsValue({
          gender: "M",
        });
      }
      let year = parseInt(identityNo.slice(0, 2));
      const month = identityNo.slice(2, 4);
      const day = identityNo.slice(4, 6);
      if (moment().format("YY") - year < 0) {
        year = `19${year}`;
      } else {
        year < 10 ? (year = `200${year}`) : (year = `20${year}`);
      }
      const birthDate = dayjs(`${year}-${month}-${day}`);
      if (birthDate.isValid()) {
        form.setFieldsValue({
          DOB: birthDate,
        });
      }
    }
  }, [identityNo]);

  const onFinish = (formData) => {
    let data = _.cloneDeep(formData);
    data.role = "M";
    data.displayName = formData.firstName; //hardcoded
    data.username = formData.identityNo; //hardcoded
    data.password = formData.identityNo.slice(-6); //hardcoded
    data.DOB = moment(formData.DOB.toString()).format("YYYY-MM-DD");
    data.isKYC = false;
    data.status = "A";
    data.ICBack = formData.ICBack.base64;
    data.ICFront = formData.ICFront.base64;
    data.receipt = formData.receipt ? formData.receipt.base64 : "";
    data.mailingAddress = _.cloneDeep(formData.residentialAddress);
    if (_.isEmpty(data.mobile2)) {
      delete data.mobileCode2;
      delete data.mobile2;
    }
    registerMember(data);
  };

  return (
    <div className="registration-form-container">
      <TechBaseText
        Tag={"h4"}
        className="font-weight-bold"
        content="{{AGENT_REGISTRATION}}"
      />
      <Form
        scrollToFirstError
        onFinish={onFinish}
        layout="vertical"
        form={form}
        initialValues={{
          country: 127,
          nationality: 127,
          mobileCode: "6",
          mobileCode2: "6",
          identityType: "IC",
        }}
      >
        {/* Referral Details */}
        <TechBaseCard
          title={
            <TechBaseText
              Tag={"h6"}
              className="font-weight-bold"
              style={{ color: "!primary" }}
              content="{{REFERRAL_DETAIL}}"
            />
          }
          headStyle={{
            backgroundColor: "$primary",
            boxShadow: "0 0 10px #aaaaaa",
          }}
        >
          <Row gutter={32}>
            <Col xs={24} md={12}>
              <Form.Item
                name="sponsorID"
                label={<TechBaseText content="{{USERNAME}}" />}
              // rules={requiredRules}
              >
                <Input size="large" disabled />
              </Form.Item>
            </Col>
            <Col xs={24} md={12}>
              <Form.Item
                name="sponsorName"
                label={<TechBaseText content="{{FULL_NAME}}" />}
              // rules={requiredRules}
              >
                <Input size="large" disabled />
              </Form.Item>
            </Col>
          </Row>
        </TechBaseCard>

        {/* Personal Information */}
        <TechBaseCard
          title={
            <TechBaseText
              Tag={"h6"}
              className="font-weight-bold"
              style={{ color: "!primary" }}
              content="{{PERSONAL_INFORMATION}}"
            />
          }
          style={{ marginTop: "-15px" }}
          headStyle={{
            backgroundColor: "$primary",
            boxShadow: "0 0 10px #aaaaaa",
          }}
        >
          <Row gutter={32}>
            <Col xs={24} md={12}>
              <Form.Item
                name="firstName"
                label={<TechBaseText content="{{FULL_NAME_AS_PER_IC}}" />}
                rules={requiredRules}
              >
                <Input
                  size="large"
                  placeholder={TechBaseText({
                    content: "{{FULL_NAME_AS_PER_IC}}",
                  })}
                />
              </Form.Item>
            </Col>
            {/* <Col xs={24} md={12}>
              <Form.Item name="lastName" label={<TechBaseText content="{{LAST_NAME}}" />} rules={requiredRules}>
                <Input size="large" placeholder={TechBaseText({ content: "{{LAST_NAME}}" })} />
              </Form.Item>
            </Col> */}
            <Col xs={24} md={12}>
              <Row gutter={24} className="align-items-end">
                <Col xs={8}>
                  <Form.Item
                    name="mobileCode"
                    rules={requiredRules}
                    label={<TechBaseText content="{{MOBILE}}" />}
                  >
                    <Select
                      showSearch
                      size="large"
                      options={_.filter(
                        countryOptions,
                        (val) => !_.isEmpty(val.MobileCode) && val.AllowRegister
                      )}
                      fieldNames={{ label: "MobileCode", value: "MobileCode" }}
                      optionRender={(option) =>
                        `${option.data.Country_Name} (+${option.data.MobileCode})`
                      }
                      labelRender={(val) => {
                        const selected = _.find(countryOptions, {
                          MobileCode: val.value,
                        });
                        return `${selected?.Country_Name} (+${selected?.MobileCode})`;
                      }}
                      filterOption={(input, option) => {
                        return `${option.Country_Name} (+${option.MobileCode})`
                          .toLowerCase()
                          .includes(input.toLowerCase());
                      }}
                    />
                  </Form.Item>
                </Col>
                <Col xs={16}>
                  <Form.Item
                    name="mobile"
                    rules={[
                      { requiredRules },
                      {
                        min: 10,
                        max: 11,
                      },
                    ]}
                  >
                    <Input size="large" placeholder="149545789" />
                  </Form.Item>
                </Col>
              </Row>
            </Col>
            <Col xs={24} md={12}>
              <Row gutter={24} className="align-items-end">
                <Col xs={8}>
                  <Form.Item
                    name="mobileCode2"
                    label={
                      <TechBaseText
                        content="{{MOBILE_NO_2}}"
                        rules={requiredRules}
                      />
                    }
                  >
                    <Select
                      showSearch
                      size="large"
                      options={_.filter(
                        countryOptions,
                        (val) => !_.isEmpty(val.MobileCode) && val.AllowRegister
                      )}
                      fieldNames={{ label: "MobileCode", value: "MobileCode" }}
                      optionRender={(option) =>
                        `${option.data.Country_Name} (+${option.data.MobileCode})`
                      }
                      labelRender={(val) => {
                        const selected = _.find(countryOptions, {
                          MobileCode: val.value,
                        });
                        return `${selected?.Country_Name} (+${selected?.MobileCode})`;
                      }}
                      filterOption={(input, option) => {
                        return `${option.Country_Name} (+${option.MobileCode})`
                          .toLowerCase()
                          .includes(input.toLowerCase());
                      }}
                    />
                  </Form.Item>
                </Col>
                <Col xs={16}>
                  <Form.Item
                    name="mobile2"
                    rules={[
                      {
                        min: 10,
                        max: 11,
                      },
                    ]}
                  >
                    <Input size="large" placeholder="149545789" />
                  </Form.Item>
                </Col>
              </Row>
            </Col>
            <Col xs={24} md={12}>
              <Form.Item
                name="email"
                label={<TechBaseText content="{{EMAIL}}" />}
                rules={[
                  // ...requiredRules,
                  {
                    type: "email",
                    message: <TechBaseText content="{{EMAIL_IS_INVALID}}" />,
                  },
                ]}
              >
                <Input size="large" placeholder="Email" />
              </Form.Item>
            </Col>
            <Col xs={24} md={12}>
              <Form.Item
                name="nationality"
                label={<TechBaseText content="{{NATIONALITY}}" />}
                rules={requiredRules}
              >
                <Select
                  size="large"
                  options={_.filter(countryOptions, (val) => val.AllowRegister)}
                  fieldNames={{ label: "Country_Name", value: "ID" }}
                />
              </Form.Item>
            </Col>
            <Col xs={24} md={12}>
              <Row gutter={24} className="align-items-start">
                <Col xs={8}>
                  <Form.Item
                    name="identityType"
                    rules={requiredRules}
                    label={<TechBaseText content="{{IDENTITY_TYPE}}" />}
                  >
                    <Select
                      disabled
                      size="large"
                      options={documentOptions}
                      fieldNames={{
                        label: "ParameterName",
                        value: "ParameterValue",
                      }}
                    />
                  </Form.Item>
                </Col>
                <Col xs={16}>
                  <Form.Item
                    validateFirst
                    name="identityNo"
                    rules={[
                      ...requiredRules,
                      {
                        validator: (_, value) =>
                          validateMyKad(value) || identityType !== "IC"
                            ? Promise.resolve()
                            : Promise.reject(
                              new Error(
                                TechBaseText({
                                  content: "{{INVALID_IC_FORMAT}}",
                                })
                              )
                            ),
                      },
                    ]}
                    extra={<TechBaseText content="PW_IS_IC" />}
                    label={
                      <TechBaseText content="{{IDENTITY_REGISTRATION_NO}}" />
                    }
                  >
                    <Input
                      size="large"
                      placeholder={TechBaseText({
                        content: "{{IDENTITY_REGISTRATION_NO}}",
                      })}
                    />
                  </Form.Item>
                </Col>
              </Row>
            </Col>
            <Col xs={24} md={12}>
              <Form.Item
                name="gender"
                label={<TechBaseText content="{{GENDER}}" />}
              // rules={requiredRules}
              >
                <Select
                  size="large"
                  options={genderOptions}
                  placeholder={
                    <TechBaseText content="{{PLEASE_SELECT_A}} {{GENDER}}" />
                  }
                />
              </Form.Item>
            </Col>
            <Col xs={24} md={12}>
              <Form.Item
                name="DOB"
                label={<TechBaseText content="{{DATE_OF_BIRTH}}" />}
                rules={[
                  // ...requiredRules,
                  {
                    validator: (_, value) =>
                      isOver18YearsOld(value)
                        ? Promise.resolve()
                        : Promise.reject(
                          new Error(
                            TechBaseText({
                              content: "{{REGISTER_AGE_RESTRICTION}}",
                            })
                          )
                        ),
                  },
                ]}
              >
                <DatePicker disabled className="w-100" size="large" />
              </Form.Item>
            </Col>
            <Col xs={24} md={12}>
              <Form.Item
                name="maritalStatus"
                label={<TechBaseText content="{{MARITAL_STATUS}}" />}
                rules={requiredRules}
              >
                <Select
                  size="large"
                  options={maritalStatusOptions}
                  fieldNames={{
                    label: "ParameterName",
                    value: "ParameterValue",
                  }}
                  placeholder={
                    <TechBaseText content="{{PLEASE_SELECT_A}} {{MARITAL_STATUS}}" />
                  }
                />
              </Form.Item>
            </Col>
          </Row>
        </TechBaseCard>

        {/* Background Check */}
        <TechBaseCard
          title={
            <TechBaseText
              Tag={"h6"}
              className="font-weight-bold"
              style={{ color: "!primary" }}
              content="{{BACKGROUND_CHECK}}"
            />
          }
          style={{ marginTop: "-15px" }}
          headStyle={{
            backgroundColor: "$primary",
            boxShadow: "0 0 10px #aaaaaa",
          }}
        >
          <Form.Item
            name="isCriminal"
            label={<TechBaseText content="{{CRIMINAL_RECORD}}" />}
          // rules={requiredRules}
          >
            <Radio.Group options={decisionOptions} />
          </Form.Item>
          <Form.Item
            name="isBankruptcy"
            label={<TechBaseText content="{{BANKRUPTCY}}" />}
          // rules={requiredRules}
          >
            <Radio.Group options={decisionOptions} />
          </Form.Item>
        </TechBaseCard>

        {/* Address Information */}
        <TechBaseCard
          title={
            <TechBaseText
              Tag={"h6"}
              className="font-weight-bold"
              style={{ color: "!primary" }}
            // content="{{ADDRESS_INFORMATION}}"
            />
          }
          style={{ marginTop: "-15px" }}
          headStyle={{
            backgroundColor: "$primary",
            boxShadow: "0 0 10px #aaaaaa",
          }}
        >
          <Row gutter={32}>
            <Col xs={24} md={12}>
              <Form.Item
                name={["residentialAddress", "addressOne"]}
                label={<TechBaseText content="{{ADDRESS_LINE_1}}" />}
              // rules={requiredRules}
              >
                <Input
                  size="large"
                  placeholder={TechBaseText({ content: "{{ADDRESS_LINE_1}}" })}
                />
              </Form.Item>
            </Col>
            <Col xs={24} md={12}>
              <Form.Item
                name={["residentialAddress", "addressTwo"]}
                label={<TechBaseText content="{{ADDRESS_LINE_2}}" />}
              >
                <Input
                  size="large"
                  placeholder={TechBaseText({ content: "{{ADDRESS_LINE_2}}" })}
                />
              </Form.Item>
            </Col>
            <Col xs={24} md={12}>
              <Form.Item
                name={["residentialAddress", "country"]}
                label={<TechBaseText content="{{COUNTRY}}" />}
              // rules={requiredRules}
              >
                <Select
                  size="large"
                  options={_.filter(countryOptions, (val) => val.AllowRegister)}
                  fieldNames={{ label: "Country_Name", value: "ID" }}
                  placeholder={
                    <TechBaseText content="{{PLEASE_SELECT_A}} {{COUNTRY}}" />
                  }
                />
              </Form.Item>
            </Col>
            <Col xs={24} md={12}>
              <Form.Item
                name={["residentialAddress", "postCode"]}
                label={<TechBaseText content="{{POSTCODE}}" />}
                rules={[
                  //  ...requiredRules,
                  {
                    len: 5,
                    message:
                      "Invalid postcode Format, Only 5-digit numerics allowed",
                  },
                ]}
              >
                <Input
                  size="large"
                  placeholder={TechBaseText({ content: "{{POSTCODE}}" })}
                />
              </Form.Item>
            </Col>
            <Col xs={24} md={12}>
              <Form.Item
                name={["residentialAddress", "state"]}
                label={<TechBaseText content="{{STATE}}" />}
              // rules={requiredRules}
              >
                <Select
                  size="large"
                  options={_.filter(stateOptions, {
                    State_Country: country,
                    State_Status: 1,
                  })}
                  fieldNames={{ label: "State_Name", value: "ID" }}
                  placeholder={
                    <TechBaseText content="{{PLEASE_SELECT}} {{STATE}}" />
                  }
                />
              </Form.Item>
            </Col>
            <Col xs={24} md={12}>
              <Form.Item
                name={["residentialAddress", "city"]}
                label={<TechBaseText content="{{CITY}}" />}
              // rules={requiredRules}
              >
                <Input
                  size="large"
                  placeholder={TechBaseText({ content: "{{CITY}}" })}
                />
              </Form.Item>
            </Col>
          </Row>
        </TechBaseCard>

        {/* Bank Information */}
        <TechBaseCard
          title={
            <TechBaseText
              Tag={"h6"}
              className="font-weight-bold"
              style={{ color: "!primary" }}
              content="{{MEMBER_BANK_INFORMATION}}"
            />
          }
          style={{ marginTop: "-15px" }}
          headStyle={{
            backgroundColor: "$primary",
            boxShadow: "0 0 10px #aaaaaa",
          }}
        >
          <Row gutter={32}>
            <Col xs={24} md={12}>
              <Form.Item
                name="bankCode"
                label={<TechBaseText content="{{BANK_NAME}}" />}
                rules={requiredRules}
              >
                <Select
                  size="large"
                  options={bankOptions}
                  fieldNames={{ label: "BankName", value: "BankCode" }}
                  placeholder={
                    <TechBaseText content="{{PLEASE_SELECT}} {{BANK_NAME}}" />
                  }
                />
              </Form.Item>
            </Col>
            <Col xs={24} md={12}>
              <Form.Item
                name="bankAccountNo"
                label={<TechBaseText content="{{BANK_ACCOUNT_NO}}" />}
                rules={requiredRules}
              >
                <Input
                  size="large"
                  placeholder={TechBaseText({ content: "{{BANK_ACCOUNT_NO}}" })}
                />
              </Form.Item>
            </Col>
            <Col xs={24} md={12}>
              <Form.Item
                name="bankAccountHolder"
                label={<TechBaseText content="{{HOLDER_NAME}}" />}
                rules={requiredRules}
              >
                <Input
                  size="large"
                  placeholder={TechBaseText({ content: "{{HOLDER_NAME}}" })}
                />
              </Form.Item>
            </Col>
          </Row>
        </TechBaseCard>

        {/* Identification Upload */}
        <TechBaseCard
          title={
            <TechBaseText
              Tag={"h6"}
              className="font-weight-bold"
              style={{ color: "!primary" }}
              content="{{UPLOAD_IDENTITY_CARD}}"
            />
          }
          style={{ marginTop: "-15px" }}
          headStyle={{
            backgroundColor: "$primary",
            boxShadow: "0 0 10px #aaaaaa",
          }}
        >
          <Row gutter={32}>
            <Col xs={24} md={12}>
              <Form.Item
                name="ICFront"
                label={<TechBaseText content="{{IC_FRONT}}" />}
                rules={requiredRules}
              >
                <TechBaseFilePond acceptedFileTypes={["image/*"]} />
              </Form.Item>
            </Col>
            <Col xs={24} md={12}>
              <Form.Item
                name="ICBack"
                label={<TechBaseText content="{{IC_BACK}}" />}
                rules={requiredRules}
              >
                <TechBaseFilePond acceptedFileTypes={["image/*"]} />
              </Form.Item>
            </Col>
          </Row>
        </TechBaseCard>

        {/* Payment Information */}
        <TechBaseCard
          title={
            <TechBaseText
              Tag={"h6"}
              className="font-weight-bold"
              style={{ color: "!primary" }}
              content={`{{REGISTRATION_FEE}} (RM ${registrationFee.toFixed(
                2
              )})`}
            />
          }
          style={{ marginTop: "-15px" }}
          headStyle={{
            backgroundColor: "$primary",
            boxShadow: "0 0 10px #aaaaaa",
          }}
        >
          <Row gutter={32}>
            <Col xs={24} md={12}>
              <Form.Item
                name="paymentType"
                label={<TechBaseText content="{{PAYMENT_TYPE}}" />}
                rules={requiredRules}
              >
                <Select
                  size="large"
                  options={_.filter(
                    paymentOptions,
                    (opt) =>
                      opt.Sort === 0 &&
                      !opt.ParameterName.toLowerCase().includes("wallet")
                  )}
                  fieldNames={{
                    label: "ParameterName",
                    value: "ParameterValue",
                  }}
                  placeholder={
                    <TechBaseText content="{{PLEASE_SELECT}} {{PAYMENT_TYPE}}" />
                  }
                />
              </Form.Item>
            </Col>
            <Col xs={24} md={12}>
              <Form.Item
                name="remarks"
                label={<TechBaseText content="{{REMARKS}}" />}
              >
                <Input
                  size="large"
                  placeholder={TechBaseText({ content: "{{REMARKS}}" })}
                />
              </Form.Item>
            </Col>
            <Col xs={24} md={12}>
              <Form.Item
                name="receipt"
                label={<TechBaseText content="{{PROOF_OF_PAYMENT}}" />}
              // rules={requiredRules}
              >
                <TechBaseFilePond acceptedFileTypes={["image/*"]} />
              </Form.Item>
            </Col>
          </Row>

          <Form.Item
            name="TNCAgreement"
            valuePropName="checked"
            rules={[
              {
                validator: (_, value) =>
                  value
                    ? Promise.resolve()
                    : Promise.reject(
                      new Error(
                        "Please read and accept the term and conditions to continue"
                      )
                    ),
              },
            ]}
          >
            <Checkbox>
              <TechBaseText content="{{PLEASE_READ_TERM_AND_CONDITIONS}}" />
            </Checkbox>
          </Form.Item>

          <Row className="w-100 d-flex footer-btn">
            <Col>
              <TechBaseButton size="large" href="/login">
                <TechBaseText content="{{BACK_TO_LOGIN_PAGE}}" />
              </TechBaseButton>
            </Col>

            <Col>
              <TechBaseButton size="large" htmlType="submit" color="primary">
                <TechBaseText content="{{REGISTER_NOW}}" />
              </TechBaseButton>
            </Col>
          </Row>
        </TechBaseCard>
      </Form>
    </div>
  );
};

export default WithHOC(Registration);
