import React from 'react'
import { Col } from 'antd'

import CoreRenderFunction from 'DisplayCore'
import { applyColor } from 'utils/apply-color'

const ZitronColumn = props => {
  let {
    style,
    children
  } = props.item
  return(
    <Col 
      { ...props.item }
      style={ applyColor( style, props.colors ) || {} }>
      {
        children?.map( child => {
          return <CoreRenderFunction { ...props } item={ child }/>
        })
      }
    </Col>
  )
}

export default ZitronColumn