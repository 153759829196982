import React from 'react'
import { Carousel } from 'antd'
import { LeftOutlined, RightOutlined } from '@ant-design/icons'
import CoreRenderFunction from 'DisplayCore'

import './index.scss'

const TechBaseCarousel = props => {
  let {
    configs = {},
    className,
    dataKey,
    template,
    prevArrow,
    nextArrow
  } = props.item
  return(
    <Carousel
      className={ className }
      autoplay={ configs.autoplay }
      autoplaySpeed={ configs.autoplaySpeed }
      dots={ configs.dots }
      dotPosition={ configs.dotPosition }
      arrows={ configs.arrows }
      prevArrow={ prevArrow || <LeftOutlined />}
      nextArrow={ nextArrow || <RightOutlined />}
    >
      {
        dataKey && props[ dataKey ] && props[ dataKey ].map( item => {
          return (
            <CoreRenderFunction
              {...props }
              item={ template }
              carouselData={ item }
              onClickAction={( actionKey ) => {
                props.onClickAction( actionKey, item.id )
              }}
            />
          )
        })
      }
    </Carousel>
  )
}

export default TechBaseCarousel