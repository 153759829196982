import React from "react"
import { Card, CardImg } from "react-bootstrap"
import { Row, Col, CardBody, CardHeader } from 'reactstrap'
import _ from 'lodash'

import CoreRenderFunction from 'DisplayCore'
import { applyColor } from 'utils/apply-color'
import { handleNestedObjectKey, checkIfComponentHidden } from 'utils'
import './index.scss'

const TechBaseCardList = props => {
  let {
    itemList,
    style,
    cardHeader,
    cardImage,
    cardBody,
    enableHover,
    overlay,
    isHideItem,
    isSelected,
    selectedOverlay,
    config={}
  } = props.item

  return(
    <Row className='px-2'>
      {
        props[ itemList ].map( data => {
          if( checkIfComponentHidden( props.hideComponent, { isHide: isHideItem }, data ) ){
            return <></>
          }
          return (
            <Col
              xs={ config.xs || 6 }
              md={ config.md || 4 }
              lg={ config.lg || 3 }
              className={ `${ config.colClassName ? config.colClassName : 'mb-5 col-12' }` } 
            >
              <Card style={ applyColor( style, props.colors )}>
                {
                  cardHeader && (
                    <CardHeader>
                      <CoreRenderFunction
                        { ...props }
                        item={ cardHeader }
                      />
                    </CardHeader>
                  )
                }
                {
                  cardImage && (
                    <CardImg
                      className={ cardImage.className || "" }
                      style={ cardImage.style || {} }
                      alt={ cardImage.altText }
                      src={ cardImage.src ? cardImage.src : handleNestedObjectKey( data, cardImage.dataKey ) || props.data.ClientReducer.clientInfo.logo_image_url }
                    />
                  )
                }
                {
                  cardBody && (
                    <CardBody style={ cardBody.style } >
                      {
                        cardBody.children && cardBody.children.map( child => {
                          return (
                            <CoreRenderFunction
                              { ...props }
                              item = { child }
                              listItem={ data }
                            />
                          )
                        })
                      }
                    </CardBody>
                  )
                }
                {
                  ( checkIfComponentHidden( props.hideComponent, { isHide: isSelected }, data ) || _.isEmpty( selectedOverlay ) ) && enableHover && (
                    <CoreRenderFunction
                      { ...props }
                      item={ overlay }
                      listItem={ data }
                    />
                  )
                }
                {
                  !checkIfComponentHidden( props.hideComponent, { isHide: isSelected }, data ) && !_.isEmpty( selectedOverlay ) && (
                    <CoreRenderFunction
                      { ...props }
                      item={ selectedOverlay }
                      listItem={ data }
                    />
                  )
                }
              </Card>
            </Col>
          )
        })
      }
    </Row>
  )
}

export default TechBaseCardList
