import React, { lazy } from "react";
import _ from "lodash";
import MonumentList from "containers/Dashboard/ProductsManagement/MonumentList";
import MonumentListing from "containers/Dashboard/Orders/MonumentListing";
import MonumentMall from "containers/Dashboard/Mall/Monument";


const MainContent = lazy(() => import("containers/Dashboard/MainContent"));
const Profile = lazy(() => import("containers/Dashboard/Users/Profile"));
const MemberList = lazy(() => import("containers/Dashboard/Users/MemberList"));
const PurchaserList = lazy(() => import("containers/Dashboard/Users/PurchaserList"));
const InstallmentApproval = lazy(() => import("containers/Dashboard/Approval/Installment"));
const BookingApproval = lazy(() => import("containers/Dashboard/Approval/Order"));
const MemberApproval = lazy(() => import("containers/Dashboard/Approval/Member"));
const RegistrationApproval = lazy(() => import("containers/Dashboard/Approval/Registration"));
const BookingList = lazy(() => import("containers/Dashboard/Bookings/BookingList"));
const Orders = lazy(() => import("containers/Dashboard/Orders/OrderListing"));
const Refund = lazy(() => import("containers/Dashboard/Orders/Refund"));
const Mall = lazy(() => import("containers/Dashboard/Mall/List"));
const UrnMall = lazy(() => import("containers/Dashboard/Mall/Urn"));
const ServiceMall = lazy(() => import("containers/Dashboard/Mall/Service"));
const MiscellaneousMall = lazy(() => import("containers/Dashboard/Mall/Miscellaneous"));
const SubadminList = lazy(() => import("containers/Dashboard/StaffSettings/SubadminList"));
const RolePermissions = lazy(() => import("containers/Dashboard/StaffSettings/RolePermissions"));
const TransactionHistories = lazy(() => import("containers/Dashboard/Orders/TransactionHistories"));
const SalesPayment = lazy(() => import("containers/Dashboard/Orders/SalesPayment"));
const LotEditor = lazy(() => import("containers/Dashboard/ProductsManagement/LotEditor"));
const LotList = lazy(() => import("containers/Dashboard/ProductsManagement/LotList"));
const FSPList = lazy(() => import("containers/Dashboard/ProductsManagement/FSPList"));
const Announcement = lazy(() => import("containers/Dashboard/Announcement"));
const AddAnnouncement = lazy(() => import("containers/Dashboard/Announcement/AddAnnouncements"));
const SponsorTree = lazy(() => import("containers/Dashboard/OrganisationChart/SponsorTreeV2"));
const ChangePassword = lazy(() => import("containers/Dashboard/Settings/ChangePassword"));
const ChangeSecurityPin = lazy(() => import("containers/Dashboard/Settings/ChangeSecurityPin"));
const Certification = lazy(() => import("containers/Dashboard/MemorialPanel/Certification"));
const FSPListing = lazy(() => import("containers/Dashboard/Orders/FSPListing"));
const MiscListing = lazy(() => import("containers/Dashboard/Orders/MiscListing"));
const EResourceCategories = lazy(() => import("containers/Dashboard/EResource/Category"));
const EResourceListing = lazy(() => import("containers/Dashboard/EResource/Listing"));


export const MainNav = [
  {
    module: "home",
    exact: true,
    module_user: ["AD", "M"],
    icon: "pe-7s-home",
    label: "DASHBOARD",
    to: "/dashboard/",
    render: (props) => <MainContent {...props} />
  },
  {
    module: "users",
    icon: "pe-7s-users",
    label: "USERS",
    content: [
      {
        exact: true,
        module: "profile",
        module_user: ["AD", "M"],
        module_name: "Profile",
        icon: "pe-7s-user",
        label: "PROFILE",
        to: "/dashboard/profile",
        render: (props) => <Profile {...props} />
      },
      {
        exact: true,
        module_user: ["AD"],
        module: "memberList",
        icon: "pe-7s-users",
        label: "AGENT_LIST",
        to: "/dashboard/member-list",
        render: (props) => <MemberList {...props} />
      },
      {
        exact: true,
        module: "purchaserList",
        module_user: ["AD"],
        icon: "pe-7s-users",
        label: "PURCHASER_LIST",
        to: "/dashboard/purchaser-list",
        render: (props) => <PurchaserList {...props} />
      },
      {
        exact: true,
        module: "organisationChart",
        module_user: ["AD", "M"],
        icon: "pe-7s-culture",
        label: "ORGANISATION_CHART",
        to: "/dashboard/sponsor_tree",
        render: (props) => <SponsorTree {...props} />
      }
    ]
  },
  {
    module: "productManagement",
    icon: "pe-7s-portfolio",
    label: "PRODUCTS_MANAGEMENT",
    content: [
      {
        exact: true,
        module: "lotEditor",
        module_user: ["AD"],
        icon: "pe-7s-shopbag",
        label: "LOTS_EDITOR",
        to: "/dashboard/products-management/lot-editor",
        render: (props) => <LotEditor {...props} />
      },
      {
        exact: true,
        module: "lotList",
        module_user: ["AD"],
        icon: "pe-7s-shopbag",
        label: "LOT_LIST",
        to: "/dashboard/products-management/lot-list",
        render: (props) => <LotList {...props} />
      },
      {
        exact: true,
        module: "soldLotList",
        module_user: ["AD"],
        icon: "pe-7s-shopbag",
        label: "SOLD_LOT_LIST",
        to: "/dashboard/products-management/sold-lot-list",
        render: (props) => <LotList {...props} isSold />
      },
      {
        exact: true,
        module: "monumentList",
        module_user: ["AD"],
        icon: "pe-7s-folder",
        label: "MONUMENT_LIST",
        to: "/dashboard/products-management/monument-list",
        render: (props) => <MonumentList {...props} />
      },
      {
        exact: true,
        module: "fspList",
        module_user: ["AD"],
        icon: "pe-7s-folder",
        label: "FSP_LIST",
        to: "/dashboard/products-management/fsp-list",
        render: (props) => <FSPList {...props} />
      },
      {
        exact: true,
        module: "productEditor",
        disabled: true,
        module_user: ["AD"],
        icon: "pe-7s-box2",
        label: "PRODUCT_EDITOR",
        to: "/dashboard/packages-management"
      }
    ]
  },
  {
    module: "adminPanel",
    icon: "pe-7s-box1",
    label: "APPROVAL",
    content: [
      {
        exact: true,
        module: "registrationApproval",
        module_user: ["AD"],
        icon: "pe-7s-users",
        label: "REGISTRATION",
        to: "/dashboard/registration-approval",
        render: (props) => <RegistrationApproval {...props} />
      },
      {
        exact: true,
        module: "memberApproval",
        module_user: ["AD"],
        icon: "pe-7s-users",
        label: "MEMBER",
        to: "/dashboard/member-approval",
        render: (props) => <MemberApproval {...props} />
      },
      {
        exact: true,
        module: "salesPaymentApproval",
        module_user: ["AD"],
        icon: "pe-7s-note2",
        label: "ORDERS",
        to: "/dashboard/orders-approval",
        render: (props) => <BookingApproval {...props} />
      },
      // {
      //   exact: true,
      //   module: "installmentApproval",
      //   module_user: ["AD"],
      //   icon: "pe-7s-note2",
      //   label: "INSTALLMENTS",
      //   to: "/dashboard/installment-approval",
      //   render: (props) => <InstallmentApproval {...props} />
      // }
    ]
  },
  {
    module: "bookings",
    icon: "pe-7s-note2",
    label: "BOOKINGS",
    content: [
      {
        exact: true,
        module: "bookingList",
        module_user: ["AD", "M"],
        icon: "pe-7s-file",
        label: "BOOKING_LIST",
        to: "/dashboard/bookings",
        render: (props) => <BookingList {...props} />
      },
      {
        exact: true,
        module: "fspBookinglist",
        module_user: ["AD", "M"],
        icon: "pe-7s-file",
        label: "FSP_BOOKING_LIST",
        to: "/dashboard/fsp-bookings",
        render: (props) => <BookingList {...props} listType="FSP" />
      },
      {

        exact: true,
        module: "memorialBookinglist",
        module_user: ["AD", "M"],
        icon: "pe-7s-file",
        label: "MONUMENT_BOOKING_LIST",
        to: "/dashboard/monument-bookings",
        render: (props) => <BookingList {...props} listType="monument" />
      }
    ]
  },
  {
    module: "memorialPanel",
    icon: "pe-7s-note2",
    label: "MEMORIAL_PANEL",
    content: [
      {
        exact: true,
        module: "certification",
        module_user: ["AD", "M"],
        icon: "pe-7s-file",
        label: "CERTIFICATION",
        to: "/dashboard/certification",
        render: (props) => <Certification {...props} />
      }
    ]
  },
  {
    module: "orders",
    icon: "pe-7s-note2",
    label: "ORDERS",
    content: [
      {
        exact: true,
        module: "listing",
        module_user: ["AD", "M"],
        icon: "pe-7s-speaker",
        label: "LOT_LISTING",
        to: "/dashboard/orders",
        render: (props) => <Orders {...props} />
      },
      {
        exact: true,
        module: "fsplisting",
        module_user: ["AD", "M"],
        icon: "pe-7s-speaker",
        label: "FSP_LISTING",
        to: "/dashboard/fsp-listing",
        render: (props) => <FSPListing {...props} />
      },
      {
        exact: true,
        module: "monumentlisting",
        module_user: ["AD", "M"],
        icon: "pe-7s-speaker",
        label: "MONUMENT_LISTING",
        to: "/dashboard/monument-listing",
        render: (props) => <MonumentListing {...props} />
      },
      {
        exact: true,
        module: "misclisting",
        module_user: ["AD", "M"],
        icon: "pe-7s-speaker",
        label: "MISC_LISTING",
        to: "/dashboard/misc-listing",
        render: (props) => <MiscListing {...props} />
      },
      {
        exact: true,
        module: "refund",
        module_user: ["AD", "M"],
        icon: "pe-7s-speaker",
        label: "REFUND",
        to: "/dashboard/refund",
        render: (props) => <Refund {...props} />
      },
      {
        exact: true,
        module: "transactionHistory",
        module_user: ["AD", "M"],
        icon: "pe-7s-speaker",
        label: "TRANSACTION_HISTORY",
        to: "/dashboard/transaction_history",
        render: (props) => <TransactionHistories {...props} />
      },
      {
        exact: true,
        module: "salesPayment",
        module_user: ["AD", "M"],
        icon: "pe-7s-speaker",
        label: "SALES_PAYMENT",
        to: "/dashboard/sales_payment",
        render: (props) => <SalesPayment {...props} />
      }
    ]
  },
  {
    // Mall
    module: "mall",
    icon: "pe-7s-shopbag",
    label: "MALL",
    content: [
      {
        exact: true,
        module: "lotMall",
        module_user: ["AD", "M"],
        icon: "pe-7s-shopbag",
        label: "LOT_PURCHASE",
        to: "/dashboard/mall",
        render: (props) => <Mall {...props} />
      },
      {
        exact: true,
        module: "serviceMall",
        module_user: ["AD", "M"],
        icon: "pe-7s-shopbag",
        label: "FSP",
        to: "/dashboard/service-mall",
        render: (props) => <ServiceMall {...props} />
      },
      {
        exact: true,
        module: "monumentMall",
        module_user: ["AD", "M"],
        icon: "pe-7s-shopbag",
        label: "MONUMENT_MALL",
        to: "/dashboard/monument-mall",
        render: (props) => <MonumentMall {...props} />
      },
      {
        exact: true,
        module: "urnMall",
        module_user: ["AD", "M"],
        icon: "pe-7s-shopbag",
        label: "URN_MALL",
        to: "/dashboard/urn-mall",
        render: (props) => <UrnMall {...props} />
      },
      {
        exact: true,
        module: "miscMall",
        module_user: ["AD"],
        icon: "pe-7s-shopbag",
        label: "MISCELLANEOUS",
        to: "/dashboard/miscellaneous",
        render: (props) => <MiscellaneousMall {...props} />
      }
    ]
  },
  {
    module: "announcementManagement",
    icon: "pe-7s-speaker",
    label: "ANNOUNCEMENT",
    content: [
      {
        exact: true,
        module: "addAnnouncement",
        module_user: ["AD"],
        icon: "pe-7s-speaker",
        label: "ADD_ANNOUNCEMENT",
        to: "/dashboard/add-announcement",
        render: (props) => <AddAnnouncement {...props} />
      },
      {
        exact: true,
        module: "manageAnnouncement",
        module_user: ["AD"],
        icon: "pe-7s-speaker",
        label: "MANAGE_ANNOUNCEMENT",
        to: "/dashboard/announcement",
        render: (props) => <Announcement {...props} />
      }
    ]
  },
  {
    disabled: true,
    exact: true,
    module_user: ["AD", "M"],
    icon: "pe-7s-headphones",
    label: "CERTIFICATE",
    to: "/dashboard/contact-us"
  },
  {
    disabled: true,
    icon: "pe-7s-note2",
    label: "REPORTS",
    content: [
      {
        exact: true,
        module_user: ["AD", "M"],
        icon: "pe-7s-news-paper",
        label: "BONUS_SUMMARY",
        to: "/dashboard/bonus-summary"
      },
      {
        exact: true,
        module_user: ["AD", "M"],
        icon: "pe-7s-news-paper",
        label: "DAILY_PAIRED_HISTORY",
        to: "/dashboard/daily-paired-history"
      },
      {
        exact: true,
        module_user: ["AD", "M"],
        icon: "pe-7s-news-paper",
        label: "MONTHLY_PAIRED_HISTORY",
        to: "/dashboard/monthly-paired-history"
      },
      {
        exact: true,
        module_user: ["AD", "M"],
        icon: "pe-7s-news-paper",
        label: "PAIRING_DAILY_BALANCE",
        to: "/dashboard/pairing-daily-balance"
      },
      {
        exact: true,
        module_user: ["AD", "M"],
        icon: "pe-7s-news-paper",
        label: "SALES_SV_TO_MAINTENCE",
        to: "/dashboard/sales-to-maintence"
      },
      {
        exact: true,
        module_user: ["AD", "M"],
        icon: "pe-7s-news-paper",
        label: "UPGRADE_PACKAGE_RANK_HISTORY",
        to: "/dashboard/upgrade-package-rank-history"
      },
      {
        exact: true,
        module_user: ["AD", "M"],
        icon: "pe-7s-news-paper",
        label: "UPGRADE_RANK_HISTORY",
        to: "/dashboard/upgrade-rank-history"
      }
    ]
  },
  {
    module: "staffManagement",
    icon: "pe-7s-settings",
    label: "STAFF_SETTINGS",
    content: [
      {
        exact: true,
        module: "subadminList",
        module_user: ["AD"],
        icon: "pe-7s-users",
        label: "SUBADMIN_LIST",
        to: "/dashboard/subadmin-list",
        render: (props) => <SubadminList {...props} />
      },
      {
        exact: true,
        module: "rolePermission",
        module_user: ["AD"],
        icon: "pe-7s-users",
        label: "ROLE_PERMISSIONS",
        to: "/dashboard/role-permissions",
        render: (props) => <RolePermissions {...props} />
      }
    ]
  },
  {
    module: "settings",
    icon: "pe-7s-settings",
    label: "SETTINGS",
    content: [
      {
        exact: true,
        module: "changePassword",
        module_user: ["AD", "M"],
        icon: "pe-7s-users",
        label: "CHANGE_PASSWORD",
        to: "/dashboard/change-password",
        render: (props) => <ChangePassword {...props} />
      },
      {
        exact: true,
        module: "changeSecurityPin",
        module_user: ["AD", "M"],
        icon: "pe-7s-users",
        label: "CHANGE_SECURITY_PIN",
        to: "/dashboard/change-security-pin",
        render: (props) => <ChangeSecurityPin {...props} />
      }
    ]
  },
  {
    module: "eResource",
    icon: "pe-7s-settings",
    label: "E-Resource",
    content: [
      {
        exact: true,
        module: "viewEResource",
        module_user: ["AD", "M"],
        icon: "pe-7s-users",
        label: "VIEW_LISTING",
        to: "/dashboard/view-e-resource",
        render: (props) => <EResourceListing {...props} />
      },
      {
        exact: true,
        module: "manageCategory",
        module_user: ["AD"],
        icon: "pe-7s-users",
        label: "MANAGE_CATEGORY",
        to: "/dashboard/e-resource-category",
        render: (props) => <EResourceCategories {...props} />
      }
    ]
  }
];
